import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import FlightsCheckout from "./components/FlightsCheckout"
import FlightsThanks from "./components/FlightsThanks"
import AccommodationsCheckout from "./components/AccommodationsCheckout"
import AccommodationsThanks from "./components/AccommodationsThanks"
import AccommodationsEPSCheckout from "./components/AccommodationsEPSCheckout"
import Layout from "./components/Layout"
import CombinedCheckout from "./components/combined/CombinedCheckout/CombinedCheckout"
import CombinedThanks from "./components/combined/CombinedThanks/CombinedThanks"
import CarsCheckout from "./components/cars/CarsCheckout/CarsCheckout"
import CarsThanks from "./components/cars/CarsThanks/CarsThanks"
import InsurancesCheckout from "./components/insurances/InsurancesCheckout/InsurancesCheckout"
import InsurancesThanks from "./components/insurances/InsurancesThanks/InsurancesThanks"
import { Auth0Provider } from "@auth0/auth0-react"

class App extends React.Component {
  render() {
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
      >
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/checkout/flights/:id"
              render={() => (
                <Layout product={"FLIGHTS"}>
                  <FlightsCheckout />
                </Layout>
              )}
            />
            <Route
              exact
              path="/checkout/accommodations/:id"
              render={props => (
                <Layout product={"HOTELS"}>
                  <AccommodationsCheckout {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/checkout/accommodations/eps/:id"
              render={props => (
                <Layout product={"HOTELS"}>
                  <AccommodationsEPSCheckout {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/checkout/reservations/:id/thanks"
              render={props => (
                <Layout product={"FLIGHTS"}>
                  <FlightsThanks {...props} />
                </Layout>
              )}
            />
            <Route
              exact
              path="/checkout/accommodations/reservations/:id/thanks"
              render={props => (
                <Layout product={"HOTELS"}>
                  <AccommodationsThanks {...props} />
                </Layout>
              )}
            />

            <Route
              exact
              path="/checkout/combined/:id"
              render={() => (
                <Layout product={"COMBINED_PRODUCTS"}>
                  <CombinedCheckout />
                </Layout>
              )}
            />
            <Route
              exact
              path="/checkout/combined/reservations/:id/thanks"
              render={() => (
                <Layout product={"COMBINED_PRODUCTS"}>
                  <CombinedThanks />
                </Layout>
              )}
            />

            <Route
              exact
              path="/checkout/cars/:id"
              render={() => (
                <Layout product={"CARS"}>
                  <CarsCheckout />
                </Layout>
              )}
            />
            <Route
              exact
              path="/checkout/cars/reservations/:id/thanks"
              render={() => (
                <Layout product={"CARS"}>
                  <CarsThanks />
                </Layout>
              )}
            />
            <Route
              exact
              path="/checkout/insurances/:id"
              render={() => (
                <Layout product={"TRAVEL_INSURANCES"}>
                  <InsurancesCheckout />
                </Layout>
              )}
            />
            <Route
              exact
              path="/checkout/insurances/reservations/:id/thanks"
              render={() => (
                <Layout product={"TRAVEL_INSURANCES"}>
                  <InsurancesThanks />
                </Layout>
              )}
            />
          </Switch>
        </BrowserRouter>
      </Auth0Provider>
    )
  }
}

export default App
