import React from "react"
import Select from "react-select"
import "./combo-box.css"
import { ThemeProps, theme } from "@basset-la/themed-components"
import _ from "lodash"
import { ActionMeta, FocusEventHandler } from "react-select/lib/types"

interface Props extends ThemeProps {
  options: any[]

  disabled?: boolean
  hasError?: boolean
  required?: boolean
  value?: string
  placeholder?: string
  onChange?: (value: any, action: ActionMeta) => void
  onBlur?: FocusEventHandler
  name?: string
}

const ComboBox = ({
  name,
  placeholder,
  disabled,
  required,
  options,
  value,
  hasError,
  theme,
  onChange,
  onBlur,
  ...attr
}: Props) => {
  return (
    <Select
      className={["react-select-container", hasError && "has-error"].join(" ")}
      classNamePrefix="react-select"
      placeholder={placeholder}
      value={value && _.find(options, option => option.value === value)}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      clearable={false}
      backspaceRemoves={!required}
      deleteRemoves={!required}
      disabled={disabled}
      name={name}
      theme={t => ({
        ...t,
        borderRadius: 0,
        colors: {
          ...t.colors,
          primary: theme.colors.brand_primary
        }
      })}
      {...attr}
    />
  )
}

export default theme(ComboBox)
