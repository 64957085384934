// @flow

import React from "react"
import { ThemeProps, theme } from "@basset-la/themed-components"
import { withTranslation, WithTranslation } from "react-i18next"
import TextField from "../common/TextField"
import DocumentField from "@basset-la/commons-frontend/dist/components/checkout/passengers/DocumentField"
import { I18N_NS } from "../../utils/constants"
import styles from "./PaymentSection-styles"
import EPSCardNumber from "./EPSCardNumber"
import EPSSecurityCode from "./EPSSecurityCode"
import CardExpiration from "./CardExpiration"
import { css } from "glamor"

interface Props extends WithTranslation, ThemeProps {
  site: string
  payment: any
  paymentToken: string
  onChange: (n: string, v: string) => void
  documentOptions?: any[]
}

class PaymentSection extends React.PureComponent<Props, any> {
  render() {
    const { t, payment, paymentToken, onChange, documentOptions } = this.props

    const onChangeWithField = (e: any) => {
      onChange(e.target.name, e.target.value)
    }

    return (
      <div className={`${styles.containerStyle}`}>
        <div className={`${styles.rowContainerStyle}`}>
          <TextField
            name={"card_holder"}
            title={t<string>("PaymentSection.cardHolder")}
            placeholder={t<string>("PaymentSection.cardHolderPlaceholder")}
            value={payment.card_holder}
            errorText={payment.card_holder_error}
            onChange={onChangeWithField}
          />
          <DocumentField
            documentNumberName={`document_number`}
            documentNumberErrorText={payment.document_number_error}
            documentNumberValue={payment.document_number}
            documentTypeName={`document_type`}
            documentTypeValue={payment.document_type}
            documentTypeErrorText={payment.document_type_error}
            documentOptions={documentOptions}
            onChange={onChangeWithField}
          />
        </div>
        <div className={`${styles.rowContainerStyle}`}>
          <div className={`${css({ flex: "1 1 0", display: "flex", "flex-flow": "row" })}`}>
            <div className={`${css({ flex: "2 1 0" })}`}>
              <EPSCardNumber
                errorText={payment.credit_card_error}
                isCreditCardValid={payment.isCreditCardValid}
                paymentToken={paymentToken}
              />
            </div>
            <div className={`${css({ flex: "1 1 0" })}`}>
              <EPSSecurityCode errorText={payment.security_code_error} paymentToken={paymentToken} />
            </div>
            <div className={`${css({ flex: "1 1 0" })}`}>
              <CardExpiration
                expirationMonthValue={payment.expiration_month}
                expirationMonthErrorText={payment.expiration_month_error}
                expirationYearValue={payment.expiration_year}
                expirationYearErrorText={payment.expiration_year_error}
                onChange={onChangeWithField}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation(I18N_NS)(theme(PaymentSection))
