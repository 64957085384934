import React from "react"
import { css } from "glamor"
import { ThemeProps, theme } from "@basset-la/themed-components"
import { withTranslation, WithTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

interface Props extends WithTranslation, ThemeProps {
  title: string
  code: string
}

const ThanksProductReservationDetail = ({ title, code, theme }: Props) => {
  const tytleStyle = css({
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "1.33",
    color: theme.colors.brand_primary,
    margin: 0
  })
  return (
    <div className={`${containerStyle}`}>
      <p className={`${tytleStyle}`}>{title}</p>
      <p className={`${reservationStyle}`}>{code}</p>
    </div>
  )
}

const containerStyle = css({
  border: "solid 1px #CCC",
  backgroundColor: "#fff",
  padding: "16px 8px",
  display: "flex",
  flexDirection: "column",
  marginBottom: "16px",
  "@media(min-width:1200px)": {
    flex: "1 1 0%",
    marginBottom: "0px",
    marginLeft: "16px"
  }
})

const reservationStyle = css({
  fontFamily: "Roboto",
  margin: 0,
  fontSize: "18px",
  lineHeight: "1.33",
  color: "#4a4a4a",
  fontWeight: "500"
})

export default theme(withTranslation(I18N_NS)(ThanksProductReservationDetail))
