import React, { useCallback, useEffect, useState } from "react"

import clone from "lodash/clone"
import moment from "moment"
import qs from "qs"
import ReactGA from "react-ga"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"

import { validations } from "@basset-la/commons-frontend"
import BillingContainer from "@basset-la/commons-frontend/dist/components/checkout/billing/BillingContainer"
import PassengersContainer from "@basset-la/commons-frontend/dist/components/checkout/passengers/PassengersContainer"
import { validatePassengers as passengersValidations } from "@basset-la/commons-frontend/dist/components/checkout/utils/passengersValidations"
import { FlightsCheckoutPassenger } from "@basset-la/commons-frontend/dist/components/types"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import ChangedFareDialog from "@basset-la/components-flights/dist/components/ChangedFareDialog"
import BaggageAllowance from "@basset-la/components-flights/dist/components/CheckoutBaggageAllowance"
import PriceboxBreakdownCheckoutWeb from "@basset-la/components-flights/dist/components/PriceboxBreakdownCheckoutWeb"
import { ChangeFareCluster, Cluster } from "@basset-la/components-flights/dist/model"
import { getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useTheme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { getCountries, getStatesByCountryCode } from "../api/common"
import { getCluster } from "../api/flights"
import { createReservationAsync, getReservationProcess } from "../api/reservations"
import { FormConfig, getFormConfig } from "../config/formConfig"
import { I18N_NS } from "../utils/constants"
import { createFlightPurchaseModel, formatPrice, isValidFare, validateFieldAirlines } from "../utils/flightsUtils"
import FormSection from "./common/FormSection"
import { checkoutConfig } from "@basset-la/components-products/dist/utils/config"
import ContactContainer from "./contact/ContactContainer"
import FlightDetail from "@basset-la/components-flights/dist/components/FlightDetail"
import styles from "./FlightsCheckout.styles"
import NotFound from "./NotFound"
import RequiredDocAlert from "./requiredDoc/RequiredDocAlert"
import TermsAndConditions from "./TermsAndConditions"
import * as model from "./types"
import { useAuthUser, useConfig } from "@basset-la/components-commons"
import Message from "@basset-la/components-commons/dist/components/Message"
import InsurancesCrossSelling from "./crossSelling/InsurancesCrossSelling"
import { SearchboxParams as InsuranceSearchboxParams } from "@basset-la/components-insurances"
import { createCrossSellingProducts } from "../utils/crossselling"
import { CheckoutEmergencyContact, InsurancesProviderConfig } from "../utils/common"
import { CrossSellingCluster } from "./types"

interface RouteMatch {
  id: string
}

const FlightsCheckout: React.FC = () => {
  const { config } = useConfig()

  const match = useRouteMatch<RouteMatch>()
  const { search } = useLocation()

  const { i18n, t } = useTranslation(I18N_NS)
  const { userId } = useAuthUser()

  const theme = useTheme()
  const history = useHistory()

  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isBooking, setIsBooking] = useState<boolean>(false)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [showTermAndConditions, setShowTermAndConditions] = useState<boolean>(false)
  const [termsAndConditionAccepted, setTermsAndConditionAccepted] = useState<boolean>(false)
  const [site, setSite] = useState<string>("")
  const [paymentsURL, setPaymentsURL] = useState<string>()
  const [formConfig, setFormConfig] = useState<FormConfig>(getFormConfig())
  const [product, setProduct] = useState<ChangeFareCluster>()
  const [passengers, setPassengers] = useState<FlightsCheckoutPassenger[]>([])
  const [termAndConditions, setTermAndConditions] = useState<any>()
  const [billingInfo, setBillingInfo] = useState<model.BillingInformation>()
  const [contactInfo, setContactInfo] = useState<model.ContactInformation>()
  const [arrivalDate, setArrivalDate] = useState<Date>(new Date())
  const [geo, setGeo] = useState<model.Geo>()

  const [changeFareProduct, setChangeFareProduct] = useState<ChangeFareCluster>()
  const [openChangeFareDialog, setOpenChangeFareDialog] = useState(false)
  const [pendingReserve, setPendingReserve] = useState(false)
  const [continueReserve, setContinueReserve] = useState(false)
  const [originalFare, setOriginalFare] = useState<number>()

  const [crossSellingClusters, setCrossSellingClusters] = useState<model.CrossSellingCluster[]>([])
  const [insuranceSearchParams, setInsuranceSearchParams] = useState<InsuranceSearchboxParams>()
  const [emergencyContact, setEmergencyContact] = useState<CheckoutEmergencyContact>({
    name: "",
    lastName: "",
    phone: "",
    errors: {}
  })
  const [lastSelectedCrossSellingClusterProviderConfig, setLastSelectedCrossSellingClusterProviderConfig] = useState<
    InsurancesProviderConfig
  >({
    id: "",
    emergency_contact_required: false
  })

  const isMobile = useMediaQuery("(max-width: 768px)")

  var selectedBrand = 0
  const params = qs.parse(search.substring(1))
  if (params["selected_brand"]) {
    selectedBrand = parseInt(params["selected_brand"])
  }

  const createContactInformation = useCallback((countryCode: string) => {
    return {
      email: "",
      email_confirmation: "",
      telephone_type: checkoutConfig.config[countryCode].defaults.telephones.type,
      telephone_country_code: checkoutConfig.config[countryCode].defaults.telephones.country,
      telephone_area_code: checkoutConfig.config[countryCode].defaults.telephones.area,
      telephone_number: ""
    }
  }, [])

  const createPassengerType = useCallback((countryCode, type, quantity) => {
    let passengersList: FlightsCheckoutPassenger[] = []

    for (let x = 0; x < quantity; x++) {
      passengersList.push({
        type: type,
        firstname: "",
        lastname: "",
        gender: "",
        birthDay: 0,
        birthMonth: 0,
        birthYear: 0,
        document_type: checkoutConfig.config[countryCode].defaults.documentType,
        document_number: "",
        nationality: checkoutConfig.config[countryCode].defaults.nationality,
        document_issuing_country: checkoutConfig.config[countryCode].defaults.nationality,
        document_valid_thru: ""
      } as FlightsCheckoutPassenger)
    }

    return passengersList
  }, [])

  const createPassengers = useCallback(
    (countryCode: string, product: ChangeFareCluster) => {
      const price = product.upsell_fares ? product.upsell_fares[selectedBrand].price : product.price

      let passengersList: FlightsCheckoutPassenger[] = []
      if (price) {
        if (price.adults) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "ADT", price.adults.quantity))
        }
        if (price.seniors) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "SRC", price.seniors.quantity))
        }
        if (price.disabled) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "DIS", price.disabled.quantity))
        }
        if (price.children) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "CHD", price.children.quantity))
        }
        if (price.infants) {
          passengersList = passengersList.concat(createPassengerType(countryCode, "INF", price.infants.quantity))
        }
      }

      return passengersList
    },
    [createPassengerType]
  )

  const createBillingInformation = useCallback((countryCode: string) => {
    return {
      fiscal_name: "",
      fiscal_id: "",
      fiscal_situation: checkoutConfig.config[countryCode].defaults.fiscalSituation,
      province: "",
      city: "",
      street: "",
      street_number: "",
      floor_number: "",
      building_number: "",
      postal_code: ""
    }
  }, [])

  const retrieveCheckoutData = useCallback(
    async (id: string, pendingProduct?: ChangeFareCluster) => {
      const lang = getLocale(i18n).substring(0, 2)
      setSite(config.country)

      let cluster: ChangeFareCluster | undefined

      try {
        cluster = await getCluster(id, config.country, config.agency_id, userId)
      } catch (e) {
        setNotFound(true)
        setIsFetching(false)

        return
      }

      if (pendingProduct) {
        try {
          if (isValidFare(cluster.original_fare)) {
            setChangeFareProduct(cluster)
            setOpenChangeFareDialog(true)
            setOriginalFare(cluster.original_fare)
          } else {
            //Handle change fare internally when prebooking is disabled and the cache
            //returns a itinerary that its fare was modified during reserve process because of a change fare error
            const pPriceTotal = pendingProduct?.upsell_fares
              ? pendingProduct?.upsell_fares[selectedBrand].price.total
              : pendingProduct?.price.total

            const cPriceTotal = cluster.upsell_fares
              ? cluster.upsell_fares[selectedBrand].price.total
              : cluster.price.total

            const originalFare = isValidFare(pPriceTotal) ? pPriceTotal : cPriceTotal

            if (cPriceTotal - originalFare !== 0) {
              setChangeFareProduct(cluster)
              setOpenChangeFareDialog(true)
              setOriginalFare(originalFare)
            } else {
              setProduct(cluster)

              handleRedirectToExternalPayment(match.params.id, cluster.tracking_id || "")

              setContinueReserve(true)
            }
          }
        } catch (error) {
          setNotFound(true)
        }
        return
      }

      handleRedirectToExternalPayment(id, cluster?.tracking_id || "")

      const fc = getFormConfig(config.checkout_form_config)

      setFormConfig(fc)
      setTermAndConditions(config.brand.terms_and_conditions)

      const countries = await getCountries(config.agency_id, lang)
      const states = !fc.billingInfo ? undefined : await getStatesByCountryCode(config.country, config.agency_id, lang)

      setBillingInfo(createBillingInformation(config.country))
      setContactInfo(createContactInformation(config.country))
      setGeo({
        countries: countries,
        states: states
      })

      if (cluster) {
        const passengersNew = createPassengers(config.country, cluster)

        setPassengers(passengersNew)
        setPaymentsURL(cluster.payments_url)
        setArrivalDate(getArrivalDate(cluster))

        if (isValidFare(cluster.original_fare)) {
          setChangeFareProduct(cluster)
          setOpenChangeFareDialog(true)
          setOriginalFare(cluster.original_fare)
          return
        }

        setProduct(cluster)

        const geoInfo = {
          countries: countries,
          states: states
        }

        if (
          config.cross_sellings &&
          cluster.flight_type !== "ONE_WAY" &&
          config.cross_sellings.find(cs => cs.product_type === "TRAVEL_INSURANCES")?.enabled
        ) {
          initInsuranceSearchParams(cluster, geoInfo, passengersNew)
        }
      }
      setIsFetching(false)
    },
    [createBillingInformation, createContactInformation, createPassengers, i18n, userId, match.params.id]
  )

  const handleReserveChangeFareError = useCallback(
    async (product?: ChangeFareCluster) => {
      console.error("reserve change fare error")
      setPendingReserve(true)
      await retrieveCheckoutData(match.params.id, product)
    },
    [match.params.id, retrieveCheckoutData]
  )

  const validateContactInfo = useCallback((): boolean => {
    if (!contactInfo) return false

    let isValid = true

    const contact = clone(contactInfo)
    if (!contact.email) {
      isValid = false
      contact.email_error = t("FlightsCheckout.emailError")
    } else {
      if (!validations.validateEmail(contact.email)) {
        isValid = false
        contact.email_error = t("FlightsCheckout.emailInvalid")
      } else {
        if (contact.email !== contact.email_confirmation) {
          isValid = false
          contact.email_confirmation_error = t("FlightsCheckout.emailConfirmation")
        }
      }
    }

    if (!contact.telephone_country_code) {
      isValid = false
      contact.telephone_country_code_error = t("FlightsCheckout.telephoneCountryCode")
    }

    if (!validations.validateNumeric(contact.telephone_country_code || "")) {
      isValid = false
      contact.telephone_number_error = t("FlightsCheckout.telephoneInvalid")
    }

    contact.telephone_area_code_error = ""

    if (contact.telephone_type !== "M") {
      if (!contact.telephone_area_code) {
        isValid = false
        contact.telephone_area_code_error = t("FlightsCheckout.telephoneAreaCode")
      }

      if (!validations.validateAreaCode(contact.telephone_area_code || "")) {
        isValid = false
        contact.telephone_number_error = t("FlightsCheckout.telephoneInvalid")
      }
    }

    if (!contact.telephone_number) {
      isValid = false
      contact.telephone_number_error = t("FlightsCheckout.telephoneNumber")
    }

    if (!validations.validateNumeric(contact.telephone_number || "")) {
      isValid = false
      contact.telephone_number_error = t("FlightsCheckout.telephoneInvalid")
    }

    if (!isValid) {
      setContactInfo(contact)
    }

    return isValid
  }, [contactInfo, site, t])

  const validateFields = useCallback(
    (config: any, info: any, prefix: string): boolean => {
      let isValid = true
      if (config.length > 0) {
        for (let i in config) {
          let name = config[i].name
          let regexValid = true

          if (config[i].regex) {
            let regex = new RegExp(config[i].regex, "g")
            regexValid = regex.test(info[name])
          }

          if (config[i].required && (!info[name] || !regexValid)) {
            isValid = false
            info[name + "_error"] = t(prefix + name)
          }
        }
      }

      return isValid
    },
    [t]
  )

  const validateBillingInfo = useCallback((): boolean => {
    if (!billingInfo) return false

    const billing = clone(billingInfo)
    const chkConfig = checkoutConfig.config[site]
    const identification = chkConfig.billing.identification ? chkConfig.billing.identification : []
    const addressStreet = chkConfig.billing.address_street ? chkConfig.billing.address_street : []
    const addressExtra = chkConfig.billing.address_extra ? chkConfig.billing.address_extra : []
    const fiscalId = chkConfig.billing.fiscal_id ? chkConfig.billing.fiscal_id : []
    const fiscalSituation = chkConfig.billing.fiscal_situation ? chkConfig.billing.fiscal_situation : []

    const config = identification
      .concat(addressStreet)
      .concat(addressExtra)
      .concat(fiscalId)
      .concat(fiscalSituation)

    let isValid = validateFields(config, billing, "FlightsCheckout.billingError.")

    if (!validations.validateFiscalID(billing.fiscal_id || "", billing.fiscal_situation || "", site)) {
      billing.fiscal_id_error = t("FlightsCheckout.billingError.fiscal_id")
      isValid = false
    }

    if (!isValid) {
      setBillingInfo(billing)
    }

    return isValid
  }, [billingInfo, site, t, validateFields])

  const validatePassengers = useCallback(
    (arrivalDate: Date, passengers: FlightsCheckoutPassenger[], site: string): boolean => {
      const paxs = clone(passengers)
      const chkConfig = checkoutConfig.config[site]

      const validateNamesLength = validateFieldAirlines(product as ChangeFareCluster)

      let isValid = true

      const passengersValidated = passengersValidations(site, paxs, arrivalDate, chkConfig, validateNamesLength)
      if (passengersValidated) {
        isValid = false
        setPassengers(passengersValidated)
      }

      return isValid
    },
    []
  )

  const validateEmergencyContact = useCallback(
    (emergencyContact: CheckoutEmergencyContact, insuranceProviderConfig: InsurancesProviderConfig): boolean => {
      if (!insuranceProviderConfig.emergency_contact_required) {
        return true
      }

      let isValid = true

      const copy = {
        ...emergencyContact,
        errors: { ...emergencyContact.errors }
      }

      if (copy.name === "") {
        isValid = false
        copy.errors.nameError = t<string>("InsurancesCheckout.emergencyContact.firstName")
      }
      if (copy.lastName === "") {
        isValid = false
        copy.errors.lastNameError = t<string>("InsurancesCheckout.emergencyContact.lastName")
      }
      if (copy.phone === "") {
        isValid = false
        copy.errors.phoneError = t<string>("InsurancesCheckout.emergencyContact.phone")
      }

      setEmergencyContact({
        ...copy,
        errors: { ...copy.errors }
      })

      return isValid
    },
    []
  )

  const validate = useCallback(
    (
      formConfig: FormConfig,
      emergencyContact: CheckoutEmergencyContact,
      insuranceProviderConfig: InsurancesProviderConfig
    ): boolean => {
      const validPassengers = !formConfig.passengers ? true : validatePassengers(arrivalDate, passengers, site)
      const validBillingInfo =
        !formConfig.billingInfo || formConfig.autocompleteBillingInfo ? true : validateBillingInfo()
      const validContactInfo = !formConfig.contactInfo ? true : validateContactInfo()
      const validEmergencyContact = validateEmergencyContact(emergencyContact, insuranceProviderConfig)

      return validPassengers && validBillingInfo && validContactInfo && validEmergencyContact
    },
    [
      arrivalDate,
      passengers,
      site,
      validateBillingInfo,
      validateContactInfo,
      validatePassengers,
      validateEmergencyContact
    ]
  )

  const validateAndPurchase = async (product?: ChangeFareCluster) => {
    const lang = getLocale(i18n).substring(0, 2)

    const { country: site, agency_id: agencyId } = config

    if (validate(formConfig, emergencyContact, lastSelectedCrossSellingClusterProviderConfig)) {
      setIsBooking(true)

      const crossSellingProducts = createCrossSellingProducts(
        crossSellingClusters,
        passengers,
        emergencyContact,
        billingInfo,
        contactInfo,
        formConfig,
        site,
        lang
      )

      const req = createFlightPurchaseModel(
        match.params.id,
        passengers,
        billingInfo,
        contactInfo,
        site,
        formConfig,
        lang,
        product!,
        selectedBrand,
        crossSellingProducts
      )

      try {
        const resProc = await createReservationAsync(req, userId, site, agencyId)

        const interval = setInterval(async () => {
          const reservationProcess = await getReservationProcess(resProc.id, userId, site, agencyId)
          switch (reservationProcess.status) {
            case "OK":
              if (paymentsURL) {
                let url = paymentsURL.replace("{id}", `${reservationProcess.reservation_id}`)
                const { _ga } = qs.parse(window.location.search.substr(1))

                if (_ga) {
                  url = url.replace("{_ga}", _ga)
                } else {
                  const ga = ReactGA.ga()
                  ga(function(tracker) {
                    let clientId = tracker.get("clientId")
                    url = url.replace("{_ga}", clientId)
                  })
                }
                window.location.href = url
              } else {
                history.push(`/checkout/reservations/${reservationProcess.reservation_id}/thanks`)
              }

              clearInterval(interval)
              break
            case "ERROR":
              console.error(reservationProcess.error_message)
              setNotFound(true)
              clearInterval(interval)
              break
            case "CHANGE_FARE":
              clearInterval(interval)
              handleReserveChangeFareError(product)
              break
            default:
              console.log("Still booking...")
              break
          }
        }, 1000)
      } catch (err) {
        console.error(err)
        setNotFound(true)
      }
    }
  }

  const initInsuranceSearchParams = (
    cluster: ChangeFareCluster,
    geoInfo: model.Geo,
    passengersInfo: FlightsCheckoutPassenger[]
  ) => {
    if (!cluster || !geoInfo?.countries.length || insuranceSearchParams) return

    const { segments } = cluster

    const departureDate = moment(segments[0].options[0].departure_date, "YYYY-MM-DD")
    const lastSegment = segments[segments.length - 1]
    const lastOption = lastSegment.options[lastSegment.options.length - 1]
    const returnDate = moment(lastOption.departure_date, "YYYY-MM-DD")
    const fromRegion = geoInfo?.countries.find(country => country.value === segments[0].origin.country_code)!

    setInsuranceSearchParams({
      origin: {
        id: fromRegion.id,
        name: fromRegion.label,
        type: "country"
      },
      destination: {
        id: lastSegment.origin.code,
        name: lastSegment.origin.name,
        type: "airport"
      },
      departureDate,
      returnDate,
      passengers: {
        birthday: new Array(passengersInfo.length).fill(undefined)
      }
    })
  }

  useEffect(() => {
    retrieveCheckoutData(match.params.id)
  }, [retrieveCheckoutData, match.params.id])

  useEffect(() => {
    if (continueReserve) {
      setPendingReserve(false)
      setContinueReserve(false)
      validateAndPurchase(product)
    }
  }, [continueReserve, product, validateAndPurchase])

  const onPassengerChange = (i: number, field: string, value: string) => {
    let regex
    if (field === "firstname" || field === "lastname") {
      regex = /^[a-zA-Z\sñáéíóúÁÉÍÓÚ]*$/
      if (!regex.test(value)) {
        value = passengers[i][field]
      }
    }
    if (field === "document_number") {
      regex = /^[a-zA-Z0-9]*$/
      if (!regex.test(value)) {
        value = passengers[i][field]
      }
    }
    if (field === "document_valid_thru") {
      regex = /^[\d-]*$/
      if (value !== "" && !regex.test(value)) {
        value = passengers[i][field]
      }
      if (value.length === 11) value = passengers[i][field]
      if (value.length === 8) {
        if (value.charAt(7) === "-") value = value.substr(0, 7)
        else value = value.slice(0, -1) + "-" + value.slice(-1)
      }
      if (value.length === 5) {
        if (value.charAt(4) === "-") value = value.substr(0, 4)
        else value = value.slice(0, -1) + "-" + value.slice(-1)
      }
      if (
        (passengers[i][field].length === 3 && value.length === 4) ||
        (passengers[i][field].length === 6 && value.length === 7)
      ) {
        value = value + "-"
      }
    }

    // handlePassengerBirthDateChange(i, field, value)

    const passenger = { ...passengers[i], [field]: value }
    if (passenger[field + "_error"]) delete passenger[field + "_error"]
    if (passenger["birthDate_error"]) delete passenger["birthDate_error"]

    setPassengers([...passengers.slice(0, i), passenger, ...passengers.slice(i + 1, passengers.length)])
  }

  const onChange = (target: "billingInfo" | "contactInfo") => (field: string, value: string) => {
    let target_billing_contact
    switch (target) {
      case "billingInfo":
        target_billing_contact = billingInfo
        break
      case "contactInfo":
        target_billing_contact = contactInfo
        break
    }

    let regex
    if (field === "fiscal_id") {
      regex = /^[a-zA-Z0-9]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }
    if (field === "fiscal_name" || field === "city") {
      regex = /^[a-zA-Z\sñáéíóúÁÉÍÓÚ]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }
    if (field === "street") {
      regex = /^[a-zA-Z0-9\s.,#-]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }
    if (field === "street_number" || field === "floor_number" || field === "building_number") {
      regex = /^[a-zA-Z0-9\s-]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }
    if (field === "postal_code") {
      regex = /^[\d-]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }

    if (field === "email" || field === "email_confirmation") {
      regex = /^[a-zA-Z0-9@._%+-]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }

    if (field === "telephone_country_code" || field === "telephone_area_code" || field === "telephone_number") {
      regex = /^[\d]*$/
      if (!regex.test(value)) {
        value = target_billing_contact[field]
      }
    }
    switch (target) {
      case "billingInfo":
        const billing = { ...billingInfo, [field]: value }
        if (billing[field + "_error"]) delete billing[field + "_error"]
        setBillingInfo(billing)
        break

      case "contactInfo":
        const contact = { ...contactInfo, [field]: value }
        if (contact[field + "_error"]) delete contact[field + "_error"]
        setContactInfo(contact)
        break
    }
  }

  const hideTermsAndConditions = () => {
    setShowTermAndConditions(false)
  }

  const handleShowTermAndConditions = () => {
    setShowTermAndConditions(true)
  }

  const onChangeTermAndConditions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setTermsAndConditionAccepted(checked)
  }

  const handleChangeEmergencyContact = (c: CheckoutEmergencyContact) => {
    setEmergencyContact({
      ...c,
      errors: {}
    })
  }

  const goBackToSearch = () => {
    history.goBack()
  }

  const handleRedirectToExternalPayment = (id: string, trackingId: string) => {
    if (!config?.checkout_url || config.checkout_url === "/checkout/{product}/{id}") {
      return
    }

    let replacedPath = config!.checkout_url
      .replace("{id}", id)
      .replace("{product}", "flights")
      .replace("{tracking_id}", trackingId || "")
      .replace("{selected_brand}", selectedBrand.toString())

    const { _ga } = qs.parse(window.location.search.substr(1))

    if (_ga) {
      replacedPath = replacedPath.replace("{_ga}", _ga)
    } else {
      const ga = ReactGA.ga()
      ga(function(tracker) {
        const clientId = tracker.get("clientId")
        replacedPath = replacedPath.replace("{_ga}", clientId)
      })
    }

    window.location.href = replacedPath
  }

  const getArrivalDate = (cluster: Cluster): Date => {
    if (cluster.segments.length > 0) {
      const lastSegment = cluster.segments[cluster.segments.length - 1]

      if (lastSegment.options.length > 0) {
        const lastOption = lastSegment.options[lastSegment.options.length - 1]

        return moment(lastOption.arrival_date + " " + lastOption.arrival_time, "YYYY-MM-DD HH:mm")
          .utc()
          .toDate()
      }
    }

    return new Date()
  }

  const handleAcceptNewFare = () => {
    setProduct(changeFareProduct)
    setChangeFareProduct(undefined)
    setOriginalFare(undefined)
    setOpenChangeFareDialog(false)
    setIsFetching(false)

    handleRedirectToExternalPayment(match.params.id, product?.tracking_id || "")

    if (pendingReserve) {
      setContinueReserve(true)
    }
  }

  const handleSelectInsurancesCluster = (cluster: model.CrossSellingCluster, pc: InsurancesProviderConfig) => {
    let arr: CrossSellingCluster[]
    let selectedCluster: CrossSellingCluster | undefined

    if (cluster.cluster) {
      arr = [...crossSellingClusters]

      selectedCluster = cluster

      const idx = arr.findIndex(c => c.productType === cluster.productType)

      if (idx >= 0) {
        arr[idx] = cluster
      } else {
        arr.push(cluster)
      }
    } else {
      arr = crossSellingClusters.filter(c => c.productType !== cluster.productType)

      selectedCluster = undefined
    }

    setCrossSellingClusters(arr)
    setLastSelectedCrossSellingClusterProviderConfig(pc)
    setEmergencyContact({
      ...emergencyContact,
      errors: selectedCluster ? emergencyContact.errors : {}
    })
  }

  let requiredDocAlert: any[] = []
  let chargeDisclamers: any[] = []
  let charges: any[] = []
  const chkConfig = checkoutConfig.config[site]

  if (isFetching) {
    return (
      <div className={`${styles.background(theme)}`}>
        {changeFareProduct && isValidFare(originalFare) && (
          <ChangedFareDialog
            open={openChangeFareDialog}
            isMobile={isMobile}
            closeOnlyUserAction={true}
            currency={changeFareProduct.price.currency}
            newPrice={changeFareProduct.price.total}
            oldPrice={originalFare!}
            handleAcceptNewFare={handleAcceptNewFare}
            handleRejectNewFare={goBackToSearch}
          />
        )}
        <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="FLIGHTS_CHECKOUT" />
      </div>
    )
  }

  if (notFound) {
    return (
      <div className={`${styles.background(theme)}`}>
        <div className={`${styles.checkout}`}>
          <div className={`${styles.notFoundContainer}`}>
            <NotFound description={t<string>("FlightsCheckout.notFoundFlight")} onBackToSearch={goBackToSearch} />
          </div>
        </div>
      </div>
    )
  }

  if (formConfig.passengerDisclaimers.enabled) {
    const email = formConfig.passengerDisclaimers.email
    const passengerTypes = [...new Set(passengers.map(passenger => passenger.type))]
    for (let type of passengerTypes) {
      if (type === "SRC") {
        requiredDocAlert.push(<RequiredDocAlert type="SRC" email={email} />)
      } else if (type === "DIS") {
        requiredDocAlert.push(<RequiredDocAlert type="DIS" email={email} />)
      }
    }
  }

  if (product && geo) {
    // Price breakdown
    const price = product.upsell_fares ? product.upsell_fares[selectedBrand].price : product.price

    if (price.adults) {
      chargeDisclamers.push({
        label: t("FlightsCheckout.adultLabel"),
        price: price.currency + " " + formatPrice(price.adults.fare / price.adults.quantity, site)
      })
      charges.push({
        label: t("FlightsCheckout.adt", { count: price.adults.quantity }),
        price: price.currency + " " + formatPrice(price.adults.fare, site)
      })
    }
    if (price.seniors) {
      chargeDisclamers.push({
        label: t("FlightsCheckout.seniorLabel"),
        price: price.currency + " " + formatPrice(price.seniors.fare / price.seniors.quantity, site)
      })
      charges.push({
        label: t("FlightsCheckout.src", { count: price.seniors.quantity }),
        price: price.currency + " " + formatPrice(price.seniors.fare, site)
      })
    }
    if (price.disabled) {
      chargeDisclamers.push({
        label: t("FlightsCheckout.disabledLabel"),
        price: price.currency + " " + formatPrice(price.disabled.fare / price.disabled.quantity, site)
      })
      charges.push({
        label: t("FlightsCheckout.dis", { count: price.disabled.quantity }),
        price: price.currency + " " + formatPrice(price.disabled.fare, site)
      })
    }
    if (price.children) {
      chargeDisclamers.push({
        label: t("FlightsCheckout.childrenLabel"),
        price: price.currency + " " + formatPrice(price.children.fare / price.children.quantity, site)
      })
      charges.push({
        label: t("FlightsCheckout.chd", { count: price.children.quantity }),
        price: price.currency + " " + formatPrice(price.children.fare, site)
      })
    }
    if (price.infants) {
      chargeDisclamers.push({
        label: t("FlightsCheckout.infantsLabel"),
        price: price.currency + " " + formatPrice(price.infants.fare / price.infants.quantity, site)
      })
      charges.push({
        label: t("FlightsCheckout.inf", { count: price.infants.quantity }),
        price: price.currency + " " + formatPrice(price.infants.fare, site)
      })
    }
    charges.push({
      label: t("FlightsCheckout.taxes"),
      price: price.currency + " " + formatPrice(price.taxes, site)
    })
    charges.push({
      label: price.charges >= 0 ? t("FlightsCheckout.charges") : t("FlightsCheckout.discount"),
      price: price.currency + " " + formatPrice(price.charges, site)
    })

    return (
      <div className={`${styles.background(theme)}`}>
        {isBooking && (
          <>
            {changeFareProduct && isValidFare(originalFare) && (
              <ChangedFareDialog
                open={openChangeFareDialog}
                isMobile={isMobile}
                closeOnlyUserAction={true}
                currency={changeFareProduct.price.currency}
                newPrice={changeFareProduct.price.total}
                oldPrice={originalFare!}
                handleAcceptNewFare={handleAcceptNewFare}
                handleRejectNewFare={goBackToSearch}
              />
            )}
            <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="FLIGHTS_PAYMENT" />
          </>
        )}
        {!isBooking && (
          <div className={`${styles.checkout}`}>
            <p className={`${styles.title(theme)}`}>{t<string>("FlightsCheckout.title")}</p>
            <div className={`${styles.mainContainer}`}>
              <div className={`${styles.detailContainer}`}>
                <div className={`${styles.priceBreakdownContainer}`}>
                  <PriceboxBreakdownCheckoutWeb flightPrice={price} />
                </div>
                <div className={`${styles.flightDetailContainer}`}>
                  <FlightDetail
                    origin={product.segments[0]?.origin?.code || ""}
                    destination={product.segments[0]?.destination?.code || ""}
                    cluster={product}
                    selectedBrand={selectedBrand}
                  />
                </div>
              </div>
              <div className={`${styles.formContainer}`}>
                {requiredDocAlert}
                {formConfig.passengers && (
                  <FormSection title={t<string>("FlightsCheckout.passengersTitle")}>
                    <PassengersContainer
                      onChange={onPassengerChange}
                      countries={geo.countries}
                      passengers={passengers}
                      maxDate={arrivalDate}
                      checkoutConfig={chkConfig}
                    />
                  </FormSection>
                )}
                {formConfig.billingInfo && (
                  <FormSection title={t<string>("FlightsCheckout.billingTitle")}>
                    {formConfig.autocompleteBillingInfo && (
                      <Message open variant="fixed" action="info" message={t("FormConfig.autocompleteBillingInfo")} />
                    )}
                    <BillingContainer
                      onChange={onChange("billingInfo")}
                      billingInfo={billingInfo}
                      checkoutConfig={chkConfig}
                      geo={geo}
                    />
                  </FormSection>
                )}
                <FormSection>
                  <BaggageAllowance cluster={product as Cluster} selectedBrand={selectedBrand} />
                </FormSection>
                {formConfig.contactInfo && (
                  <FormSection title={t<string>("FlightsCheckout.contactTitle")}>
                    <ContactContainer onChange={onChange("contactInfo")} contact={contactInfo} />
                  </FormSection>
                )}
                {insuranceSearchParams && (
                  <InsurancesCrossSelling
                    insuranceSearchParams={insuranceSearchParams}
                    emergencyContact={emergencyContact}
                    onClusterSelected={handleSelectInsurancesCluster}
                    onChangeEmergencyContact={handleChangeEmergencyContact}
                    lastSelectedClusterProviderConfig={lastSelectedCrossSellingClusterProviderConfig}
                  />
                )}
              </div>
            </div>

            <div className={`${styles.bottomContainer}`}>
              <div className={`${styles.termAndConditions(theme)}`}>
                <Checkbox
                  classes={{
                    root: `${styles.checkbox}`,
                    checked: `${styles.checkboxChecked(theme)}`
                  }}
                  checked={termsAndConditionAccepted}
                  onChange={onChangeTermAndConditions}
                />
                <p>
                  {t<string>("FlightsCheckout.termAndConditionsAccept")}{" "}
                  <button onClick={handleShowTermAndConditions}>
                    {t<string>("FlightsCheckout.termAndConditionsLink")}
                  </button>
                </p>
              </div>
              <Button
                id="buy-button"
                variant={"contained"}
                classes={{ root: `${styles.button(theme)}` }}
                disabled={isBooking || !termsAndConditionAccepted}
                onClick={() => validateAndPurchase(product)}
              >
                {paymentsURL ? t<string>("FlightsCheckout.buyButton") : t<string>("FlightsCheckout.reserveButton")}
              </Button>
              <TermsAndConditions
                showTermAndConditions={showTermAndConditions}
                hideTermsAndConditions={hideTermsAndConditions}
                termAndConditions={termAndConditions}
                showProviderTerms={false}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  return <></>
}

export default FlightsCheckout
