// @flow

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./index.css"
import "./i18n/i18n"
// IE  polyfill
import "core-js/features/set"
ReactDOM.render(<App />, document.getElementById("root"))
