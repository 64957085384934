import { FetchError } from "@basset-la/components-flights/dist/model"
import { Cart } from "../components/combined/types"

const createQueryString = (mapQs: any) => new URLSearchParams(mapQs).toString()

export const getCart = async (config: any, id: string): Promise<Cart> => {
  const filtersStr = "".concat(
    createQueryString({
      cart_id: id,
      site: config.country,
      channel: "WEB",
      source: config.source,
      language: config.language
    })
  )
  const url = process.env.REACT_APP_COMBINED_PRODUCTS_API_URL
    ? `${process.env.REACT_APP_COMBINED_PRODUCTS_API_URL}?${filtersStr}`
    : ""

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY || "",
    "x-client-id": config.agency_id
  }
  const results = await fetch(url, {
    method: "GET",
    headers: headers
  })

  const json = await results.json()
  if (!results.ok) {
    throw new FetchError(results.status, json.error)
  }
  return json
}
