import React, { useEffect, useState } from "react"
import styles from "./CombinedThanks.styles"
import { getFlightReservation } from "../../../api/flights"
import { getReservation } from "../../../api/reservations"
import ThanksTotal from "../../thanks/ThanksTotal"
import ReservationConfirmationText from "../../thanks/ReservationConfirmationText"
import ThanksReservationDetail from "../../thanks/ThanksReservationDetail"
import ThanksFlightDetail from "../../thanks/ThanksFlightDetail"
import { formatPrice } from "../../../utils/flightsUtils"
import ThanksProductReservationDetail from "../../thanks/ThanksProductReservationDetail"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import { ThanksCombinedProduct } from "../types"
import { useRouteMatch } from "react-router-dom"
import { getAccommodation, getAccommodationReservation } from "../../../api/accommodations"
import AccommodationCluster from "@basset-la/commons-frontend/dist/components/accommodations/AccommodationCluster"
import AccommodationDescription from "../../accommodations/AccommodationDescription"
import CancelPenalties from "@basset-la/components-accommodations/dist/components/CancelPenalties"
import moment from "moment"
import ReservationDetailTitle from "./ReservationDetailTitle"
import { useTheme } from "@basset-la/themed-components"
import ReservationDetailBreakdown from "./ReservationDetailBreakdown"
import Check from "../../../icons/Check"
import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"
import { removeCartState } from "../helper"
import { useAuthUser, useConfig } from "@basset-la/components-commons"

interface RouteMatch {
  id: string
}

const CombinedThanks = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const match = useRouteMatch<RouteMatch>()

  const { config } = useConfig()
  const { userId } = useAuthUser()

  const [reservation, setReservation] = useState<any>(null)
  const [combinedProduct, setCombinedProduct] = useState<ThanksCombinedProduct | null>(null)

  useEffect(() => {
    const retrieveReservation = async (id: string) => {
      try {
        const reservation = await getReservation(id, userId, config.country, config.agency_id)

        const flightResId = reservation.products.find(p => p.type === "FLIGHT").reservation_id
        const accResId = reservation.products.find(p => p.type === "ACCOMMODATION").reservation_id
        const flightRes = await getFlightReservation(flightResId, i18n.language)
        const accRes = await getAccommodationReservation(accResId, config.country, config.agency_id)
        const accommodation = await getAccommodation(accRes.accommodation.id, i18n.language, config.agency_id)

        removeCartState()
        setReservation(reservation)
        setCombinedProduct({
          flightReservation: flightRes,
          accommodationReservation: accRes,
          accommodation: accommodation
        })
      } catch (e) {
        console.log(e)
      }
    }

    retrieveReservation(match.params.id)
  }, [i18n.language, match.params.id, userId])

  if (!reservation || !combinedProduct) {
    return <AppLoader variant="FLIGHTS" />
  }

  const checkin = moment(combinedProduct.accommodationReservation.accommodation.rate.checkin)
  const checkout = moment(combinedProduct.accommodationReservation.accommodation.rate.checkout)
  const checkinDate = checkin.utc().format("ddd DD MMM YYYY")
  const checkoutDate = checkout.utc().format("ddd DD MMM YYYY")
  const nights = checkout.diff(checkin, "days")
  const rooms = combinedProduct.accommodationReservation.accommodation.rate.rooms
  const comments = combinedProduct.accommodationReservation.accommodation.rate.comments
  let checkinInstructions = ""
  if (combinedProduct.accommodationReservation.accommodation.checkin.instructions) {
    checkinInstructions += combinedProduct.accommodationReservation.accommodation.checkin.instructions
  }
  if (combinedProduct.accommodationReservation.accommodation.checkin.special_instructions) {
    checkinInstructions += combinedProduct.accommodationReservation.accommodation.checkin.special_instructions
  }
  checkinInstructions += t("AccommodationsThanks.checkin_instructions_disclamer")

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.checkIcon}>
          <Check />
        </div>
        <p className={styles.titleText}>{t<string>("FlightsThanks.title")}</p>
      </div>
      <div className={styles.resInfoContainer}>
        <ThanksTotal
          currency={combinedProduct.flightReservation.cluster.price.currency}
          total={formatPrice(combinedProduct.flightReservation.cluster.price.total, config.country)}
        />
        <ReservationConfirmationText reservationID={reservation.id} email={reservation.contact?.email || ""} />
        <ThanksProductReservationDetail
          title={t<string>("ThanksProductReservationDetail.hotelCode")}
          code={combinedProduct.accommodationReservation.provider_reservation_id}
        />
        <ThanksProductReservationDetail
          title={t<string>("ThanksProductReservationDetail.pnr")}
          code={combinedProduct.flightReservation.pnr}
        />
      </div>
      <div className={styles.borderSolid}>
        <ThanksReservationDetail passengers={combinedProduct.flightReservation.passengers} />
        <ThanksFlightDetail cluster={combinedProduct.flightReservation.cluster} />
      </div>

      <div className={styles.borderSolid}>
        <div className={styles.accommodationContainer(theme)}>
          <ReservationDetailTitle nights={nights} totalRooms={rooms.length} />
          <div className={styles.accommodationInnerContainer}>
            <div className={styles.accommodationInnerContainerItem}>
              <AccommodationCluster
                name={combinedProduct.accommodation.name}
                address={combinedProduct.accommodation.address}
                stars={combinedProduct.accommodation.stars}
                rating={combinedProduct.accommodation.rating}
                mealPlan={t(
                  `Accommodation.mealPlan.${combinedProduct.accommodationReservation.accommodation.rate.meal_plan}`
                )}
                photos={combinedProduct.accommodation.images}
                refundable={combinedProduct.accommodationReservation.accommodation.rate.refundable}
                mini
                borderless
              />
            </div>
            <div className={styles.accommodationInnerContainerItem}>
              <ReservationDetailBreakdown
                rooms={rooms}
                checkinDate={checkinDate}
                checkinTime={combinedProduct.accommodation.checkin.time}
                checkoutDate={checkoutDate}
                checkoutTime={combinedProduct.accommodation.checkout.time}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.borderSolid}>
        {checkinInstructions.length > 0 && (
          <AccommodationDescription
            title={t<string>("AccommodationsThanks.checkin_instructions")}
            description={checkinInstructions}
          />
        )}
        <CancelPenalties
          cancelPenalties={combinedProduct.accommodationReservation.accommodation.rate.cancel_penalties}
          expirationDate={combinedProduct.accommodationReservation.accommodation.rate.expiration_date}
          customExpirationDate={combinedProduct.accommodationReservation.accommodation.rate.custom_expiration_date}
          refundable={combinedProduct.accommodationReservation.accommodation.rate.refundable}
        />
        {comments.length > 0 && (
          <AccommodationDescription title={t<string>("AccommodationsThanks.rate_comments")} description={comments} />
        )}
      </div>
    </div>
  )
}

export default CombinedThanks
