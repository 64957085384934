import { FlightsCheckoutPassenger } from "@basset-la/commons-frontend/dist/components/types"
import { ChangeFareCluster, Cluster } from "@basset-la/components-flights/dist/model"
import { FormConfig } from "../config/formConfig"
import { checkoutConfig } from "@basset-la/components-products/dist/utils/config"
import { CheckoutPassenger as InsuranceCheckoutPassenger } from "@basset-la/components-insurances/dist/models/checkout"
import { Coverage } from "@basset-la/components-insurances/dist/models"

const createFlightPurchaseModel = (
  itineraryID: string,
  passengers: FlightsCheckoutPassenger[],
  billingInfo: any,
  contact: any,
  site: string,
  formConfig: FormConfig,
  lang: string,
  cluster: Cluster,
  selectedBrand: number,
  crossSellingProducts: any[]
) => {
  let pass: any[] = []
  let fiscalIndetification: any = null
  let fiscalData: any = null
  let contactInfo: any = null

  if (formConfig.passengers) {
    for (let p of passengers) {
      pass.push({
        type: p.type,
        firstname: p.firstname,
        lastname: p.lastname,
        gender: p.gender,
        birth: `${p.birthDay}/${p.birthMonth}/${p.birthYear}`,
        document: {
          type: p.document_type,
          number: p.document_number,
          issuing_country: p.document_issuing_country,
          valid_thru: p.document_valid_thru
        },
        nationality: p.nationality
      })
    }
  }

  if (formConfig.billingInfo) {
    const siteConfig = checkoutConfig.config[site as keyof typeof checkoutConfig]
    const typeFiscal =
      siteConfig.billing.identification
        .find(o => o.name === "fiscal_situation")
        ?.options?.find(ft => ft.value === billingInfo.fiscal_situation)?.code ?? ""

    fiscalIndetification = {
      type: typeFiscal,
      number: billingInfo.fiscal_id
    }
    fiscalData = {
      identification: {
        type: typeFiscal,
        number: billingInfo.fiscal_id,
        fiscal_name: billingInfo.fiscal_name,
        tax_situation: billingInfo.fiscal_situation
      },
      address: {
        state: billingInfo.province,
        city: billingInfo.city,
        country: site,
        street: billingInfo.street,
        number: billingInfo.street_number,
        floor: billingInfo.floor_number,
        door: billingInfo.building_number,
        postal_code: billingInfo.postal_code
      }
    }
  }

  if (formConfig.contactInfo) {
    contactInfo = {
      language: lang,
      email: contact.email,
      telephone: {
        type: contact.telephone_type,
        country_code: contact.telephone_country_code,
        area_code: contact.telephone_area_code,
        number: contact.telephone_number
      }
    }
  }

  const brandId = cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].id : null
  const price = cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price : cluster.price

  let product = {
    item_id: itineraryID,
    type: "FLIGHT",
    passengers: pass,
    itinerary_info: {
      origin: cluster.segments[0].origin.code,
      destination: cluster.segments[0].destination.code,
      departure_date: cluster.segments[0].departure_date,
      return_date: cluster.segments.length > 1 ? cluster.segments[1].departure_date : "",
      currency: price.currency,
      price: `${price.total}`
    },
    upsell_fare_id: brandId
  }

  return {
    products: [product, ...crossSellingProducts],
    //@deprecate use fiscal_data
    fiscal_identification: fiscalIndetification,
    fiscal_data: fiscalData,
    contact: contactInfo,
    autocomplete_billing_info: formConfig.autocompleteBillingInfo
  }
}

export const mapInsuranceFlightPassenger = (
  passengers: FlightsCheckoutPassenger[],
  coverage: Coverage[]
): InsuranceCheckoutPassenger[] =>
  passengers.map(p => {
    const {
      firstname,
      lastname,
      birthDay,
      birthMonth,
      birthYear,
      document_type,
      document_number,
      document_valid_thru
    } = p

    return {
      name: firstname,
      lastName: lastname,
      birthDay: birthDay,
      birthMonth: birthMonth,
      birthYear: birthYear,
      documentType: document_type as any,
      documentNumber: document_number,
      validThru: document_valid_thru,
      coverages: coverage.map(c => c.name) || [],
      errors: {},
      disabledFields: {
        firstname: false,
        lastname: false,
        document: false,
        birthDate: false,
        validThru: false
      }
    } as InsuranceCheckoutPassenger
  })

const formatPrice = (price: number | string, site?: string) => {
  return parseFloat(price as string).toLocaleString("es", {
    minimumFractionDigits: site && site === "CL" ? 0 : 2,
    maximumFractionDigits: 2
  })
}

const isValidFare = (fare: number | undefined | null): boolean => {
  if (fare === undefined || fare === null || isNaN(fare)) {
    return false
  }
  return true
}

const validateFieldAirlines = (cluster: ChangeFareCluster | null): boolean => {
  if (!cluster) return false

  if (cluster.source_type !== "NDC") return false

  const sources = new Set(["AMNDC", "LA", "AA"])

  if (!sources.has(cluster.source)) return false

  const airlines = new Set(["AA", "LA"])

  if (!airlines.has(cluster.segments[0].options[0].legs[0].marketing_carrier.code)) return false

  return true
}

export { createFlightPurchaseModel, formatPrice, isValidFare, validateFieldAirlines }
