import { FlightsCheckoutPassenger } from "@basset-la/commons-frontend/dist/components/types"
import { CrossSellingCluster } from "../components/types"
import { FormConfig } from "../config/formConfig"
import { mapInsuranceFlightPassenger } from "./flightsUtils"
import { createInsurancePurchaseModel } from "./insurancesUtils"
import { CheckoutEmergencyContact } from "./common"

export const createCrossSellingProducts = (
  clusters: CrossSellingCluster[],
  flightsPassengers: FlightsCheckoutPassenger[],
  emergencyContact: CheckoutEmergencyContact,
  billingInfo: any,
  contactInfo: any,
  formConfig: FormConfig,
  site: string,
  lang: string
) => {
  const products = clusters.map(c => {
    switch (c.productType) {
      case "TRAVEL_INSURANCES":
        const insurancePassengers = mapInsuranceFlightPassenger(flightsPassengers, c.cluster?.coverages || [])

        const model = createInsurancePurchaseModel(
          insurancePassengers,
          emergencyContact,
          billingInfo,
          contactInfo,
          formConfig,
          site,
          lang,
          c.cluster
        )

        return model.products[0]
      default:
        return null
    }
  })

  return products.filter(p => !!p)
}
