import { Theme } from "@basset-la/themed-components/dist/model/types"
import { css } from "@emotion/css"

const styles = {
  background: (theme: Theme) =>
    css({
      backgroundColor: theme.colors.background_primary,
      display: "flex"
    }),

  notFoundRoot: css({
    margin: "0 auto",
    padding: "16px 8px",
    flex: "1 1 0%",
    "@media(min-width:1200px)": {
      maxWidth: "1320px"
    },
    /* IE Fix */
    "@media (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      margin: "50px"
    }
  }),

  notFoundContainer: css({
    minHeight: "608px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }),

  container: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Roboto",
    padding: "8px"
  }),

  meesageContainer: css({
    display: "flex",
    flexDirection: "row",
    padding: "8px",
    alignSelf: "flex-start"
  }),

  checkIcon: css({
    width: "24px",
    height: "24px",
    marginTop: "4px",
    backgroundSize: "contain"
  }),

  titleText: (t: Theme) =>
    css({
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "1.33",
      margin: 0,
      color: t.colors.brand_darker,
      marginLeft: "8px"
    }),

  content: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",

    "@media(min-width:1200px)": {
      gap: "16px"
    }
  }),

  infoContainer: css({
    "@media(min-width:1200px)": {
      display: "flex",
      flexDirection: "row"
    }
  }),

  thanksTitle: css({
    fontWeight: "400 !important"
  }),

  thanksContainer: css({
    minWidth: "208px",
    flex: 0
  }),

  confirmationContainer: css({
    minWidth: "432px",
    flex: 0,
    "@media(max-width: 1024px)": {
      minWidth: "0px"
    }
  }),

  card: (t: Theme, fullWidth: boolean = false) =>
    css({
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      border: "solid 1px #CCC",
      backgroundColor: t.colors.background_primary,
      padding: "16px",
      justifyContent: "space-between",
      width: fullWidth ? "100%" : undefined
    }),

  planCoveragesTitles: css({
    fontSize: "18px !important"
  }),

  reservationText: css({
    fontWeight: "400 !important"
  }),

  infoWrapper: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column"
  })
}

export default styles
