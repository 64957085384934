import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  borderSolid: css({
    border: "solid 1px #CCC"
  }),

  resInfoContainer: css({
    "@media(min-width:1200px)": {
      display: "flex",
      flexDirection: "row"
    }
  }),

  container: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    margin: "0 auto",
    gap: "16px",
    "@media(min-width:1024px)": {
      maxWidth: "880px"
    }
  }),

  accommodationContainer: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      gap: "16px",
      backgroundColor: t.colors.background_primary
    }),

  accommodationInnerContainer: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    " > div": {
      width: "unset !important"
    },
    "@media(max-width:1024px)": {
      flexDirection: "column"
    }
  }),

  accommodationInnerContainerItem: css({
    flex: "1 1 auto"
  }),

  title: css({
    display: "flex",
    flexDirection: "row",
    padding: "8px"
  }),

  titleText: css({
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: 300,
    lineHeight: "1.33",
    margin: 0,
    color: "#0d207b",
    marginLeft: "8px"
  }),

  checkIcon: css({
    width: "24px",
    height: "24px",
    marginTop: "4px",
    backgroundSize: "contain"
  })
}
