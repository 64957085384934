import { css } from "@emotion/css"

export default {
  wrapper: css({
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    position: "fixed",
    zIndex: 5000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: "0.8"
  })
}
