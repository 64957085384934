import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  container: css({
    border: "solid 1px #CCC",
    backgroundColor: "#fff",
    padding: "16px 8px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    "@media(min-width:1200px)": {
      flex: "1 1 0%",
      marginBottom: "0px",
      marginRight: "16px"
    }
  }),

  title: (theme: Theme) =>
    css({
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: "300",
      lineHeight: "1.33",
      color: theme.colors.brand_primary,
      margin: 0
    }),

  price: css({
    fontFamily: "Roboto",
    margin: 0,
    fontSize: "18px",
    lineHeight: "1.33",
    color: "#4a4a4a",
    fontWeight: "500"
  })
}

export default styles
