import React from "react"

const Ticket = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="191" height="126" viewBox="0 0 191 126">
      <g fill="none" fillRule="evenodd" transform="translate(0 -2)">
        <g fill="#0D207B">
          <path d="M105.645 54.587l-9.496 1.986a1.46 1.46 0 0 0-1.074.923l-5.47 14.776-.704.148 1.243-12.66a1.454 1.454 0 0 0-.479-1.226 1.47 1.47 0 0 0-1.276-.339l-9.498 1.987c-.45.094-.83.394-1.026.809l-1.524 3.236.324-4.37a1.449 1.449 0 0 0-.165-.783l-2.055-3.885 2.698 2.355c.345.302.814.424 1.264.33l9.498-1.986c.456-.095.838-.4 1.032-.822.193-.422.174-.91-.053-1.313l-6.223-11.099.704-.147L94.31 53.85c.349.36.86.518 1.355.415l9.497-1.986c.592-.124 1.272.321 1.397.917.121.58-.332 1.268-.914 1.39m-1.081-5.156l-8.704 1.82-10.945-11.344a1.466 1.466 0 0 0-1.354-.415l-3.446.72c-.455.095-.838.4-1.032.822a1.45 1.45 0 0 0 .054 1.314l6.223 11.099-6.834 1.429-2.756-2.407a1.468 1.468 0 0 0-1.265-.33l-2.585.541c-.45.094-.829.393-1.025.806a1.45 1.45 0 0 0 .028 1.298l2.793 5.281-.44 5.946c-.032.456.151.9.496 1.2.346.301.813.423 1.262.329l2.587-.541c.45-.094.83-.394 1.025-.81l1.559-3.308 6.833-1.43-1.243 12.661c-.045.461.133.915.48 1.225.345.308.819.434 1.275.338l3.445-.72a1.46 1.46 0 0 0 1.074-.923l5.47-14.776 8.704-1.82c2.177-.455 3.634-2.67 3.18-4.837-.456-2.173-2.68-3.623-4.859-3.168M39.288 52.399a1.459 1.459 0 0 0-1.133 1.723l5.238 24.988a1.46 1.46 0 0 0 1.73 1.125 1.458 1.458 0 0 0 1.134-1.724l-5.239-24.988a1.46 1.46 0 0 0-1.73-1.124M45.827 56.275a1.459 1.459 0 0 0-1.133 1.724l3.172 15.139a1.46 1.46 0 0 0 1.732 1.124 1.459 1.459 0 0 0 1.133-1.723l-3.174-15.14a1.46 1.46 0 0 0-1.73-1.124" />
          <path d="M77.49 41.435l-15.217 3.183a.972.972 0 0 0-.756 1.149c.111.524.627.86 1.154.75l15.219-3.182a.973.973 0 0 0 .754-1.15.973.973 0 0 0-1.153-.75M69.607 77.837l15.217-3.182a.972.972 0 0 0 .756-1.149.973.973 0 0 0-1.154-.75l-15.218 3.181a.973.973 0 0 0-.755 1.15c.11.525.626.86 1.154.75M85.408 68.62a.972.972 0 0 0-1.154-.749L76.1 69.576a.97.97 0 1 0 .399 1.899l8.153-1.705a.972.972 0 0 0 .756-1.15M70.89 48.836c.11.524.627.86 1.154.75l8.154-1.706a.971.971 0 0 0 .755-1.149.972.972 0 0 0-1.153-.75l-8.154 1.706a.971.971 0 0 0-.755 1.149" />
          <path d="M125.833 81.015l-.419-1.993a3.963 3.963 0 0 1-1.37.577 3.985 3.985 0 0 1-1.245.044l.42 1.981.742 3.508-76.723 16.043c-4.515.944-8.953-1.941-9.894-6.432l-10.72-51.139c-.943-4.49 1.964-8.912 6.478-9.855L109.7 17.731l1.176 5.56.42 1.983c.378-.23.792-.414 1.251-.51a3.97 3.97 0 0 1 1.47-.022l-.418-1.993-1.473-7.013a1.459 1.459 0 0 0-1.73-1.125L32.506 30.9c-6.093 1.275-10.016 7.243-8.746 13.304L34.48 95.342c1.27 6.062 7.26 9.956 13.355 8.682l77.918-16.294a1.46 1.46 0 0 0 1.155-1.596l-1.075-5.12z" />
          <path d="M119.665 62.668l.42 1.983.928 4.387.42 1.98a3.939 3.939 0 0 1 1.168-.466 3.987 3.987 0 0 1 1.486-.02l-.418-1.994-.943-4.492-.419-1.994a3.96 3.96 0 0 1-1.367.575 3.94 3.94 0 0 1-1.275.041M116.006 47.539l.42 1.985.931 4.4.42 1.982c.364-.214.759-.389 1.195-.48a3.956 3.956 0 0 1 1.481-.022l-.418-1.993-.942-4.49-.419-1.992c-.405.264-.86.467-1.363.572-.442.093-.88.09-1.305.038M112.178 31.454l.42 1.987.935 4.415.42 1.984a3.937 3.937 0 0 1 1.224-.495c.5-.106.998-.102 1.475-.023l-.418-1.993-.943-4.486-.418-1.993a3.97 3.97 0 0 1-1.358.57 3.93 3.93 0 0 1-1.337.034M163.244 58.089l1.469.436 1.885-6.277-1.468-.436-.677 2.252-6.376-1.896-.534 1.773 6.378 1.897zM163.973 60.937l-7.845-2.333-.535 1.784 7.845 2.333zM153.684 70.131l1.17-1.255c-.559-.616-.752-1.316-.52-2.086.348-1.16 1.508-1.81 3.19-1.31 1.68.5 2.292 1.677 1.943 2.836-.232.77-.778 1.25-1.587 1.46l.286 1.689c1.34-.307 2.37-1.3 2.812-2.773.683-2.274-.461-4.278-2.916-5.007-2.453-.73-4.515.32-5.198 2.594-.442 1.473-.128 2.866.82 3.852M151.191 73.962l1.927.572.983 1.362-3.758.886-.602 2.007 5.458-1.425 2.49 3.412.59-1.963-2.784-3.571 3.541 1.052.533-1.772-7.845-2.334zM147.79 86.239l1.423.423 1.065-3.545 1.894.563-.918 3.055 1.424.423.918-3.055 1.68.5-1.034 3.445 1.424.423 1.567-5.218-7.845-2.332zM152.749 91.906l-6.377-1.896-.532 1.773 6.376 1.896-.677 2.251 1.469.438 1.886-6.278-1.469-.437z" />
          <path d="M170.402 38.599l-10.831-3.22a1.464 1.464 0 0 0-1.821.977c-.744 2.478-3.377 3.89-5.867 3.15l-4.504-1.38-.435 1.45-.361 1.203 2.778.844c.588.278.801.409 1.684.672.248.074.498.13.747.18 3.322.636 6.662-.995 8.207-3.931l9.565 2.845c4.418 1.313 6.938 5.958 5.618 10.354l-15.042 50.061c-1.32 4.396-5.989 6.904-10.407 5.589l-9.689-2.88c.094-3.317-2-6.326-5.264-7.388-.065-.021-.121-.053-.187-.072a7.792 7.792 0 0 0-1.717-.309l-2.843-.819-.211.704-.563 1.871 4.496 1.341c2.355.7 3.76 3.033 3.267 5.426a1.454 1.454 0 0 0 1.014 1.686l10.86 3.23c5.963 1.773 12.266-1.611 14.048-7.544l15.04-50.063c1.785-5.933-1.617-12.203-7.582-13.977" />
          <path d="M135.006 89.105l.585-1.955 1.323-4.427.585-1.954c-.5.05-1.018.01-1.529-.143a3.934 3.934 0 0 1-1.298-.676l-.585 1.952-1.317 4.388-.585 1.95c.477-.038.969 0 1.454.144a3.97 3.97 0 0 1 1.367.721M139.87 73.57l.585-1.954 1.32-4.416.585-1.953a3.978 3.978 0 0 1-1.51-.143 3.955 3.955 0 0 1-1.3-.678l-.586 1.95-1.317 4.39-.585 1.95a3.958 3.958 0 0 1 1.459.146c.508.15.96.397 1.349.709M144.735 58.037l.585-1.953 1.317-4.407.584-1.951a3.98 3.98 0 0 1-1.487-.144 3.94 3.94 0 0 1-1.306-.681l-.586 1.951-1.318 4.393-.586 1.95c.481-.039.977 0 1.466.145.5.148.945.393 1.331.697" />
        </g>
        <circle cx="4" cy="49" r="4" fill="#0D207B" opacity=".318" />
        <circle cx="155" cy="17" r="4" stroke="#0D207B" strokeWidth="2" opacity=".318" />
        <circle cx="42" cy="115" r="4" stroke="#0D207B" strokeWidth="2" opacity=".318" />
        <g stroke="#0D207B" strokeLinecap="round" strokeWidth="2" opacity=".38">
          <path d="M55.309 8.816L48.601 5.4M50.246 10.461l3.418-6.708" />
        </g>
        <g stroke="#0D207B" strokeLinecap="round" strokeWidth="2" opacity=".35">
          <path d="M183.966 104.425l5.149-4.321M184.376 99.686l4.32 5.149" />
        </g>
        <circle cx="117.5" cy="110.5" r="3.5" fill="#0D207B" opacity=".15" />
        <circle cx="133" cy="126" r="2" fill="#0D207B" opacity=".26" />
        <circle cx="10" cy="28" r="2" fill="#0D207B" opacity=".26" />
      </g>
    </svg>
  )
}

export default Ticket
