import { FlightsCheckoutPassenger } from "@basset-la/commons-frontend/dist/components/types"
import { Cluster } from "@basset-la/components-flights/dist/model"
import { checkoutConfig } from "@basset-la/components-products/dist/utils/config"
import moment from "moment"

export const createCombinedPurchaseModel = (
  itineraryID: string,
  passengers: FlightsCheckoutPassenger[],
  rateID: string,
  rooms: any[],
  billingInfo: any,
  contact: any,
  site: string,
  formConfig: any,
  lang: string,
  cluster: Cluster,
  selectedBrand: number,
  roomIds: string[],
  roomCompanionsMap: Record<number, any>
) => {
  let pass: any[] = []
  let guestRooms: any[] = []
  let payment: any = null
  let fiscalIndetification: any = null
  let fiscalData: any = null
  let contactInfo: any = null
  let roomCompanions: any[] | undefined = undefined

  if (formConfig.passengers) {
    pass = passengers.map(p => {
      return {
        type: p.type,
        firstname: p.firstname,
        lastname: p.lastname,
        gender: p.gender,
        birth: `${p.birthDay}/${p.birthMonth}/${p.birthYear}`,
        document: {
          type: p.document_type,
          number: p.document_number,
          issuing_country: p.document_issuing_country,
          valid_thru: p.document_valid_thru
        },
        nationality: p.nationality
      }
    })
    guestRooms = rooms.map((r, index) => {
      return {
        id: `${roomIds[index]}_${index}`,
        firstname: r.firstName,
        lastname: r.lastName,
        document: {
          type: r.documentType,
          number: r.documentNumber
        }
      }
    })
    if (roomCompanionsMap && Object.values(roomCompanionsMap).length) {
      roomCompanions = []
      for (let key in roomCompanionsMap) {
        roomCompanionsMap[key].forEach(c =>
          roomCompanions?.push({
            firstname: c.firstName,
            lastname: c.lastName,
            document: {
              type: c.documentType,
              number: c.documentNumber
            },
            age: moment().diff(c.birthday, "years"),
            room_id: roomIds[key] ? `${roomIds[key]}_${key}` : ""
          })
        )
      }
    }
  }

  if (formConfig.billingInfo) {
    //@deprecate use fiscal_data
    const siteConfig = checkoutConfig.config[site as keyof typeof checkoutConfig]
    const typeFiscal =
      siteConfig.billing.identification
        .find(o => o.name === "fiscal_situation")
        ?.options?.find(ft => ft.value === billingInfo.fiscal_situation)?.code ?? ""

    fiscalIndetification = {
      type: typeFiscal,
      number: billingInfo.fiscal_id
    }
    fiscalData = {
      identification: {
        type: typeFiscal,
        number: billingInfo.fiscal_id,
        fiscal_name: billingInfo.fiscal_name,
        tax_situation: billingInfo.fiscal_situation
      },
      address: {
        country: billingInfo.country ? billingInfo.country : site,
        state: billingInfo.province,
        city: billingInfo.city,
        street: billingInfo.street,
        number: billingInfo.street_number,
        floor: billingInfo.floor_number,
        door: billingInfo.building_number,
        postal_code: billingInfo.postal_code
      }
    }
  }

  if (formConfig.contactInfo) {
    contactInfo = {
      language: lang,
      email: contact.email,
      telephone: {
        type: contact.telephone_type,
        country_code: contact.telephone_country_code,
        area_code: contact.telephone_area_code,
        number: contact.telephone_number
      }
    }
  }

  const brandId = cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].id : null
  const price = cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price : cluster.price

  return {
    products: [
      {
        item_id: itineraryID,
        type: "FLIGHT",
        passengers: pass,
        itinerary_info: {
          origin: cluster.segments[0].origin.code,
          destination: cluster.segments[0].destination.code,
          departure_date: cluster.segments[0].departure_date,
          return_date: cluster.segments.length > 1 ? cluster.segments[1].departure_date : "",
          currency: price.currency,
          price: `${price.total}`
        },
        upsell_fare_id: brandId
      },
      {
        item_id: rateID,
        type: "ACCOMMODATION",
        rooms: guestRooms,
        room_companions: roomCompanions
      }
    ],
    payment: payment,
    //@deprecate use fiscal_data
    fiscal_identification: fiscalIndetification,
    fiscal_data: fiscalData,
    contact: contactInfo,
    autocomplete_billing_info: formConfig.autocompleteBillingInfo
  }
}
