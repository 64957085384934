export const getRate = async (id: string, agency_id: string, country: string) => {
  const baseURL = process.env.REACT_APP_RATES_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!baseURL) throw new Error("missing key REACT_APP_RATES_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")

  const uri = `${baseURL}/${id}?site=${country}&channel=WEB`
  const res = await fetch(uri, {
    headers: {
      "x-api-key": apiKey,
      "x-client-id": agency_id
    }
  })
  if (res.ok) {
    return res.json()
  }

  throw new Error("Failed to find rate")
}

export const getAccommodation = async (id: string, lang: string, agency_id: string) => {
  const url = process.env.REACT_APP_ACCOMMODATIONS_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!url) throw new Error("missing key REACT_APP_ACCOMMODATIONS_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")

  const res = await fetch(`${url}/${id}?language=${lang}&channel=WEB`, {
    headers: {
      "x-client-id": agency_id,
      "x-api-key": apiKey
    }
  })
  if (res.ok) {
    const accommodation = await res.json()
    return accommodation
  }
  throw new Error("Failed to get accommodation")
}

export const getAccommodationReservation = async (id: string, country: string, agency_id: string) => {
  const url = process.env.REACT_APP_ACCOMMODATIONS_RESERVATIONS_URL
  const apiKey = process.env.REACT_APP_API_KEY
  if (!url) throw new Error("missing key REACT_APP_ACCOMMODATIONS_RESERVATIONS_URL")
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")
  const res = await fetch(`${url}/${id}?site=${country}&channel=WEB`, {
    headers: {
      "x-client-id": agency_id,
      "x-api-key": apiKey
    }
  })
  if (res.ok) {
    return res.json()
  }
  throw new Error("Failed to get reservation")
}
