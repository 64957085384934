import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  description: css({
    display: "flex",
    flexDirection: "row",
    gap: "16px"
  }),

  icon: (t: Theme) =>
    css({
      color: t.colors.error
    }),

  actions: css({
    "&.MuiDialogActions-root": {
      justifyContent: "space-around"
    }
  }),

  actionsContainer: css({
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    padding: "16px !important"
  })
}
