import { css } from "@emotion/css"

export default {
  reservationBreakdownContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "16px",
    "@media(min-width:1200px)": {
      width: "400px",
      marginTop: 0
    }
  }),

  datesRowContainer: css({
    display: "flex",
    flexDirection: "row",
    padding: "8px 0",
    justifyContent: "space-between",
    " >p:first-child": {
      fontSize: "14px",
      textTransform: "uppercase",
      lineHeight: "1.14",
      color: "#9b9b9b",
      fontWeight: 400
    },
    " >p:last-child": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#4a4a4a",
      minWidth: "240px",
      textAlign: "right"
    }
  }),

  guestsContainer: css({
    marginTop: "16px"
  }),

  detailTitleStyle: css({
    fontSize: "14px",
    textTransform: "uppercase",
    lineHeight: "1.14",
    color: "#9b9b9b",
    fontWeight: 400
  }),

  guestRow: css({
    display: "flex",
    flexDirection: "row",
    padding: "8px 0",
    borderBottom: "1px solid #ccc",
    "&:last-child": {
      borderBottom: 0
    },
    justifyContent: "space-between",
    " >p:first-child": {
      fontSize: "14px",
      fontWeight: 300,
      color: "#4a4a4a",
      lineHeight: "1.14"
    },
    " >p:last-child": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#4a4a4a",
      minWidth: "240px",
      textAlign: "right"
    }
  })
}
