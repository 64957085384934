// @flow

import React from "react"
import { css } from "glamor"
import InputLabel from "./InputLabel"
import { TextInput } from "@basset-la/commons-frontend"
import ErrorLabel from "./ErrorLabel"

interface Props {
  title: string
  placeholder: string
  name: string
  onChange: (e: any) => void
  value: string
  errorText?: string
  number?: boolean
}

const TextField = ({ placeholder, title, name, onChange, value, number, errorText }: Props) => {
  let err = !!(errorText && errorText.length)
  return (
    <div className={`${containerStyle}`}>
      <InputLabel title={title} />
      <div className={`${textInputContainer}`}>
        <TextInput
          error={err}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          number={number}
        />
      </div>
      {errorText && <ErrorLabel errors={[errorText]} />}
    </div>
  )
}

const textInputContainer = css({
  display: "flex",
  height: "32px"
})

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  flex: "3 1 0%"
})

export default TextField
