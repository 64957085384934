import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: css({
    margin: "0 auto",
    padding: "16px 8px",
    flex: "1 1 0%",
    "@media(min-width:1200px)": {
      maxWidth: "1320px"
    },
    fontFamily: "Roboto"
  }),

  header: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: "16px",
    gap: "16px"
  }),

  title: (theme: Theme) =>
    css({
      color: `${theme.colors.brand_primary} !important`
    }),

  container: css({
    display: "flex",
    flexDirection: "column",
    "@media(min-width:1200px)": {
      flexDirection: "row-reverse"
    },
    fontFamily: "Roboto",
    gap: "24px"
  }),

  itemsContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "@media(min-width:1200px)": {
      width: "950px"
    },
    flex: 2
  }),

  rightContainer: css({
    display: "flex",
    flex: 1,
    minWidth: "345px",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "16px"
  }),

  background: (theme: Theme) =>
    css({
      backgroundColor: theme.colors.background_primary,
      display: "flex"
    }),

  checkout: css({
    margin: "0 auto",
    padding: "16px 8px",
    flex: "1 1 0%",
    "@media(min-width:1200px)": {
      maxWidth: "1320px"
    },
    /* IE Fix */
    "@media (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      margin: "50px"
    }
  }),

  notFoundContainer: css({
    minHeight: "608px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }),

  termAndConditions: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      " > p": {
        fontSize: "14px",
        fontWeight: "300",
        lineHeight: "1.14",
        margin: 0,
        marginLeft: "8px",
        color: "#4a4a4a",
        " > button": {
          color: t.colors.brand_primary,
          cursor: "pointer",
          fontSize: "inherit",
          background: "none !important",
          border: "none",
          "&:focus": {
            outline: 0
          }
        }
      }
    }),

  checkbox: css({
    width: "18px !important",
    height: "18px !important",
    color: "#4a4a4a"
  }),

  checkboxChecked: (t: Theme) =>
    css({
      color: `${t.colors.brand_primary} !important`
    }),

  button: (t: Theme) =>
    css({
      backgroundColor: `${t.colors.brand_primary} !important`,
      color: "#fff !important",
      width: "100%",
      marginTop: "16px",
      ":disabled,[disabled]": {
        backgroundColor: "#eaeaea !important",
        color: "#bbb !important"
      },
      " span": {
        fontFamily: "Roboto",
        textTransform: "none",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.1680002063512802px"
      },
      "@media(min-width:1200px)": {
        maxWidth: "320px"
      }
    }),

  contactInfoContainer: css({
    gap: "16px",
    flexDirection: "column",
    display: "flex"
  }),

  voucherBeneficiary: (theme: Theme) =>
    css({
      color: `${theme.colors.brand_primary} !important`,
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "32px"
    }),

  goBack: (theme: Theme) =>
    css({
      color: `${theme.colors.brand_primary} !important`,

      "& .MuiButton-root": {
        color: `${theme.colors.brand_primary} !important`
      },

      "& .MuiButton-startIcon": {
        color: `${theme.colors.brand_primary} !important`,
        margin: 0
      },

      "& .MuiButton-label": {
        color: theme.colors.brand_primary,
        fontSize: "14px",
        textTransform: "initial",
        marginLeft: "-8px"
      }
    })
}

export default styles
