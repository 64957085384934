// @flow

import React from "react"
import TextField from "../common/TextField"
import SelectField from "../common/SelectField"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
interface Props extends WithTranslation {
  onChange: (n: string, v: string) => void
  contact: any
}

const ContactContainer = ({ t, onChange, contact }: Props) => {
  const telephoneStyle = css({
    " > div:last-child": {
      marginBottom: 0
    },
    "@media(min-width:1024px)": {
      display: "flex",
      flexDirection: "row",
      " > div": {
        flex: "1 1 0%",
        marginBottom: "0px"
      }
    }
  })
  const telephoneContainerStyle = css({
    flexDirection: "row",
    display: "flex",
    " > div": {
      flex: "1 1 0%",
      marginRight: "8px",
      marginBottom: "16px"
    },
    " > div:first-child": {
      flex: "2 1 0%"
    },
    " > div:last-child": {
      marginRight: 0
    },
    "@media(min-width:1024px)": {
      marginRight: "16px",
      " > div": {
        marginRight: "16px",
        marginBottom: "0px"
      }
    }
  })
  const emailContainerStyle = css({
    " > div": {
      marginBottom: "16px"
    },
    "@media(min-width:1024px)": {
      display: "flex",
      marginBottom: "16px",
      flexDirection: "row",
      " > div": {
        flex: "1 1 0%",
        marginRight: "16px",
        marginBottom: 0
      },
      " > div:last-child": {
        marginRight: "0px"
      }
    }
  })

  const onChangeWithField = e => {
    onChange(e.target.name, e.target.value)
  }

  const phoneTypes = [
    { value: "M", label: t<string>("ContactContainer.telephoneTypes.M") }, // Mobile
    { value: "F", label: t<string>("ContactContainer.telephoneTypes.F") } // House
  ]

  return (
    <div>
      <div className={`${emailContainerStyle}`}>
        <TextField
          title={t<string>("ContactContainer.emailLabel")}
          placeholder={t<string>("ContactContainer.emailPlaceholder")}
          name={"email"}
          value={contact.email}
          errorText={contact.email_error}
          onChange={onChangeWithField}
        />
        <TextField
          title={t<string>("ContactContainer.emailConfirmationLabel")}
          placeholder={t<string>("ContactContainer.emailConfirmationPlaceholder")}
          name={"email_confirmation"}
          value={contact.email_confirmation}
          errorText={contact.email_confirmation_error}
          onChange={onChangeWithField}
        />
      </div>
      <div className={`${telephoneStyle}`}>
        <div className={`${telephoneContainerStyle}`}>
          <SelectField
            options={phoneTypes}
            title={t<string>("ContactContainer.telephoneLabel")}
            placeholder={t<string>("ContactContainer.telephonePlaceholder")}
            name={"telephone_type"}
            value={contact.telephone_type}
            errorText={contact.telephone_type_error}
            onChange={onChangeWithField}
          />
          <TextField
            title={t<string>("ContactContainer.telephoneCountryCode")}
            placeholder={""}
            name={"telephone_country_code"}
            value={contact.telephone_country_code}
            errorText={contact.telephone_country_code_error}
            onChange={onChangeWithField}
          />
          <TextField
            title={t<string>("ContactContainer.telephoneAreaCode")}
            placeholder={""}
            name={"telephone_area_code"}
            value={contact.telephone_area_code}
            errorText={contact.telephone_area_code_error}
            onChange={onChangeWithField}
          />
        </div>
        <TextField
          title={t<string>("ContactContainer.telephoneNumber")}
          placeholder={""}
          name={"telephone_number"}
          value={contact.telephone_number}
          errorText={contact.telephone_number_error}
          onChange={onChangeWithField}
        />
      </div>
    </div>
  )
}

export default withTranslation(I18N_NS)(ContactContainer)
