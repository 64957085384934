import React from "react"
import { AccommodationCluster } from "@basset-la/commons-frontend"
import { ThemeProps, theme } from "@basset-la/themed-components"
import { withTranslation, WithTranslation } from "react-i18next"
import Icon from "@material-ui/core/Icon"
import AnimateHeight from "react-animate-height"
import CancelPenalties from "./CancelPenalties"
import CancelPenaltiesAlert from "@basset-la/components-accommodations/dist/components/CancelPenalties"
import AccommodationDescription from "./AccommodationDescription"
import _ from "underscore"
import { I18N_NS } from "../../utils/constants"
import { ChangedFareDialog } from "@basset-la/components-accommodations"
import styles from "./AccommodationDetail.styles"

interface Props extends ThemeProps, WithTranslation {
  adultsQty: number
  childAges: number[]
  accommodation: any
  checkin: string
  checkout: string
  nights: number
  rooms: any[]
  mealPlan: string
  refundable: boolean
  cancelPenalties: any[]
  expirationDate: string
  customExpirationDate: string
  comments: string
  backgroundColor?: string
  site: string
  openChangedFareDialog: boolean
  handleOpenChangedFareDialog: () => void
  rateCluster: any
}

class AccommodationDetail extends React.PureComponent<Props, any> {
  state = { hidden: window.innerWidth < 1200 }

  changeHidden = () => {
    this.setState({ hidden: !this.state.hidden })
  }

  render() {
    const {
      t,
      accommodation,
      mealPlan,
      theme,
      cancelPenalties,
      refundable,
      backgroundColor,
      adultsQty,
      childAges,
      checkin,
      checkout,
      nights,
      rooms,
      site,
      comments,
      expirationDate,
      customExpirationDate,
      openChangedFareDialog,
      rateCluster,
      handleOpenChangedFareDialog
    } = this.props
    const { hidden } = this.state
    const arrow = hidden ? "arrow_drop_down" : "arrow_drop_up"

    const oldFare = rateCluster?.old_fare

    let childrenText = ""
    const childrenMap = {}

    childAges.forEach(a => {
      if (!childrenMap[a]) {
        childrenMap[a] = 1
      } else {
        childrenMap[a] += 1
      }
    })

    for (let key in childrenMap) {
      childrenText += t("AccommodationDetail.child", { count: childrenMap[key] })
      childrenText += ` (${t<string>("AccommodationDetail.year", { count: parseInt(key, 10) })})`
      childrenText += " / "
    }

    let checkinInstructions = ""
    if (accommodation.checkin.instructions) {
      checkinInstructions += accommodation.checkin.instructions
    }

    if (accommodation.checkin.special_instructions) {
      checkinInstructions += accommodation.checkin.special_instructions
    }

    checkinInstructions += t("AccommodationDetail.checkin_instructions_disclamer")

    const roomGroup = Object.values(_.groupBy(rooms, e => e.id))

    return (
      <div className={styles.containerStyle} style={{ backgroundColor: backgroundColor ? backgroundColor : "#ebebeb" }}>
        <div className={styles.titleContainerStyle} onClick={this.changeHidden}>
          <p className={styles.titleStyle(theme)}>{t<string>("AccommodationDetail.title")}</p>
          <Icon style={{ color: theme.colors.brand_primary }}>{arrow}</Icon>
        </div>
        <AnimateHeight height={hidden ? 0 : "auto"} duration={200}>
          <div className={styles.accommodationContainerStyle}>
            <AccommodationCluster
              name={accommodation.name}
              address={accommodation.address}
              stars={accommodation.stars}
              rating={accommodation.rating}
              mealPlan={mealPlan}
              photos={accommodation.images}
              mini
              borderless
              refundable={refundable}
            />
            <div className={`${styles.reservationDetailStyle}`}>
              <div>
                <p>{t<string>("AccommodationDetail.guests")}</p>
                <p>
                  <span>
                    {t<string>("AccommodationDetail.adult", { count: adultsQty })}
                  </span>
                  <br />
                  <span>{childrenText.length > 0 && childrenText}</span>
                </p>
              </div>
              <div>
                <p>{t<string>("AccommodationDetail.your_reservation")}</p>
                <p>
                  <span>{`${t<string>("AccommodationDetail.night", { count: nights })}, ${t<string>(
                    "AccommodationDetail.room",
                    {
                      count: rooms.length
                    }
                  )}`}</span>
                </p>
              </div>
              <div>
                <p>{t<string>("AccommodationDetail.rooms")}</p>
                <p>
                  <span>{roomGroup.map((e: any) => `${e.length} x ${e[0].name}`).join(", ")}</span>
                </p>
              </div>
              <div>
                <p>{t<string>("AccommodationDetail.arrival")}</p>
                <p>
                  <span>
                    {checkin} {`(${t<string>("AccommodationDetail.since")} ${accommodation.checkin.time})`}
                  </span>
                </p>
              </div>
              <div>
                <p>{t<string>("AccommodationDetail.departure")}</p>
                <p>
                  <span>
                    {checkout} {`(${t<string>("AccommodationDetail.until")} ${accommodation.checkout.time})`}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className={styles.alertStyle}>
            <CancelPenaltiesAlert
              variant="ALERT"
              cancelPenalties={cancelPenalties}
              refundable={refundable}
              expirationDate={expirationDate}
              customExpirationDate={customExpirationDate}
            />
          </div>
          {checkinInstructions.length > 0 && (
            <div>
              <AccommodationDescription
                title={t<string>("AccommodationDetail.checkin_instructions")}
                description={checkinInstructions}
              />
            </div>
          )}
          <div>
            <CancelPenalties
              cancelPenalties={cancelPenalties}
              refundable={refundable}
              site={site}
              expirationDate={expirationDate}
              customExpirationDate={customExpirationDate}
            />
          </div>
          {comments.length > 0 && (
            <div>
              <AccommodationDescription title={t<string>("AccommodationDetail.rate_comments")} description={comments} />
            </div>
          )}
          {openChangedFareDialog && rateCluster?.rates?.length && oldFare && (
            <ChangedFareDialog
              open={openChangedFareDialog}
              currency={rateCluster.rates[0]!.fare!.currency}
              newPrice={rateCluster.rates[0]!.fare!.total}
              oldPrice={oldFare.total}
              closeOnlyUserAction
              handleAcceptNewFare={handleOpenChangedFareDialog}
              handleRejectNewFare={handleOpenChangedFareDialog}
            />
          )}
        </AnimateHeight>
      </div>
    )
  }
}

export default withTranslation(I18N_NS)(theme(AccommodationDetail))
