// @flow

import React from "react"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Cluster from "@basset-la/components-flights/dist/components/Cluster"

interface Props extends WithTranslation {
  cluster: any
}

const ThanksFlightDetail = ({ t, cluster }: Props) => {
  return (
    <div className={`${containerStyle}`}>
      <p className={`${titleStyle}`}>{t<string>("ThanksFlightDetail.title")}</p>
      <Cluster cluster={cluster} variant="B2C" />
    </div>
  )
}

const containerStyle = css({
  backgroundColor: "#fff",
  marginTop: "4px",
  padding: "16px 8px",
  "@media(min-width:1200px)": {
    padding: "16px"
  }
})

const titleStyle = css({
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "1.14",
  color: "#0d207b",
  margin: "8px 0"
})

export default withTranslation(I18N_NS)(ThanksFlightDetail)
