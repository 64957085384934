// @flow
import React from "react"
import { css } from "glamor"
import Button from "@material-ui/core/Button"
import { theme, ThemeProps } from "@basset-la/themed-components"
import { withTranslation, WithTranslation } from "react-i18next"
import { I18N_NS } from "../utils/constants"
import Ticket from "../icons/Ticket"

interface Props extends WithTranslation, ThemeProps {
  description: string
  onBackToSearch: () => void
}

const NotFound = ({ t, description, theme, onBackToSearch }: Props) => {
  const titleStyle = css({
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "1.33",
    letterSpacing: "0.9px",
    color: theme.colors.brand_primary
  })

  const buttonStyle = css({
    fontSize: "14px !important",
    lineHeight: "1.14 !important",
    letterSpacing: "1.3px !important",
    color: "#fff !important",
    backgroundColor: `${theme.colors.brand_primary} !important`
  })

  return (
    <div className={`${containerStyle}`}>
      <Ticket />
      <div>
        <p className={`${titleStyle}`}>{t<string>("NotFound.title")}</p>
        <p className={`${subtitleStyle}`}>{description}</p>
        <Button onClick={onBackToSearch} className={`${buttonStyle}`}>
          {t<string>("NotFound.button")}
        </Button>
      </div>
    </div>
  )
}

const subtitleStyle = css({
  fontFamily: "Roboto",
  fontSize: "18px",
  lineHeight: "1.33",
  letterSpacing: "0.6px",
  color: "#4a4a4a",
  fontWeight: "300"
})

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "@media(min-width:1024px)": {
    flexDirection: "row"
  },
  " > img": {
    "@media(min-width:1024px)": {
      marginRight: "76px"
    },
    width: "192px",
    height: "128px"
  }
})

export default theme(withTranslation(I18N_NS)(NotFound))
