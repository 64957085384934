import React from "react"
import { css } from "glamor"
import FormSection from "./common/FormSection"
import { withTranslation, WithTranslation } from "react-i18next"
import { validations } from "@basset-la/commons-frontend"
import { ThemeProps, theme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import ContactContainer from "./contact/ContactContainer"
import BillingContainer from "@basset-la/commons-frontend/dist/components/checkout/billing/BillingContainer"
import { getCountries, getStatesByCountryCode } from "../api/common"
import { getRate, getAccommodation } from "../api/accommodations"
import { formatPrice } from "../utils/flightsUtils"
import AccommodationDetail from "./accommodations/AccommodationDetail"
import clone from "lodash/clone"
import moment from "moment"
import {
  createAccommodationPurchaseModel,
  validateNameLength,
  validateRoomCompanionsSection
} from "../utils/accommodationsUtils"
import { createReservationAsync, getReservationProcess } from "../api/reservations"
import Fees from "./accommodations/Fees"
import Checkbox from "@material-ui/core/Checkbox"
import NotFound from "./NotFound"
import TermsAndConditions from "./TermsAndConditions"
import { getFormConfig } from "../config/formConfig"
import { RouteComponentProps } from "react-router"
import * as formConfiguration from "./config/form-configuration.json"
import { I18N_NS } from "../utils/constants"
import PaymentSection from "./payment/PaymentSection"
import { getEPSToken } from "../api/payment"
import { getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { PriceboxBreakdownCheckoutWeb } from "@basset-la/components-accommodations"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import { AuthUserType, withAuthUser, withConfig } from "@basset-la/components-commons"
import { ConfigType } from "@basset-la/components-commons/dist/components/ConfigProvider/ConfigProvider"
import Message from "@basset-la/components-commons/dist/components/Message"
import CommonCheckoutRoomSection from "./common/CommonCheckoutRoomSection"
import WarningsDialog from "./WarningsDialog/WarningsDialog"

interface MatchProps {
  id: string
}

interface Props extends WithTranslation, ThemeProps, RouteComponentProps<MatchProps>, AuthUserType, ConfigType {}

class AccommodationsCheckout extends React.PureComponent<Props, any> {
  isMobile = window.innerWidth < 768

  constructor(props) {
    super(props)
    this.state = {
      site: this.props.config.country,
      rateCluster: null,
      accommodation: null,
      geo: null,
      notFound: "",
      isFetching: true,
      isBooking: false,
      roomGuests: [],
      paymentInfo: null,
      paymentToken: null,
      paymentsURL: null,
      billingInfo: null,
      contactInfo: null,
      formConfig: getFormConfig(this.props.config.checkout_form_config),
      termAndConditions: this.props.config.brand.terms_and_conditions,
      showTermAndConditions: false,
      termsAndConditionAccepted: false,
      agencyId: this.props.config.agency_id,
      roomCompanionsMap: {},
      roomCompanionErrors: []
    }
  }

  containerStyle = css({
    padding: "10px",
    //backgroundColor: this.props.theme.brand_colors.background_color,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  })

  buttonStyle = css({
    backgroundColor: this.props.theme.brand_colors.click_color + " !important",
    color: "#fff !important",
    width: "100%",
    marginTop: "16px",
    ":disabled,[disabled]": {
      backgroundColor: "#eaeaea !important",
      color: "#bbb !important"
    },
    " span": {
      textTransform: "none !important",
      fontWeight: "300 !important"
    },
    "@media(min-width:1200px)": {
      maxWidth: "320px"
    }
  })

  titleStyle = css({
    fontSize: "24px",
    lineHeight: "1.33",
    color: this.props.theme.colors.brand_primary,
    marginBottom: "16px"
  })

  checkboxCheckedStyle = css({
    color: `${this.props.theme.colors.brand_primary} !important`
  })

  UNSAFE_componentWillMount() {
    this.retrieveCheckoutData()
  }

  retrieveCheckoutData = async () => {
    const lang = getLocale(this.props.i18n).substring(0, 2)
    const { site, agencyId } = this.state

    let rateCluster

    try {
      rateCluster = await getRate(this.props.match.params.id, agencyId, site)
    } catch (error) {
      this.setState({ notFound: "notFound", isFetching: false })
    }

    if (rateCluster) {
      let [countries, states] = await Promise.all([
        getCountries(agencyId, lang),
        getStatesByCountryCode(site, agencyId, lang)
      ])

      const accommodation = await getAccommodation(rateCluster.rates[0].accommodation_id, lang, agencyId)
      const paymentToken = await getEPSToken(this.props.match.params.id, lang)

      const roomCompanionsMap: Record<number, any[]> = {}
      rateCluster.rooms.forEach((_, index) => (roomCompanionsMap[index] = []))

      this.setState({
        paymentsURL: rateCluster ? rateCluster.payments_url : undefined,
        isFetching: false,
        roomGuests: this.createRoomGuests(rateCluster.rooms),
        paymentInfo: this.createPaymentInformation(paymentToken),
        paymentToken: paymentToken,
        billingInfo: this.createBillingInformation(),
        contactInfo: this.createContactInformation(),
        geo: {
          countries: countries,
          states: states
        },
        rateCluster: rateCluster,
        accommodation: accommodation,
        roomCompanionsMap: roomCompanionsMap,
        roomCompanionErrors: []
      })

      if (rateCluster!.rates[0].status === "PRICE_CHANGE") {
        this.setState({ openChangeFareDialog: true })
      } else if (
        rateCluster!.rates[0].status === "EXPIRATION_DATE_CHANGE" ||
        rateCluster!.rates[0].status === "REFUNDABLE_CHANGE"
      ) {
        const status =
          rateCluster!.rates[0].status === "EXPIRATION_DATE_CHANGE" ? "expiration_date_changed" : "refundable_changed"
        this.setState({ openWarningsDialog: true, warningsDialogStatus: status })
      } else if (rateCluster!.rates[0].status === "SOLD_OUT") {
        this.setState({ notFound: "notFound" })
      }
    }
  }

  createRoomGuests = (rooms: Object[]) => {
    let roomGuests: any[] = []
    for (let index = 0; index < rooms.length; index++) {
      roomGuests.push({ firstName: "", lastName: "", documentType: "", documentNumber: "" })
    }

    return roomGuests
  }

  createBillingInformation = () => {
    const { site, agencyId } = this.state

    const defaultsConfig = this.getCheckoutConfiguration(site, agencyId, "defaults")

    return {
      fiscal_name: "",
      fiscal_id: "",
      fiscal_situation: defaultsConfig.fiscalSituation,
      country: "",
      province: "",
      city: "",
      street: "",
      street_number: "",
      floor_number: "",
      building_number: "",
      postal_code: ""
    }
  }

  createPaymentInformation = (token: string) => {
    return {
      token: token,
      card_holder: "",
      isCreditCardValid: true, //Avoid validation until cartch events
      isCreditCardCVVValid: true,
      document_type: "",
      document_number: "",
      expiration_month: "",
      expiration_year: ""
    }
  }

  createContactInformation = () => {
    const { site, agencyId } = this.state

    const defaultsConfig = this.getCheckoutConfiguration(site, agencyId, "defaults")

    return {
      email: "",
      email_confirmation: "",
      telephone_type: defaultsConfig.telephones.type,
      telephone_country_code: defaultsConfig.telephones.country,
      telephone_area_code: defaultsConfig.telephones.area,
      telephone_number: ""
    }
  }

  onChange = target => (field, value) => {
    const data = { ...this.state[target], [field]: value }
    if (data[field + "_error"]) delete data[field + "_error"]
    this.setState({ [target]: data })
  }

  onRoomGuestChange = (i: number, field: string, value: string) => {
    const { roomGuests } = this.state
    const roomGuest = { ...roomGuests[i], [field]: value }
    if (roomGuest[field + "_error"]) delete roomGuest[field + "_error"]
    const state = { roomGuests: [...roomGuests.slice(0, i), roomGuest, ...roomGuests.slice(i + 1, roomGuests.length)] }
    this.setState(state)
  }

  validatePaymentInfo = (): boolean => {
    const paymentInfo = clone(this.state.paymentInfo)
    if (!paymentInfo) return false
    const { t } = this.props

    let isValid = true
    if (!paymentInfo.card_holder) {
      isValid = false
      paymentInfo.card_holder_error = t("AccommodationsCheckout.paymentError.cardHolderError")
    }

    if (!paymentInfo.isCreditCardValid) {
      isValid = false
      paymentInfo.card_holder_error = t("AccommodationsCheckout.paymentError.cardHolderError")
      //Validate number format CCSaved
      //paymentInfo.card_holder_error = t("AccommodationsCheckout.paymentError.creditCardNumberError")
      paymentInfo.security_code_error = t("AccommodationsCheckout.paymentError.creditCardSecurityCodeError")
    }

    if (!paymentInfo.isCreditCardCVVValid) {
      isValid = false
      paymentInfo.security_code_error = t("AccommodationsCheckout.paymentError.creditCardSecurityCodeError")
    }

    if (!paymentInfo.document_type) {
      isValid = false
      paymentInfo.document_type_error = t("AccommodationsCheckout.paymentError.documentTypeError")
    }
    if (!paymentInfo.document_number) {
      isValid = false
      paymentInfo.document_number_error = t("AccommodationsCheckout.paymentError.documentNumberError")
    }
    if (!paymentInfo.expiration_month) {
      isValid = false
      paymentInfo.expiration_month_error = t("AccommodationsCheckout.paymentError.expirationMonthError")
    }
    if (!paymentInfo.expiration_year) {
      isValid = false
      paymentInfo.expiration_year_error = t("AccommodationsCheckout.paymentError.expirationYearError")
    }

    this.setState({ paymentInfo })
    return isValid
  }

  validateContactInfo = (): boolean => {
    const contactInfo = clone(this.state.contactInfo)

    if (!contactInfo) return false

    const { t } = this.props

    let isValid = true
    if (!contactInfo.email) {
      isValid = false
      contactInfo.email_error = t("AccommodationsCheckout.emailError")
    } else {
      if (!validations.validateEmail(contactInfo.email)) {
        isValid = false
        contactInfo.email_error = t("AccommodationsCheckout.emailInvalid")
      } else {
        if (contactInfo.email !== contactInfo.email_confirmation) {
          isValid = false
          contactInfo.email_confirmation_error = t("AccommodationsCheckout.emailConfirmation")
        }
      }
    }

    if (!contactInfo.telephone_country_code) {
      isValid = false
      contactInfo.telephone_country_code_error = t("AccommodationsCheckout.telephoneCountryCode")
    }
    if (!validations.validateNumeric(contactInfo.telephone_country_code)) {
      isValid = false
      contactInfo.telephone_country_code_error = t("AccommodationsCheckout.telephoneCountryCode")
    }

    contactInfo.telephone_area_code_error = ""

    if (contactInfo.telephone_type !== "M") {
      if (!contactInfo.telephone_area_code) {
        isValid = false
        contactInfo.telephone_area_code_error = t("AccommodationsCheckout.telephoneAreaCode")
      }
      if (!validations.validateAreaCode(contactInfo.telephone_area_code)) {
        isValid = false
        contactInfo.telephone_area_code_error = t("AccommodationsCheckout.telephoneInvalid")
      }
    }
    if (!contactInfo.telephone_number) {
      isValid = false
      contactInfo.telephone_number_error = t("AccommodationsCheckout.telephoneNumber")
    }
    if (!validations.validateNumeric(contactInfo.telephone_number)) {
      isValid = false
      contactInfo.telephone_number_error = t("AccommodationsCheckout.telephoneInvalid")
    }

    this.setState({ contactInfo })
    return isValid
  }

  getCheckoutConfiguration(site, agencyId, config) {
    let chkConfig = formConfiguration.config[agencyId]
    if (chkConfig == null || chkConfig[config] == null) {
      chkConfig = formConfiguration.config["DEFAULT"]
    }

    if (config) {
      chkConfig = chkConfig[config]
    }

    return chkConfig
  }

  validateBillingInfo = (): boolean => {
    const billingInfo = clone(this.state.billingInfo)
    const { t } = this.props
    const { site, agencyId } = this.state

    const billingConfig = this.getCheckoutConfiguration(site, agencyId, "billing")

    let identification = billingConfig.identification ? billingConfig.identification : []
    let addressStreet = billingConfig.address_street ? billingConfig.address_street : []
    let addressExtra = billingConfig.address_extra ? billingConfig.address_extra : []
    let fiscalId = billingConfig.fiscal_id ? billingConfig.fiscal_id : []
    let fiscalSituation = billingConfig.fiscal_situation ? billingConfig.fiscal_situation : []

    let config = identification
      .concat(addressStreet)
      .concat(addressExtra)
      .concat(fiscalId)
      .concat(fiscalSituation)

    let isValid = this.validateFields(config, billingInfo, "AccommodationsCheckout.billingError.")
    if (!validations.validateFiscalID(billingInfo.fiscal_id, billingInfo.fiscal_situation, site)) {
      billingInfo.fiscal_id_error = t("AccommodationsCheckout.billingError.fiscal_id")
      isValid = false
    }

    return isValid
  }

  validateRoomGuests = (): boolean => {
    const roomGuests = clone(this.state.roomGuests)
    const { t } = this.props
    const { site } = this.state
    let valid = true

    for (let roomGuest of roomGuests) {
      if (!roomGuest.firstName) {
        roomGuest.firstName_error = t("AccommodationsCheckout.firstNameError")
        valid = false
      } else {
        if (!validations.validateAlphabetic(roomGuest.firstName)) {
          roomGuest.firstName_error = t("AccommodationsCheckout.alphabeticError")
          valid = false
        }
      }

      if (!roomGuest.lastName) {
        roomGuest.lastName_error = t("AccommodationsCheckout.lastNameError")
        valid = false
      } else {
        if (!validations.validateAlphabetic(roomGuest.lastName)) {
          roomGuest.lastName_error = t("AccommodationsCheckout.alphabeticError")
          valid = false
        }
      }

      if (!roomGuest.documentType) {
        roomGuest.documentType_error = t("AccommodationsCheckout.documentTypeError")
        valid = false
      }
      if (!roomGuest.documentNumber) {
        roomGuest.documentNumber_error = t("AccommodationsCheckout.documentNumberError")
        valid = false
      }
      if (roomGuest.documentType && roomGuest.documentNumber && roomGuest.birthYear) {
        if (
          !validations.validateDocumentNumber(
            site,
            roomGuest.documentNumber,
            roomGuest.documentType,
            roomGuest.birthYear
          )
        ) {
          roomGuest.documentNumber_error = t("AccommodationsCheckout.documentNumberInvalid")
          valid = false
        }
      }
    }
    this.setState({ roomGuests })
    return valid
  }

  validateFields = (cofig: any, billingInfo: any, prefix: string): boolean => {
    const { t } = this.props
    let isValid = true
    if (cofig.length > 0) {
      for (let i in cofig) {
        let name = cofig[i].name
        if (cofig[i].required && !billingInfo[name]) {
          isValid = false
          billingInfo[name + "_error"] = t(prefix + name)
        }
      }
    }
    this.setState({ billingInfo })
    return isValid
  }

  hideTermsAndConditions = () => {
    this.setState({ showTermAndConditions: false })
  }

  showTermAndConditions = () => {
    this.setState({ showTermAndConditions: true })
  }

  validateAndPurchase = async () => {
    const { userId } = this.props
    const lang = getLocale(this.props.i18n).substring(0, 2)
    const { site, agencyId, rateCluster, roomCompanionsMap } = this.state

    if (this.validate()) {
      const req = createAccommodationPurchaseModel(
        this.props.match.params.id,
        this.state.roomGuests,
        this.state.paymentInfo,
        this.state.billingInfo,
        this.state.contactInfo,
        this.state.site,
        this.state.formConfig,
        lang,
        rateCluster!.rooms.map(r => r.id),
        roomCompanionsMap
      )
      this.setState({ isBooking: true })
      try {
        const resProc = await createReservationAsync(req, userId, site, agencyId)
        let interval = setInterval(async () => {
          const reservationProcess = await getReservationProcess(resProc.id, userId, site, agencyId)
          switch (reservationProcess.status) {
            case "OK":
              const { paymentsURL } = this.state
              if (paymentsURL) {
                window.location.href = paymentsURL.replace("{id}", `${reservationProcess.reservation_id}`)
              } else {
                this.props.history.push(
                  `/checkout/accommodations/reservations/${reservationProcess.reservation_id}/thanks`
                )
              }
              clearInterval(interval)
              break
            case "ERROR":
              console.error(reservationProcess.error_message)
              this.setState({ notFound: "notFound" })
              clearInterval(interval)
              break
            default:
              console.log("Still booking...")
              break
          }
        }, 1000)
      } catch (err) {
        console.error(err)
        this.setState({ notFound: "notFound" })
      }
    }
  }

  handleValidateRoomCompanionsSection = (): boolean => {
    const { t } = this.props
    const { site } = this.state

    const roomCompanionsMapCopy = clone(this.state.roomCompanionsMap)
    const roomCompanionErrorsCopy = clone(this.state.roomCompanionErrors)

    const valid = validateRoomCompanionsSection(
      roomCompanionsMapCopy,
      roomCompanionErrorsCopy,
      this.state.rateCluster,
      site,
      t
    )
    this.setState({ roomCompanionsMap: roomCompanionsMapCopy, roomCompanionErrors: roomCompanionErrorsCopy })

    return valid
  }

  validate = (): boolean => {
    const { formConfig } = this.state
    const validRooms = !formConfig.passengers ? true : this.validateRoomGuests()
    const validShortNames = !formConfig.passengers
      ? true
      : validateNameLength(this.props.t, this.state.rateCluster, this.state.roomGuests)
    const validPayment = !formConfig.payment ? true : this.validatePaymentInfo()
    const validBillingInfo =
      !formConfig.billingInfo || formConfig.autocompleteBillingInfo ? true : this.validateBillingInfo()
    const validContactInfo = !formConfig.contactInfo ? true : this.validateContactInfo()
    const validRoomCompanionSection = this.handleValidateRoomCompanionsSection()
    return (
      validRooms && validShortNames && validPayment && validBillingInfo && validContactInfo && validRoomCompanionSection
    )
  }

  termAndConditions = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "16px",
    " > p": {
      fontSize: "14px",
      fontWeight: "300",
      lineHeight: "1.14",
      margin: 0,
      marginLeft: "8px",
      color: "#4a4a4a",
      " > button": {
        color: this.props.theme.colors.brand_primary,
        cursor: "pointer",
        fontSize: "inherit",
        background: "none !important",
        border: "none",
        "&:focus": {
          outline: 0
        }
      }
    }
  })

  onChangeTermAndConditions = (event: any) => {
    const { checked } = event.target
    this.setState({ termsAndConditionAccepted: checked })
  }

  goBackToSearch = () => {
    this.props.history.goBack()
  }

  handleOnChangeCompanionData = (roomNumber: number, index: number, field: string, value: string) => {
    this.setState(prevState => ({
      roomCompanionsMap: {
        ...prevState.roomCompanionsMap,
        [roomNumber]: [
          ...prevState.roomCompanionsMap[roomNumber].slice(0, index),
          {
            ...prevState.roomCompanionsMap[roomNumber][index],
            [field]: value,
            [`${field}_error`]: ""
          },
          ...prevState.roomCompanionsMap[roomNumber].slice(index + 1)
        ]
      }
    }))
  }

  handleOnAddCompanion = (roomNumber: number) => {
    this.setState(prevState => ({
      roomCompanionsMap: {
        ...prevState.roomCompanionsMap,
        [roomNumber]: [
          ...prevState.roomCompanionsMap[roomNumber],
          {
            firstName: "",
            lastName: "",
            documentNumber: "",
            documentType: "",
            firstName_error: "",
            lastName_error: "",
            documentNumber_error: "",
            documentType_error: "",
            birthday: "",
            birthday_error: "",
            general_error: ""
          }
        ]
      }
    }))
  }

  handleOnDeleteAllCompanions = (roomNumber: number) => {
    this.setState(prevState => ({
      roomCompanionsMap: {
        ...prevState.roomCompanionsMap,
        [roomNumber]: []
      }
    }))
  }

  handleOnDeleteCompanion = (roomNumber: number, index: number) => {
    this.setState(prevState => ({
      roomCompanionsMap: {
        ...prevState.roomCompanionsMap,
        [roomNumber]: prevState.roomCompanionsMap[roomNumber].filter((_, i) => i !== index)
      }
    }))
  }

  handleOnUpdateRoomGuests = (roomGuests: any): void => {
    this.setState({ roomGuests: roomGuests })
  }

  handleOnUpdateRoomCompanionsMap = (roomCompanionsMap: Record<number, any>): void => {
    this.setState({ roomCompanionsMap: roomCompanionsMap })
  }

  handleAcceptChangedDialog = () => {
    const { openChangeFareDialog } = this.state
    const state = openChangeFareDialog

    this.setState({ openChangeFareDialog: !state })

    if (state) {
      this.goBackToSearch()
    }
  }

  handleAcceptWarningDialog = () => {
    this.setState({ openWarningsDialog: false, warningsDialogStatus: "" })
  }

  handleCancelWarningDialog = () => {
    this.setState({ openWarningsDialog: false, warningsDialogStatus: "" })
    this.goBackToSearch()
  }

  render() {
    const { t } = this.props
    const {
      paymentInfo,
      paymentToken,
      billingInfo,
      site,
      agencyId,
      geo,
      isBooking,
      contactInfo,
      isFetching,
      rateCluster,
      accommodation,
      roomGuests,
      paymentsURL,
      termsAndConditionAccepted,
      showTermAndConditions,
      termAndConditions,
      notFound,
      roomCompanionsMap,
      roomCompanionErrors,
      openChangeFareDialog,
      openWarningsDialog,
      warningsDialogStatus
    } = this.state
    let chargeDisclamers: any[] = []

    if (isFetching) {
      return (
        <div className={`${this.containerStyle}`}>
          <ProductLoader is_mobile={this.isMobile} product_variant="ACCOMMODATIONS_CHECKOUT" variant="WEB" />
        </div>
      )
    }

    if (notFound !== "" || !rateCluster) {
      return (
        <div className={`${this.containerStyle}`}>
          <div className={`${mainContainerStyle}`}>
            <div className={`${notFoundContainerStyle}`}>
              <NotFound
                description={t<string>(`AccommodationsCheckout.${notFound}`)}
                onBackToSearch={this.goBackToSearch}
              />
            </div>
          </div>
        </div>
      )
    }

    const checkin = moment(rateCluster.rates[0].checkin)
    const checkout = moment(rateCluster.rates[0].checkout)
    const nights = checkout.diff(checkin, "days")
    const checkinDate = checkin.utc().format("ddd DD MMM YYYY")
    const checkoutDate = checkout.utc().format("ddd DD MMM YYYY")

    const billingConfig = this.getCheckoutConfiguration(site, agencyId, "billing")
    const documentOptions = this.getCheckoutConfiguration(site, agencyId, "defaults").documentOptions
    const checkoutConfig = this.getCheckoutConfiguration(site, agencyId, "")

    chargeDisclamers.push({
      label: t("AccommodationsCheckout.priceRoomLabel"),
      price: `${rateCluster.rates[0].fare.currency} ${formatPrice(rateCluster.rates[0].fare.nightly_basis, site)}`
    })

    let childAges: any[] = []
    let adultsQty = 0

    for (let room of rateCluster.rooms) {
      if (room.child_ages) {
        childAges.push(...room.child_ages)
        adultsQty += room.capacity - room.child_ages.length
      } else {
        adultsQty += room.capacity
      }
    }

    const fees =
      rateCluster.rates[0].fare.fees && rateCluster.rates[0].fare.fees.length > 0
        ? rateCluster.rates[0].fare.fees.filter((f: any) => f.type !== "PAIS" && (f.type as string).startsWith("RG"))
        : undefined

    return (
      <>
        <div className={`${this.containerStyle}`}>
          {isBooking && (
            <ProductLoader is_mobile={this.isMobile} product_variant="ACCOMMODATIONS_PAYMENT" variant="WEB" />
          )}

          {!isBooking && (
            <div>
              <p className={`${this.titleStyle}`}>{t<string>("AccommodationsCheckout.title")}</p>
              <div className={`${mainContainerStyle}`}>
                <div className={`${formContainerStyle}`}>
                  {this.state.formConfig.passengers && (
                    <FormSection title={"Datos de huesped"}>
                      <CommonCheckoutRoomSection
                        roomGuests={roomGuests}
                        roomCapacities={rateCluster.rooms.map(r => r.capacity - 1)}
                        roomChildrenCount={rateCluster.rooms.map(r => r.child_ages?.length || 0)}
                        roomCompanionsMap={roomCompanionsMap}
                        chkConfig={checkoutConfig}
                        roomCompanionErrors={roomCompanionErrors}
                        onUpdateRoomGuests={this.handleOnUpdateRoomGuests}
                        onUpdateRoomCompanionsMap={this.handleOnUpdateRoomCompanionsMap}
                      />
                    </FormSection>
                  )}
                  {this.state.formConfig.payment && (
                    <FormSection title={t<string>("AccommodationsCheckout.payment")}>
                      <PaymentSection
                        site={site}
                        payment={paymentInfo}
                        paymentToken={paymentToken}
                        onChange={this.onChange("paymentInfo")}
                        documentOptions={documentOptions}
                      />
                    </FormSection>
                  )}
                  {this.state.formConfig.billingInfo && (
                    <FormSection title={t<string>("AccommodationsCheckout.billing")}>
                      {this.state.formConfig.autocompleteBillingInfo && (
                        <Message open variant="fixed" action="info" message={t("FormConfig.autocompleteBillingInfo")} />
                      )}
                      <BillingContainer
                        onChange={this.onChange("billingInfo")}
                        billingInfo={billingInfo}
                        checkoutConfig={{ billing: billingConfig }}
                        geo={geo}
                      />
                    </FormSection>
                  )}
                  {this.state.formConfig.contactInfo && (
                    <FormSection title={t<string>("AccommodationsCheckout.contact")}>
                      <ContactContainer onChange={this.onChange("contactInfo")} contact={contactInfo} />
                    </FormSection>
                  )}
                  <div className={`${this.termAndConditions}`}>
                    <Checkbox
                      classes={{
                        root: `${checkboxStyle}`,
                        checked: `${this.checkboxCheckedStyle}`
                      }}
                      checked={termsAndConditionAccepted}
                      onClick={this.onChangeTermAndConditions}
                    />
                    <p>
                      {t<string>("AccommodationsCheckout.termAndConditions")}{" "}
                      <button onClick={this.showTermAndConditions}>
                        {t<string>("AccommodationsCheckout.termAndConditionsLink")}
                      </button>
                    </p>
                  </div>
                  <Button
                    variant={"contained"}
                    classes={{ root: `${this.buttonStyle}` }}
                    onClick={this.validateAndPurchase}
                    disabled={isBooking || !termsAndConditionAccepted}
                  >
                    {paymentsURL
                      ? t<string>("AccommodationsCheckout.buyButton")
                      : t<string>("AccommodationsCheckout.reserveButton")}
                  </Button>
                </div>
                <div className={`${detailContainerStyle}`}>
                  <div>
                    <PriceboxBreakdownCheckoutWeb
                      accommodationFare={rateCluster.rates[0].fare}
                      rooms={rateCluster.rooms.length}
                      guests={adultsQty}
                    />
                  </div>
                  {fees && fees.length > 0 && (
                    <div style={{ marginTop: "16px" }}>
                      <Fees fees={fees} site={site} />
                    </div>
                  )}
                  <div className={`${hotelDetailContainer}`}>
                    <AccommodationDetail
                      site={site}
                      childAges={childAges}
                      adultsQty={adultsQty}
                      refundable={rateCluster.rates[0].refundable}
                      checkin={checkinDate}
                      checkout={checkoutDate}
                      nights={nights}
                      rooms={rateCluster.rooms}
                      cancelPenalties={rateCluster.rates[0].cancel_penalties}
                      expirationDate={rateCluster.rates[0].expiration_date}
                      customExpirationDate={rateCluster.rates[0].custom_expiration_date}
                      accommodation={accommodation}
                      mealPlan={t(`Accommodation.mealPlan.${rateCluster.rates[0].meal_plan}`)}
                      comments={rateCluster.rates[0].comments}
                      openChangedFareDialog={openChangeFareDialog}
                      rateCluster={rateCluster}
                      handleOpenChangedFareDialog={this.handleAcceptChangedDialog}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!isBooking && (
          <TermsAndConditions
            showTermAndConditions={showTermAndConditions}
            hideTermsAndConditions={this.hideTermsAndConditions}
            termAndConditions={termAndConditions}
            showProviderTerms={true}
          />
        )}
        <WarningsDialog
          open={openWarningsDialog}
          hotelName={rateCluster.rooms[0].name}
          status={warningsDialogStatus}
          onCancel={this.handleCancelWarningDialog}
          onAccept={this.handleAcceptWarningDialog}
        />
      </>
    )
  }
}

const checkboxStyle = css({
  width: "18px !important",
  height: "18px !important",
  color: "#4a4a4a"
})

const hotelDetailContainer = css({
  marginTop: "8px",
  "@media(min-width:1200px)": {
    marginTop: "16px"
  },
  " p": {
    //fix
    margin: 0
  }
})

const detailContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  order: "1",
  padding: "8px",
  "@media(min-width: 1200px)": {
    width: "432px",
    order: "2",
    marginLeft: "16px",
    padding: 0
  }
})

const mainContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  "@media(min-width:1200px)": {
    flexDirection: "row",
    width: "1344px"
  }
})

const notFoundContainerStyle = css({
  minHeight: "608px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 auto"
})

const formContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  padding: "8px",
  order: 2,
  "@media(min-width: 1200px)": {
    width: "880px",
    padding: 0,
    order: 1
  }
})

export default withConfig(withAuthUser(withTranslation(I18N_NS)(theme(AccommodationsCheckout))))
