// @flow

import React from "react"
import { useTranslation } from "react-i18next"

import { useTheme } from "@basset-la/themed-components"

import styles from "./ReservationConfirmationText.styles"
import { I18N_NS } from "../../utils/constants"

interface Props {
  reservationID: string
  email: string
  reservationText?: {
    id: string
    voucher: string
  }
  classNames?: {
    container?: string
    reservationText?: string
    emailText?: string
  }
}

const ReservationConfirmationText: React.FC<Props> = ({ reservationID, email, reservationText, classNames }) => {
  const { t } = useTranslation(I18N_NS)

  const theme = useTheme()

  return (
    <div className={`${styles.containerStyle} ${classNames?.container}`}>
      <p className={`${styles.reservationTextStyle(theme)} ${classNames?.reservationText}`}>
        {reservationText?.id || t<string>("ReservationConfirmationText.title")}{" "}
        <span className={styles.boldStyle}>{reservationID}</span>
      </p>
      <p className={`${styles.emailTextStyle} ${classNames?.emailText}`}>
        {reservationText?.voucher || t<string>("ReservationConfirmationText.email")}{" "}
        <span className={styles.boldStyle}>{email}</span>
      </p>
    </div>
  )
}

export default ReservationConfirmationText
