import { fetchAsync } from "./common"

const reservation = require("./mocks/reservation.json")

const createReservation = async (req: any, agentId: string | null, country: string, agency_id: string) => {
  if (process.env.REACT_APP_SHOULD_USE_MOCKS === "true") {
    return getReservationMock()
  }
  const opts = {
    body: JSON.stringify(req),
    method: "POST"
  }
  if (!process.env.REACT_APP_API_URI) throw new Error("Missing key REACT_APP_API_URI")
  const uri = `${process.env.REACT_APP_API_URI}/reservations/bookings`
  return fetchAPI(uri, agentId, country, agency_id, opts)
}

const createReservationAsync = async (req: any, agentId: string | null, country: string, agency_id: string) => {
  if (process.env.REACT_APP_SHOULD_USE_MOCKS === "true") {
    return getReservationMock()
  }
  const opts = {
    body: JSON.stringify(req),
    method: "POST"
  }
  if (!process.env.REACT_APP_API_URI) throw new Error("Missing key REACT_APP_API_URI")
  const uri = `${process.env.REACT_APP_API_URI}/reservations/async`
  return fetchAPI(uri, agentId, country, agency_id, opts)
}

const getReservationProcess = async (id: string, agentId: string | null, country: string, agency_id: string) => {
  if (process.env.REACT_APP_SHOULD_USE_MOCKS === "true") {
    return getReservationMock()
  }
  if (!process.env.REACT_APP_API_URI) throw new Error("Missing key REACT_APP_API_URI")
  const uri = `${process.env.REACT_APP_API_URI}/reservations/process/${id}`
  return fetchAPI(uri, agentId, country, agency_id)
}

const getReservation = async (id: string, agentId: string | null, country: string, agency_id: string) => {
  if (process.env.REACT_APP_SHOULD_USE_MOCKS === "true") {
    return getReservationMock()
  }
  if (!process.env.REACT_APP_API_URI) throw new Error("Missing key REACT_APP_API_URI")
  const uri = `${process.env.REACT_APP_API_URI}/reservations/${id}`
  return fetchAPI(uri, agentId, country, agency_id)
}

const getReservationMock = () => {
  if (!process.env.REACT_APP_MOCK_RESPONSE_TIME) throw new Error("Missing key REACT_APP_MOCK_RESPONSE_TIME")
  const responseTime = parseInt(process.env.REACT_APP_MOCK_RESPONSE_TIME, 10)
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(reservation)
    }, responseTime)
  })
}

const fetchAPI = async (url: string, agentId: string | null, site: string, agency_id: string, opts?: any) => {
  const channel = "WEB"

  const uri = `${url}?site=${site}&channel=${channel}`
  const headers = {
    "x-client-id": agency_id,
    "x-api-key": process.env.REACT_APP_API_KEY,
    "Content-Type": "application/json"
  }
  if (agentId) {
    headers["x-agent-id"] = agentId
  }
  const options = opts === undefined ? { headers: headers } : { ...opts, headers: headers }

  return fetchAsync(uri, options)
}

export { createReservation, getReservation, createReservationAsync, getReservationProcess }
