import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  card: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      backgroundColor: t.colors.background_primary,
      padding: "16px",
      justifyContent: "space-between",
      marginTop: "4px"
    }),

  planCoveragesHeader: (t: Theme) =>
    css({
      fontSize: "18px !important",
      fontWeight: "300 !important",
      color: t.colors.brand_primary
    }),

  planCoveragesTitles: css({
    fontSize: "18px !important"
  })
}
