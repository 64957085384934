import React from "react"
import styles from "./AppLoaderWrapper.styles"
import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"

export interface Props {
  variant: "FLIGHTS" | "ACCOMMODATIONS" | "CARS" | "INSURANCES"
}

const AppLoaderWrapper = ({ variant }: Props) => {
  return (
    <div className={styles.wrapper}>
      <AppLoader variant={variant} />
    </div>
  )
}

export default AppLoaderWrapper
