import React from "react"
import { css } from "glamor"
import ComboBox from "../common/comboBox/ComboBox"
import InputLabel from "../common/InputLabel"
import { withTranslation, WithTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import ErrorLabel from "../common/ErrorLabel"

interface Props extends WithTranslation {
  expirationMonthValue: string
  expirationMonthErrorText: string
  expirationYearValue: string
  expirationYearErrorText: string
  onChange: (e: any) => void
}

class CardExpiration extends React.PureComponent<Props, any> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      t,
      expirationMonthValue,
      expirationMonthErrorText,
      expirationYearValue,
      expirationYearErrorText,
      onChange
    } = this.props

    const months: any[] = []
    for (let index = 0; index <= 12; index++) {
      months.push({ value: index, label: index })
    }

    const year = new Date().getFullYear()
    const years: any[] = []
    for (let index = year; index <= year + 10; index++) {
      years.push({ value: index, label: index })
    }

    const onChangeWithTarget = (name: string) => (v: any) => {
      onChange({ target: { name: name, value: v && v.value } })
    }

    const hasExpirationMonthError = !!(expirationMonthErrorText && expirationMonthErrorText.length)
    const hasExpirationYearError = !!(expirationYearErrorText && expirationYearErrorText.length)

    return (
      <div className={`${containerStyle}`}>
        <InputLabel title={t<string>("CardExpiration.title")} />
        <div className={`${fieldsContainerStyle}`}>
          <div className={`${css(comboContainerStyle)}`}>
            <ComboBox
              options={months}
              placeholder={t<string>("CardExpiration.month")}
              required
              name={"expiration_month"}
              value={expirationMonthValue}
              onChange={onChangeWithTarget("expiration_month")}
              hasError={hasExpirationMonthError}
            />
          </div>
          <div className={`${css(comboContainerStyle)}`}>
            <ComboBox
              options={years}
              placeholder={t<string>("CardExpiration.year")}
              required
              name={"expiration_year"}
              value={expirationYearValue}
              onChange={onChangeWithTarget("expiration_year")}
              hasError={hasExpirationYearError}
            />
          </div>
        </div>
        {(hasExpirationMonthError || hasExpirationYearError) && (
          <ErrorLabel errors={[expirationMonthErrorText, expirationYearErrorText]} />
        )}
      </div>
    )
  }
}

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  flex: "3 1 0%"
})

const fieldsContainerStyle = css({
  display: "flex",
  flexDirection: "row"
})

const comboContainerStyle = css({
  display: "flex",
  flex: "1 1 0",
  marginRight: "8px",
  "> div": {
    width: "100%"
  }
})

export default withTranslation(I18N_NS)(CardExpiration)
