import React from "react"
import { css } from "glamor"
import InputLabel from "../common/InputLabel"
import { I18N_NS } from "../../utils/constants"
import { withTranslation, WithTranslation } from "react-i18next"
import ErrorLabel from "../common/ErrorLabel"

interface Props extends WithTranslation {
  paymentToken: string
  errorText: string
}

class EPSSecurityCode extends React.PureComponent<Props, any> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { t, paymentToken, errorText } = this.props

    const cssUrl = "https://s3.amazonaws.com/components.basset.ws/accommodations/iframes.css"
    const placeholder = t("EPSSecurityCode.title")
    const src =
      "https://checkout.ean.com/frames/cvv?token=" + paymentToken + "&cssUrl=" + cssUrl + "&placeholder=" + placeholder
    return (
      <div className={`${containerStyle}`}>
        <InputLabel title={t<string>("EPSSecurityCode.title")} />
        <iframe title={t<string>("EPSSecurityCode.title")} className={`${textInputContainer}`} src={src} />
        {errorText && errorText.length > 0 && <ErrorLabel errors={[errorText]} />}
      </div>
    )
  }
}

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  flex: "3 1 0%",
  "margin-right": "16px"
})

const textInputContainer = css({
  height: "36px",
  "*": {
    height: "32px"
  }
})

export default withTranslation(I18N_NS)(EPSSecurityCode)
