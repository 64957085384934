import RoomSection from "@basset-la/components-accommodations/dist/components/RoomSection"
import {
  RoomCompanionData,
  RoomGuestData,
  CompanionType
} from "@basset-la/components-accommodations/dist/model/roomSection"
import React from "react"

interface Props {
  roomGuests: RoomGuestData[]
  roomCapacities: number[]
  roomChildrenCount: number[]
  roomCompanionErrors: string[]
  roomCompanionsMap: Record<number, RoomCompanionData[]>
  onUpdateRoomGuests: (roomGuests: RoomGuestData[]) => void
  onUpdateRoomCompanionsMap: (roomCompanionsMap: Record<number, RoomCompanionData[]>) => void
  chkConfig?: any
}

const CommonCheckoutRoomSection: React.FC<Props> = ({
  roomGuests,
  roomCapacities,
  roomChildrenCount,
  roomCompanionErrors,
  roomCompanionsMap,
  chkConfig,
  onUpdateRoomGuests,
  onUpdateRoomCompanionsMap
}) => {
  const handleOnChangeGuestData = (i: number, field: string, value: string) => {
    const roomGuest = { ...roomGuests[i], [field]: value }

    if (roomGuest[field + "_error"]) {
      delete roomGuest[field + "_error"]
    }

    onUpdateRoomGuests([...roomGuests.slice(0, i), roomGuest, ...roomGuests.slice(i + 1, roomGuests.length)])
  }

  const handleOnChangeCompanionData = (roomNumber: number, index: number, field: string, value: string) => {
    onUpdateRoomCompanionsMap({
      ...roomCompanionsMap,
      [roomNumber]: [
        ...roomCompanionsMap[roomNumber].slice(0, index),
        {
          ...roomCompanionsMap[roomNumber][index],
          [field]: value,
          [`${field}_error`]: ""
        },
        ...roomCompanionsMap[roomNumber].slice(index + 1)
      ]
    })
  }

  const handleOnAddCompanion = (roomNumber: number) => {
    const roomCompanions = roomCompanionsMap[roomNumber]

    const maxCapacity = roomCapacities[roomNumber]
    const maxChildren = roomChildrenCount[roomNumber]
    const totalAdults = roomCompanions.filter(c => c.companion_type === "ADULT").length

    let newType: CompanionType = "CHILD"
    let idx = roomCompanions.length
    if (totalAdults < maxCapacity - maxChildren) {
      newType = "ADULT"
      idx = totalAdults
    }

    const data: RoomCompanionData = {
      firstName: "",
      lastName: "",
      documentNumber: "",
      documentType: "",
      firstName_error: "",
      lastName_error: "",
      documentNumber_error: "",
      documentType_error: "",
      general_error: "",
      companion_type: newType
    }

    onUpdateRoomCompanionsMap({
      ...roomCompanionsMap,
      [roomNumber]: [...roomCompanions.slice(0, idx), data, ...roomCompanions.slice(idx)]
    })
  }

  const handleOnDeleteAllCompanions = (roomNumber: number) => {
    onUpdateRoomCompanionsMap({
      ...roomCompanionsMap,
      [roomNumber]: []
    })
  }

  const handleOnDeleteCompanion = (roomNumber: number, index: number) => {
    onUpdateRoomCompanionsMap({
      ...roomCompanionsMap,
      [roomNumber]: roomCompanionsMap[roomNumber].filter((_, i) => i !== index)
    })
  }

  return (
    <RoomSection
      rooms={roomGuests}
      onChangeGuestData={handleOnChangeGuestData}
      roomCapacities={roomCapacities}
      roomCompanionsMap={roomCompanionsMap}
      onAddCompanion={handleOnAddCompanion}
      onChangeCompanionData={handleOnChangeCompanionData}
      checkoutConfig={chkConfig}
      onDeleteAllCompanionsInRoom={handleOnDeleteAllCompanions}
      onDeleteCompanionInRoom={handleOnDeleteCompanion}
      roomCompanionErrors={roomCompanionErrors}
    />
  )
}

export default CommonCheckoutRoomSection
