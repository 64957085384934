import { Car, Coverage, Fare, PickupDropoff, EquipmentInfo } from "@basset-la/components-cars/dist/models"

export interface CarResponse {
  car: Car
  payments_url?: string
  reservation_expiration_hours?: number
}

export interface CarReservation {
  id: string
  category: string
  type: string
  model: string
  image: string | null
  doors: number
  passenger_quantity: number
  baggage_quantity: number
  automatic_transmission: boolean
  air_conditioning: boolean
  unlimited_mileage: boolean
  provider: string
  plan: string
  equipments: EquipmentInfo
  coverages: Coverage[]
  fare: Fare
  pickup: PickupDropoff
  dropoff: PickupDropoff
}

export interface ReservationProduct {
  id: string
  reservation_id: string
  provider_reservation_id: string
  provider_confirmation_number: string
  client_id: string
  site: string
  channel: string
  source: string
  expiration_date: Date
  reseller_expiration_date: Date
  created_at: Date
  status: string
  price_adjustment: number
  statement: string
  activity_logs: any[]
  passenger: any
  car: CarReservation
  agent?: any
  fiscal_data?: any
  voucher_url?: string
  contact: any | null
  payment_url?: string
  flight_info?: {
    carrier: string
    number: string
  }
  terms_and_conditions: string
}

export const fetchCarDetails = async (config: any, rateId: string): Promise<CarResponse> => {
  const queryStr = `site=${config.country}&channel=WEB&source=${config.source}`

  const url = process.env.REACT_APP_CARS_API_URL
    ? `${process.env.REACT_APP_CARS_API_URL}/availabilities/${rateId}?${queryStr}`
    : ""

  const results = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
      "x-client-id": config.agency_id
    }
  })
  const json = await results.json()

  if (results.ok || results.status === 400) {
    return json
  }

  throw new Error(`${results.status}: ${json.error}`)
}

export const getReservationProduct = async (config: any, reservationId: string): Promise<ReservationProduct> => {
  const queryStr = `site=${config.country}&channel=WEB&source=${config.source}`

  const url = process.env.REACT_APP_CARS_API_URL
    ? `${process.env.REACT_APP_CARS_API_URL}/reservations/${reservationId}?${queryStr}`
    : ""

  const results = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
      "x-client-id": config.agency_id
    }
  })
  const json = await results.json()

  if (results.ok || results.status === 400) {
    return json
  }

  throw new Error(`${results.status}: ${json.error}`)
}
