import { css } from "@emotion/css"

import { Theme } from "@basset-la/themed-components/dist/model/types"

const styles = {
  reservationTextStyle: (theme: Theme) =>
    css({
      fontFamily: "Roboto",
      fontWeight: "300",
      fontSize: "18px",
      color: theme.colors.brand_primary,
      margin: 0,
      marginBottom: "8px"
    }),

  boldStyle: css({
    fontWeight: "500"
  }),

  emailTextStyle: css({
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "14px",
    margin: 0,
    color: "#4a4a4a"
  }),

  containerStyle: css({
    border: "solid 1px #CCC",
    backgroundColor: "#fff",
    padding: "16px 8px",
    marginBottom: "16px",
    "@media(min-width:1200px)": {
      flex: "3 1 0%",
      marginBottom: "0px"
    }
  })
}

export default styles
