import { getClientConfig } from "./common"

export const getEPSToken = async (rateID: string, lang: string) => {
  const url = process.env.REACT_APP_PAYMENTS_URL
  if (!url) throw new Error("missing key REACT_APP_PAYMENTS_URL")

  const apiKey = process.env.REACT_APP_API_KEY
  if (!apiKey) throw new Error("missing key REACT_APP_API_KEY")

  const config = await getClientConfig("HOTELS", lang)

  const res = await fetch(`${url}/tokens/eps?rate_id=${rateID}&site=${config.country}&channel=WEB`, {
    headers: {
      "x-client-id": config.agency_id,
      "x-api-key": apiKey
    }
  })
  if (res.ok) {
    const tokenResponse = await res.json()
    return tokenResponse.token
  }
  throw new Error("Failed to get EPS Token")
}
