// @flow

import React from "react"
import { css } from "glamor"

interface Props {
  errors: any
}

const ErrorLabel = ({ errors }: Props) => {
  return errors
    .reduce((allErrors: any, error: any) => {
      if (!(error in allErrors)) {
        allErrors.push(error)
      }
      return allErrors
    }, [])
    .map((e: any, i: any) => {
      return (
        <p key={i} className={`${style}`}>
          {e}
        </p>
      )
    })
}

const style = css({
  fontSize: "12px",
  lineHeight: "1.33",
  color: "#d0021b",
  margin: "0",
  fontFamily: "Roboto, serif",
  wordWrap: "break-word"
})

export default ErrorLabel
