import { css } from "glamor"

export default {
  containerStyle: css({
    display: "flex",
    flexDirection: "column"
  }),

  rowContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "16px",
    " > div:last-child": {
      marginBottom: 0
    },
    "@media(min-width:1200px)": {
      flexDirection: "row",
      " > div": {
        marginRight: "16px",
        marginBottom: 0
      },
      " > div:last-child": {
        marginRight: 0
      }
    }
  })
}
