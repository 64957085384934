// @flow

import React from "react"
import { useTheme } from "@basset-la/themed-components"
import styles from "./FormSection.styles"

interface Props {
  children: React.ReactNode
  title?: string
  fullWidth?: boolean
}

const FormSection = ({ children, title, fullWidth }: Props) => {
  const theme = useTheme()

  return (
    <div className={styles.container(fullWidth)}>
      {title && <h2 className={styles.title(theme)}>{title}</h2>}
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default FormSection
