import React from "react"
import { useTranslation } from "react-i18next"

import { useTheme } from "@basset-la/themed-components"

import { I18N_NS } from "../../utils/constants"
import styles from "./ThanksTotal.styles"

interface Props {
  currency: string
  total: string
  classNames?: {
    container?: string
    title?: string
    price?: string
  }
}

const ThanksTotal: React.FC<Props> = ({ currency, total, classNames }) => {
  const { t } = useTranslation(I18N_NS)

  const theme = useTheme()

  return (
    <div className={`${styles.container} ${classNames?.container}`}>
      <p className={`${styles.title(theme)} ${classNames?.title}`}>{t<string>("ThanksTotal.title")}</p>
      <p className={`${styles.price} ${classNames?.price}`}>
        {currency} {total}
      </p>
    </div>
  )
}

export default ThanksTotal
