import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  container: (fullWidth?: boolean) =>
    css({
      padding: "16px",
      borderRadius: "3px",
      backgroundColor: "#fff",
      border: "solid 1px #ccc",
      margin: 0,
      width: fullWidth ? "100%" : undefined,
      marginBottom: "8px",
      ":last-child": {
        marginBottom: "0px"
      }
    }),

  title: (t: Theme) =>
    css({
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "1.5",
      color: t.colors.brand_primary,
      margin: "0",
      marginBottom: "8px"
    }),

  content: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  })
}
