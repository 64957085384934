import React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import styles from "./ReservationDetailBreakdown.styles"

export interface Props {
  rooms: any[]
  checkinDate: string
  checkinTime: string
  checkoutDate: string
  checkoutTime: string
}

const ReservationDetailBreakdown = ({ rooms, checkinDate, checkinTime, checkoutDate, checkoutTime }: Props) => {
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.reservationBreakdownContainer}>
      <div className={styles.datesRowContainer} style={{ borderBottom: "1px solid #ccc", paddingTop: 0 }}>
        <p>{t<string>("AccommodationsThanks.arrival")}</p>
        <p>
          {checkinDate} {`(${t<string>("AccommodationsThanks.since")} ${checkinTime})`}
        </p>
      </div>
      <div className={styles.datesRowContainer}>
        <p>{t<string>("AccommodationsThanks.departure")}</p>
        <p>
          {checkoutDate} {`(${t<string>("AccommodationsThanks.until")} ${checkoutTime})`}
        </p>
      </div>
      <div className={styles.guestsContainer}>
        <p className={styles.detailTitleStyle}>{t<string>("AccommodationsThanks.guest_per_room")}</p>
        {rooms.map((r, i) => (
          <div key={i} className={styles.guestRow}>
            <p>{r.name}</p>
            <p>
              {r.guest.firstname} {r.guest.lastname} -{" "}
              {t(`AccommodationsThanks.documentTypes.${r.guest.document.type}`)}: {r.guest.document.number}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ReservationDetailBreakdown
