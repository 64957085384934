// @flow

import React, { Fragment } from "react"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { ThemeProps, theme } from "@basset-la/themed-components"
import Dialog from "@material-ui/core/Dialog"
import Backdrop from "@material-ui/core/Backdrop"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { I18N_NS } from "../utils/constants"

interface Props extends WithTranslation, ThemeProps {
  showTermAndConditions: boolean
  termAndConditions: string
  hideTermsAndConditions: () => void
  showProviderTerms: boolean
}

class TermsAndConditions extends React.PureComponent<Props, any> {
  termAndConditions = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "40px",
    " > p": {
      fontSize: "14px",
      fontWeight: "300",
      lineHeight: "1.14",
      margin: 0,
      marginLeft: "8px",
      color: "#4a4a4a",
      " > a": {
        color: this.props.theme.colors.brand_primary,
        cursor: "pointer"
      }
    }
  })

  closeButtonStyle = css({
    float: "right",
    padding: "0 !important",
    width: "24px !important",
    height: "24px !important",
    color: `${this.props.theme.colors.brand_primary} !important`
  })

  termAndConditionsStyle = css({
    fontSize: "16px",
    fontFamily: "Roboto",
    lineHeight: "24px",
    letterSpacing: "0.2px",
    padding: "16px",
    paddingTop: "0",
    color: "#4a4a4a",
    overflow: "scroll",
    " a": {
      color: this.props.theme.colors.brand_primary,
      cursor: "pointer"
    },
    " > p:first-child": {
      marginTop: "0"
    }
  })

  dialogHeaderStyle = css({
    flex: 1,
    flexDirection: "row",
    padding: "15px",
    paddingBottom: "10px",
    " > h3": {
      margin: 0
    }
  })

  render() {
    const { showTermAndConditions, termAndConditions, hideTermsAndConditions, showProviderTerms, t } = this.props

    if (!termAndConditions) return null

    return (
      <Fragment>
        <Backdrop open={showTermAndConditions} />
        <Dialog open={showTermAndConditions} onClose={hideTermsAndConditions}>
          <div className={`${this.dialogHeaderStyle}`}>
            <IconButton className={`${this.closeButtonStyle}`} onClick={hideTermsAndConditions} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <h3>{t<string>("TermsAndConditions.modal_title")}</h3>
          </div>
          <div className={`${this.termAndConditionsStyle}`}>
            <p dangerouslySetInnerHTML={{ __html: termAndConditions }} />
            {showProviderTerms && (
              <a target="_blank" rel="noopener noreferrer" href="https://developer.ean.com/terms/es">
                Terminos y condiciones del proveedor
              </a>
            )}
          </div>
        </Dialog>
      </Fragment>
    )
  }
}

export default theme(withTranslation(I18N_NS)(TermsAndConditions))
