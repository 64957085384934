import React, { useEffect, useState } from "react"

import clone from "lodash/clone"
import qs from "qs"
import ReactGA from "react-ga"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch, useLocation } from "react-router-dom"

import { validations } from "@basset-la/commons-frontend"
import BillingContainer from "@basset-la/commons-frontend/dist/components/checkout/billing/BillingContainer"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import { getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useTheme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { getCountries, getStatesByCountryCode } from "../../../api/common"
import { createReservationAsync, getReservationProcess } from "../../../api/reservations"
import { FormConfig, getFormConfig } from "../../../config/formConfig"
import { I18N_NS } from "../../../utils/constants"
import { createCarsPurchaseModel } from "../../../utils/carsUtils"
import FormSection from "../../common/FormSection"
import { checkoutConfig } from "@basset-la/components-products/dist/utils/config"
import ContactContainer from "../../contact/ContactContainer"
import styles from "./CarsCheckout.styles"
import NotFound from "../../NotFound"
import TermsAndConditions from "../../TermsAndConditions"
import * as model from "../../types"
import { CheckoutDriverForm, CheckoutFlightForm } from "@basset-la/components-cars/dist/models/checkout"
import { CarResponse, fetchCarDetails } from "../../../api/cars"
import PriceBreakdownWeb from "@basset-la/components-cars/dist/components/PriceBreakdownWeb"
import CarCheckoutFlightForm from "@basset-la/components-cars/dist/components/CarCheckoutFlightForm"
import CarCheckoutDriverForm from "@basset-la/components-cars/dist/components/CarCheckoutDriverForm"
import CarClusterCheckout from "@basset-la/components-cars/dist/components/CarClusterCheckout"
import { getAirlines } from "../../../api/autocomplete"
import AirlineLogo from "@basset-la/components-flights/dist/components/AirlineLogo"
import ModalDialog from "../ModalDialog/ModalDialog"
import CancellationPolicyText from "@basset-la/components-cars/dist/components/CancellationPolicyText"
import { PlanType } from "@basset-la/components-cars/dist/models"
import { useAuthUser, useConfig } from "@basset-la/components-commons"
import Message from "@basset-la/components-commons/dist/components/Message"
import moment from "moment"

interface RouteMatch {
  id: string
}

const CarsCheckout: React.FC = () => {
  const match = useRouteMatch<RouteMatch>()
  const { search } = useLocation()
  const { i18n, t } = useTranslation(I18N_NS)
  const { userId } = useAuthUser()
  const { config } = useConfig()

  const theme = useTheme()
  const history = useHistory()

  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isBooking, setIsBooking] = useState<boolean>(false)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [showTermAndConditions, setShowTermAndConditions] = useState<boolean>(false)
  const [termsAndConditionAccepted, setTermsAndConditionAccepted] = useState<boolean>(false)
  const [site, setSite] = useState<string>("")
  const [language, setLanguage] = useState("")
  const [agencyId, setAgencyId] = useState("")
  const [formConfig, setFormConfig] = useState<FormConfig>(getFormConfig())
  const [product, setProduct] = useState<CarResponse | null>(null)
  const [driver, setDriver] = useState<CheckoutDriverForm>({
    name: "",
    lastName: "",
    birthDay: 0,
    birthMonth: 0,
    birthYear: 0,
    documentType: "PAS",
    documentNumber: "",
    documentExpiration: "",
    documentCountry: "",
    errors: {}
  })
  const [termAndConditions, setTermAndConditions] = useState<any>()
  const [billingInfo, setBillingInfo] = useState<model.BillingInformation>()
  const [contactInfo, setContactInfo] = useState<model.ContactInformation>()
  const [flightInfo, setFlightInfo] = useState<CheckoutFlightForm>({
    airline: "",
    flightNumber: "",
    errors: {}
  })
  const [geo, setGeo] = useState<any>()
  const [openCarTerms, setOpenCarTerms] = useState(false)
  const [openCarPolicies, setOpenCarPolicies] = useState(false)

  const isMobile = useMediaQuery("(max-width: 768px)")

  const createContactInformation = (countryCode: string) => {
    return {
      email: "",
      email_confirmation: "",
      telephone_type: checkoutConfig.config[countryCode].defaults.telephones.type,
      telephone_country_code: checkoutConfig.config[countryCode].defaults.telephones.country,
      telephone_area_code: checkoutConfig.config[countryCode].defaults.telephones.area,
      telephone_number: ""
    }
  }

  const createBillingInformation = (countryCode: string) => {
    return {
      fiscal_name: "",
      fiscal_id: "",
      fiscal_situation: checkoutConfig.config[countryCode].defaults.fiscalSituation,
      province: "",
      city: "",
      street: "",
      street_number: "",
      floor_number: "",
      building_number: "",
      postal_code: ""
    }
  }

  useEffect(() => {
    const retrieveCheckoutData = async (id: string) => {
      try {
        setIsFetching(true)
        setAgencyId(config.agency_id)
        setSite(config.country)

        const lang = getLocale(i18n).substring(0, 2)
        const fc = getFormConfig(config.checkout_form_config)

        const carPromise = await fetchCarDetails(config, id)
        const countriesPromise = await getCountries(config.agency_id, lang)
        const statesPromise = await getStatesByCountryCode(config.country, config.agency_id, lang)

        const [carResponse, countries, states] = await Promise.all([carPromise, countriesPromise, statesPromise])

        setLanguage(lang)
        setFormConfig(fc)
        setTermAndConditions(config.brand.terms_and_conditions)

        setNotFound(!carResponse)
        setBillingInfo(createBillingInformation(config.country))
        setContactInfo(createContactInformation(config.country))
        setGeo({
          countries: countries,
          states: states
        })
        if (carResponse) {
          const params = qs.parse(search.substring(1))
          if (params["plan"]) {
            carResponse.car.plan = params["plan"] as PlanType
          }

          let equipmentTypes = []
          if (params["equipments"]) {
            equipmentTypes = params["equipments"].split(",")
          }
          carResponse.car.equipments.chargeables = carResponse.car.equipments.chargeables.filter(
            e => equipmentTypes.find(t => t === e.type) != null
          )

          setProduct(carResponse)
        }
      } catch (error) {
        setNotFound(true)

        console.error(error)

        return
      } finally {
        setIsFetching(false)
      }
    }

    retrieveCheckoutData(match.params.id)
  }, [match.params.id, i18n, search])

  const validateContactInfo = (): boolean => {
    if (!contactInfo) return false

    let isValid = true

    const contact = clone(contactInfo)
    if (!contact.email) {
      isValid = false
      contact.email_error = t("CarsCheckout.emailError")
    } else {
      if (!validations.validateEmail(contact.email)) {
        isValid = false
        contact.email_error = t("CarsCheckout.emailInvalid")
      } else {
        if (contact.email !== contact.email_confirmation) {
          isValid = false
          contact.email_confirmation_error = t("CarsCheckout.emailConfirmation")
        }
      }
    }

    if (!contact.telephone_country_code) {
      isValid = false
      contact.telephone_country_code_error = t("CarsCheckout.telephoneCountryCode")
    }

    if (!validations.validateNumeric(contact.telephone_country_code || "")) {
      isValid = false
      contact.telephone_number_error = t("CarsCheckout.telephoneInvalid")
    }

    contact.telephone_area_code_error = ""

    if (contact.telephone_type !== "M") {
      if (!contact.telephone_area_code) {
        isValid = false
        contact.telephone_area_code_error = t("CarsCheckout.telephoneAreaCode")
      }

      if (!validations.validateAreaCode(contact.telephone_area_code || "")) {
        isValid = false
        contact.telephone_number_error = t("CarsCheckout.telephoneInvalid")
      }
    }

    if (!contact.telephone_number) {
      isValid = false
      contact.telephone_number_error = t("CarsCheckout.telephoneNumber")
    }

    if (!validations.validateNumeric(contact.telephone_number || "")) {
      isValid = false
      contact.telephone_number_error = t("CarsCheckout.telephoneInvalid")
    }

    if (!isValid) {
      setContactInfo(contact)
    }

    return isValid
  }

  const validateFields = (config: any, info: any, prefix: string): boolean => {
    let isValid = true
    if (config.length > 0) {
      for (let i in config) {
        let name = config[i].name
        let regexValid = true

        if (config[i].regex) {
          let regex = new RegExp(config[i].regex, "g")
          regexValid = regex.test(info[name])
        }

        if (config[i].required && (!info[name] || !regexValid)) {
          isValid = false
          info[name + "_error"] = t(prefix + name)
        }
      }
    }

    return isValid
  }

  const validateBillingInfo = (): boolean => {
    if (!billingInfo) return false

    const billing = clone(billingInfo)
    const chkConfig = checkoutConfig.config[site]
    const identification = chkConfig.billing.identification ? chkConfig.billing.identification : []
    const addressStreet = chkConfig.billing.address_street ? chkConfig.billing.address_street : []
    const addressExtra = chkConfig.billing.address_extra ? chkConfig.billing.address_extra : []
    const fiscalId = chkConfig.billing.fiscal_id ? chkConfig.billing.fiscal_id : []
    const fiscalSituation = chkConfig.billing.fiscal_situation ? chkConfig.billing.fiscal_situation : []

    const config = identification
      .concat(addressStreet)
      .concat(addressExtra)
      .concat(fiscalId)
      .concat(fiscalSituation)

    let isValid = validateFields(config, billing, "CarsCheckout.billingError.")

    if (!validations.validateFiscalID(billing.fiscal_id || "", billing.fiscal_situation || "", site)) {
      billing.fiscal_id_error = t("CarsCheckout.billingError.fiscal_id")
      isValid = false
    }

    if (!isValid) {
      setBillingInfo(billing)
    }

    return isValid
  }

  const validatePassengers = (): boolean => {
    let isValid = true
    let nameError = ""
    let lastNameError = ""
    let birthdayErrors: string[] = []
    let documentErrors: string[] = []

    if (driver!.name === "") {
      isValid = false
      nameError = t("CarsCheckout.passengerError.firstName")
    }
    if (driver!.lastName === "") {
      isValid = false
      lastNameError = t("CarsCheckout.passengerError.lastName")
    }
    if (driver!.birthDay === 0) {
      isValid = false
      birthdayErrors.push(t("CarsCheckout.passengerError.birthDay"))
    }
    if (driver!.birthMonth === 0) {
      isValid = false
      birthdayErrors.push(t("CarsCheckout.passengerError.birthMonth"))
    }
    if (driver!.birthYear === 0) {
      isValid = false
      birthdayErrors.push(t("CarsCheckout.passengerError.birthYear"))
    }
    if (driver!.documentNumber === "") {
      isValid = false
      documentErrors.push(t("CarsCheckout.passengerError.documentNumber"))
    }

    const params = qs.parse(search.substring(1))
    const minAge = parseInt(params["driver_age"])
    const driverAge =
      moment().diff(moment({ year: driver!.birthYear, month: driver!.birthMonth - 1, day: driver!.birthDay }), "days") /
      365
    if (driverAge < minAge) {
      isValid = false
      documentErrors.push(t("CarsCheckout.passengerError.driverAge", { age: minAge }))
    }

    if (!isValid) {
      setDriver({
        ...driver!,
        errors: {
          nameError: nameError || undefined,
          lastNameError: lastNameError || undefined,
          birthdayErrors: birthdayErrors.length > 0 ? birthdayErrors : undefined,
          documentErrors: documentErrors.length > 0 ? documentErrors : undefined
        }
      })
    }

    return isValid
  }

  const validateFlightInfo = () => {
    let isValid = true
    let airlineError = ""
    let flightNumberError = ""

    if (flightInfo?.airline === "") {
      isValid = false
      airlineError = t("CarsCheckout.flightInfoError.airline")
    }
    if (!validateFlightNumber(flightInfo?.flightNumber || "")) {
      isValid = false
      flightNumberError = t("CarsCheckout.flightInfoError.flightNumber")
    }

    if (!isValid) {
      setFlightInfo({
        ...flightInfo,
        errors: {
          airlineError: airlineError || "",
          flightNumberError: flightNumberError || ""
        }
      })
    }

    return isValid
  }

  const validateFlightNumber = (v: string): boolean => {
    return /^\d+$/.test(v)
  }

  const validate = (formConfig: FormConfig): boolean => {
    const validPassengers = !formConfig.passengers ? true : validatePassengers()
    const validFlight = !(product?.car.pickup.location.type === "AIRPORT") ? true : validateFlightInfo()
    const validBillingInfo =
      !formConfig.billingInfo || formConfig.autocompleteBillingInfo ? true : validateBillingInfo()
    const validContactInfo = !formConfig.contactInfo ? true : validateContactInfo()

    return validPassengers && validFlight && validBillingInfo && validContactInfo
  }

  const validateAndPurchase = async () => {
    const lang = getLocale(i18n).substring(0, 2)

    if (validate(formConfig)) {
      setIsBooking(true)

      const req = createCarsPurchaseModel(
        driver!,
        flightInfo,
        billingInfo,
        contactInfo,
        site,
        formConfig,
        lang,
        product!.car
      )

      try {
        const resProc = await createReservationAsync(req, userId, site, agencyId)

        const interval = setInterval(async () => {
          const reservationProcess = await getReservationProcess(resProc.id, userId, site, agencyId)
          switch (reservationProcess.status) {
            case "OK":
              if (product?.payments_url) {
                let url = product!.payments_url.replace("{id}", `${reservationProcess.reservation_id}`)
                const { _ga } = qs.parse(search.substring(1))

                if (_ga) {
                  url = url.replace("{_ga}", _ga)
                } else {
                  const ga = ReactGA.ga()
                  ga(function(tracker) {
                    let clientId = tracker.get("clientId")
                    url = url.replace("{_ga}", clientId)
                  })
                }
                window.location.href = url
              } else {
                history.push(`/checkout/cars/reservations/${reservationProcess.reservation_id}/thanks`)
              }

              clearInterval(interval)
              break
            case "ERROR":
              console.error(reservationProcess.error_message)
              setNotFound(true)
              clearInterval(interval)
              break
            default:
              console.log("Still booking...")
              break
          }
        }, 1000)
      } catch (err) {
        console.error(err)
        setNotFound(true)
      }
    }
  }

  const onChange = (target: "billingInfo" | "contactInfo") => (field: string, value: string) => {
    switch (target) {
      case "billingInfo":
        const billing = { ...billingInfo, [field]: value }
        if (billing[field + "_error"]) delete billing[field + "_error"]
        setBillingInfo(billing)
        break

      case "contactInfo":
        const contact = { ...contactInfo, [field]: value }
        if (contact[field + "_error"]) delete contact[field + "_error"]
        setContactInfo(contact)
        break
    }
  }

  const onChangeDriver = (d: CheckoutDriverForm) => {
    setDriver({
      ...d,
      errors: {}
    })
  }

  const onChangeFlightInfo = (f: CheckoutFlightForm) => {
    setFlightInfo({
      ...f,
      errors: {}
    })
  }

  const hideTermsAndConditions = () => {
    setShowTermAndConditions(false)
  }

  const handleShowTermAndConditions = () => {
    setShowTermAndConditions(true)
  }

  const onChangeTermAndConditions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setTermsAndConditionAccepted(checked)
  }

  const goBackToSearch = () => {
    history.goBack()
  }

  const handleOpenCarTerms = () => {
    setOpenCarTerms(true)
  }

  const handleCloseCarTerms = () => {
    setOpenCarTerms(false)
  }

  const handleOpenCarPolicies = () => {
    setOpenCarPolicies(true)
  }

  const handleCloseCarPolicies = () => {
    setOpenCarPolicies(false)
  }

  let requiredDocAlert: any[] = []
  const chkConfig = checkoutConfig.config[site]

  if (notFound) {
    return (
      <div className={`${styles.background(theme)}`}>
        <div className={`${styles.checkout}`}>
          <div className={`${styles.notFoundContainer}`}>
            <NotFound description={t<string>("CarsCheckout.notFoundCar")} onBackToSearch={goBackToSearch} />
          </div>
        </div>
      </div>
    )
  }

  if (isFetching) {
    return (
      <div className={`${styles.background(theme)}`}>
        <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="CARS_CHECKOUT" />
      </div>
    )
  }

  if (product && geo) {
    return (
      <>
        <ModalDialog open={openCarTerms} title={t("CarsCheckout.termsTitle")} onClose={handleCloseCarTerms}>
          <span dangerouslySetInnerHTML={{ __html: product!.car.terms_and_conditions || "" }} />
        </ModalDialog>

        <ModalDialog open={openCarPolicies} title={t("CarsCheckout.policiesTitle")} onClose={handleCloseCarPolicies}>
          <CancellationPolicyText
            reservationExpirationHours={product!.reservation_expiration_hours || 0}
            carPickupDate={product!.car.pickup.date}
          />
        </ModalDialog>

        <div className={`${styles.background(theme)}`}>
          {isBooking && <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="CARS_PAYMENT" />}
          {!isBooking && (
            <div className={`${styles.checkout}`}>
              <p className={`${styles.title(theme)}`}>{t<string>("CarsCheckout.title")}</p>
              <div className={`${styles.mainContainer}`}>
                <div className={`${styles.detailContainer}`}>
                  <div className={`${styles.priceBreakdownContainer}`}>
                    <PriceBreakdownWeb
                      isMobile={false}
                      rate={product!.car}
                      equipments={product!.car.equipments.chargeables}
                    />
                  </div>
                  <div className={`${styles.flightDetailContainer}`}>
                    <CarClusterCheckout
                      car={product!.car}
                      onOpenPolicies={handleOpenCarPolicies}
                      onOpenTerms={handleOpenCarTerms}
                    />
                  </div>
                </div>
                <div className={`${styles.formContainer}`}>
                  {requiredDocAlert}
                  {formConfig.passengers && (
                    <FormSection>
                      <CarCheckoutDriverForm
                        disabled={false}
                        driver={driver!}
                        countries={geo.countries.map(c => {
                          return { code: c.value, name: c.label }
                        })}
                        minAge={14}
                        maxAge={99}
                        onChange={onChangeDriver}
                      />
                    </FormSection>
                  )}
                  {product.car.pickup.location.type === "AIRPORT" && (
                    <FormSection title={t<string>("CarsCheckout.flightDataTitle")}>
                      <CarCheckoutFlightForm
                        disabled={false}
                        flight={flightInfo}
                        renderAirlineIcon={iataCode => <AirlineLogo airlineCode={iataCode} />}
                        getRegions={getAirlines(agencyId, site, language)}
                        onChange={onChangeFlightInfo}
                      />
                    </FormSection>
                  )}
                  {formConfig.billingInfo && (
                    <FormSection title={t<string>("CarsCheckout.billingTitle")}>
                      {formConfig.autocompleteBillingInfo && (
                        <Message open variant="fixed" action="info" message={t("FormConfig.autocompleteBillingInfo")} />
                      )}
                      <BillingContainer
                        onChange={onChange("billingInfo")}
                        billingInfo={billingInfo}
                        checkoutConfig={chkConfig}
                        geo={geo}
                      />
                    </FormSection>
                  )}
                  {formConfig.contactInfo && (
                    <FormSection title={t<string>("CarsCheckout.contactTitle")}>
                      <ContactContainer onChange={onChange("contactInfo")} contact={contactInfo} />
                    </FormSection>
                  )}
                  <div className={`${styles.termAndConditions(theme)}`}>
                    <Checkbox
                      classes={{
                        root: `${styles.checkbox}`,
                        checked: `${styles.checkboxChecked(theme)}`
                      }}
                      checked={termsAndConditionAccepted}
                      onChange={onChangeTermAndConditions}
                    />
                    <p>
                      {t<string>("CarsCheckout.termAndConditionsAccept")}{" "}
                      <button onClick={handleShowTermAndConditions}>
                        {t<string>("CarsCheckout.termAndConditionsLink")}
                      </button>
                    </p>
                  </div>
                  <Button
                    id="buy-button"
                    variant={"contained"}
                    classes={{ root: `${styles.button(theme)}` }}
                    disabled={isBooking || !termsAndConditionAccepted}
                    onClick={() => validateAndPurchase()}
                  >
                    {product?.payments_url
                      ? t<string>("CarsCheckout.buyButton")
                      : t<string>("CarsCheckout.reserveButton")}
                  </Button>
                  <TermsAndConditions
                    showTermAndConditions={showTermAndConditions}
                    hideTermsAndConditions={hideTermsAndConditions}
                    termAndConditions={termAndConditions}
                    showProviderTerms={false}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }

  return <></>
}

export default CarsCheckout
