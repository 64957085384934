import { CheckoutDriverForm, CheckoutFlightForm, Car } from "@basset-la/components-cars/dist/models"
import moment from "moment"
import checkoutConfig from "../components/config/checkoutConfig.json"

export const createCarsPurchaseModel = (
  driver: CheckoutDriverForm,
  flightInfo: CheckoutFlightForm,
  billingInfo: any,
  contact: any,
  site: string,
  formConfig: any,
  lang: string,
  car: Car
) => {
  let fiscalIndetification: any = null
  let fiscalData: any = null
  let contactInfo: any = null

  if (formConfig.billingInfo) {
    const siteConfig = checkoutConfig.config[site as keyof typeof checkoutConfig]
    const typeFiscal =
      siteConfig.billing.identification
        .find(o => o.name === "fiscal_situation")
        ?.options?.find(ft => ft.value === billingInfo.fiscal_situation)?.code ?? ""

    fiscalIndetification = {
      type: typeFiscal,
      number: billingInfo.fiscal_id
    }
    fiscalData = {
      identification: {
        type: typeFiscal,
        number: billingInfo.fiscal_id,
        fiscal_name: billingInfo.fiscal_name,
        tax_situation: billingInfo.fiscal_situation
      },
      address: {
        state: billingInfo.province,
        city: billingInfo.city,
        country: site,
        street: billingInfo.street,
        number: billingInfo.street_number,
        floor: billingInfo.floor_number,
        door: billingInfo.building_number,
        postal_code: billingInfo.postal_code
      }
    }
  }

  if (formConfig.contactInfo) {
    contactInfo = {
      language: lang,
      email: contact.email,
      telephone: {
        type: contact.telephone_type,
        country_code: contact.telephone_country_code,
        area_code: contact.telephone_area_code,
        number: contact.telephone_number
      }
    }
  }

  const bdate = moment(`${driver.birthYear}-${driver.birthMonth}-${driver.birthDay}`)
  const age = moment(new Date()).diff(bdate, "years")

  const flight =
    flightInfo.airline !== "" && flightInfo.flightNumber !== ""
      ? {
          carrier: flightInfo.airline,
          number: flightInfo.flightNumber
        }
      : undefined

  let product = {
    item_id: car.id,
    type: "CAR",
    plan: car.plan,
    equipments: car.equipments.chargeables.map(e => e.type),
    passenger: {
      firstname: driver.name,
      lastname: driver.lastName,
      age: age,
      document: {
        type: driver.documentType,
        number: driver.documentNumber,
        issuing_country: driver.documentCountry,
        valid_thru: driver.documentExpiration
      }
    },
    car_flight_info: flight
  }

  return {
    products: [product],
    //@deprecate use fiscal_data
    fiscal_identification: fiscalIndetification,
    fiscal_data: fiscalData,
    contact: contactInfo,
    autocomplete_billing_info: formConfig.autocompleteBillingInfo
  }
}
