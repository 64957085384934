import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  titleStyle: (t: Theme) =>
    css({
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "1.78",
      width: "100%",
      color: t.colors.brand_primary
    }),

  alertStyle: css({
    margin: "8px 0"
  }),

  reservationDetailStyle: css({
    borderTop: "1px solid #ccc",
    display: "flex",
    width: "100%",
    marginTop: "8px",
    flexDirection: "column",
    " >div": {
      borderBottom: "1px dashed #ccc",
      padding: "8px 0",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      " >p": {
        fontSize: "14px",
        fontWeight: "300",
        color: "#4a4a4a",
        lineHeight: "1.14"
      },
      " >p:last-child": {
        textAlign: "right",
        maxWidth: "260px",
        fontWeight: "500"
      }
    },
    " >div:last-child": {
      borderBottom: 0
    }
  }),

  accommodationContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "16px",
    borderRadius: "3px",
    marginTop: "8px"
  }),

  titleContainerStyle: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "0 8px",
    alignItems: "center"
  }),

  containerStyle: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    backgroundColor: "#ebebeb",
    borderRadius: "3px",
    "@media(min-width:1200px)": {
      padding: "16px"
    },
    " p": {
      margin: 0
    }
  })
}
