import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  container: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    margin: "0 auto",
    "@media(min-width:1200px)": {
      maxWidth: "880px"
    }
  }),

  card: (t: Theme, fullWidth: boolean = false) =>
    css({
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      border: "solid 1px #CCC",
      backgroundColor: t.colors.background_primary,
      padding: "16px",
      justifyContent: "space-between",
      width: fullWidth ? "100%" : undefined
    }),

  cardItem: css({
    flex: "1 1 auto"
  }),

  infoContainer: css({
    "@media(min-width:1200px)": {
      display: "flex",
      flexDirection: "row"
    }
  }),

  content: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    gap: "16px"
  }),

  title: css({
    display: "flex",
    flexDirection: "row",
    padding: "8px"
  }),

  titleText: css({
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "300",
    lineHeight: "1.33",
    margin: 0,
    color: "#0d207b",
    marginLeft: "8px"
  }),

  checkIcon: css({
    width: "24px",
    height: "24px",
    marginTop: "4px",
    backgroundSize: "contain"
  })
}
