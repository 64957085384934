import qs from "qs"
import moment from "moment"

import { FormConfig } from "../config/formConfig"
import checkoutConfig from "../components/config/checkoutConfig.json"

import {
  CheckoutPassenger,
  Insurance,
  PassengerRate,
  SearchboxParams,
  UpgradedCategory
} from "@basset-la/components-insurances/dist/models"
import { InsurancePagination } from "../components/types"
import { CheckoutEmergencyContact } from "./common"

export const parseCheckoutPassengers = (product: Insurance): CheckoutPassenger[] => {
  return product.rate.passengers.map(passenger => initialCheckoutPassenger(passenger))
}

const initialCheckoutPassenger = (passenger: PassengerRate): CheckoutPassenger => {
  const disabledFields = {
    birthDate: false,
    document: false,
    firstname: false,
    lastname: false,
    validThru: false
  }

  return {
    name: "",
    lastName: "",
    birthDay: 0,
    birthMonth: 0,
    birthYear: 0,
    documentType: "PAS",
    documentNumber: "",
    validThru: undefined,
    coverages: passenger.upgraded?.map(coverage => coverage.name) || [],
    errors: {},
    disabledFields: { ...disabledFields }
  }
}

export interface SearchParams {
  upgradedRates: Array<{ index: number; upgraded: Array<{ rate: { id: string } }> }>
}

export const extraCoveragesFromUrl = (search: string, product: Insurance) => {
  const obj = qs.parse(search, { ignoreQueryPrefix: true })

  const coveredQs = obj["covered"] as string

  if (!coveredQs) {
    return product
  }

  const copy = { ...product }

  coveredQs.split("|").forEach((rateIds: string, passengerIndex: number) => {
    rateIds.split(",").forEach(rateId => {
      const upgrade = copy.upgrades!.find(u => u.rates.find(r => r.id === rateId))

      if (!upgrade) return

      const upgradedCategory: UpgradedCategory = {
        name: upgrade.name,
        category: upgrade.category,
        description: upgrade.description,
        rate: upgrade.rates.find(r => r.id === rateId)!
      }

      const currPassengerRef = copy.rate.passengers[passengerIndex]

      const upgradesChanged = [...(currPassengerRef.upgraded || [])]

      const hasInclude = upgradesChanged.findIndex(u => u.name === upgradedCategory.name)

      if (hasInclude > -1) return

      currPassengerRef.upgraded = [...upgradesChanged, upgradedCategory]
    })
  })

  return copy
}

export const createInsurancePurchaseModel = (
  passengersForm: CheckoutPassenger[],
  emergencyContact: CheckoutEmergencyContact,
  billingInfo: any,
  contact: any,
  formConfig: FormConfig,
  site: string,
  lang: string,
  insurance: Insurance
) => {
  let fiscalIndetification: any = null

  let fiscalData: any = null

  let contactInfo: any = null

  if (formConfig.billingInfo) {
    const siteConfig = checkoutConfig.config[site as keyof typeof checkoutConfig]

    const typeFiscal =
      siteConfig.billing.identification
        .find(o => o.name === "fiscal_situation")
        ?.options?.find(ft => ft.value === billingInfo.fiscal_situation)?.code ?? ""

    fiscalIndetification = {
      type: typeFiscal,
      number: billingInfo.fiscal_id
    }

    fiscalData = {
      identification: {
        type: typeFiscal,
        number: billingInfo.fiscal_id,
        fiscal_name: billingInfo.fiscal_name,
        tax_situation: billingInfo.fiscal_situation
      },
      address: {
        state: billingInfo.province,
        city: billingInfo.city,
        country: site,
        street: billingInfo.street,
        number: billingInfo.street_number,
        floor: billingInfo.floor_number,
        door: billingInfo.building_number,
        postal_code: billingInfo.postal_code
      }
    }
  }

  if (formConfig.contactInfo) {
    contactInfo = {
      language: lang,
      email: contact.email,
      telephone: {
        type: contact.telephone_type,
        country_code: contact.telephone_country_code,
        area_code: contact.telephone_area_code,
        number: contact.telephone_number
      }
    }
  }

  const passengersInsurance: any = []

  passengersForm.forEach((passenger, index) => {
    const bdate = moment(`${passenger.birthYear}-${passenger.birthMonth}-${passenger.birthDay}`, "YYYY-MM-DD")

    const upgrades = insurance.rate.passengers[index].upgraded?.map(upgrade => upgrade.rate.id)

    passengersInsurance.push({
      firstname: passenger.name,
      lastname: passenger.lastName,
      birth: bdate.format("DD/MM/YYYY"),
      document: {
        type: passenger.documentType,
        number: passenger.documentNumber,
        issuing_country: lang,
        valid_thru: passenger.validThru
      },
      upgrades
    })
  })

  let emergContact
  if (emergencyContact.name && emergencyContact.lastName && emergencyContact.phone) {
    emergContact = {
      firstname: emergencyContact.name,
      lastname: emergencyContact.lastName,
      phone: emergencyContact.phone
    }
  }

  const product = {
    item_id: insurance.id,
    type: "TRAVEL_INSURANCE",
    travel_insurances_passengers: passengersInsurance,
    travel_insurances_emergency_contact: emergContact
  }

  return {
    products: [product],
    //@deprecate use fiscal_data
    fiscal_identification: fiscalIndetification,
    fiscal_data: fiscalData,
    contact: contactInfo,
    autocomplete_billing_info: formConfig.autocompleteBillingInfo
  }
}

export const mapParamsToUrl = (
  search: SearchboxParams,
  converToAges: boolean,
  pagination?: InsurancePagination,
  crosselling?: boolean
) => {
  const now = moment()

  const ages1 = search.passengers?.age || []
  const ages2 = converToAges ? search.passengers?.birthday?.map(b => now.diff(b, "years")) || [] : []
  const ages = [...ages1, ...ages2]

  const birthdays = !converToAges ? search.passengers?.birthday?.map(b => b.format("YYYY-MM-DD")) || [] : []

  let agesValues = {}
  if (search.passengers?.age || converToAges) {
    agesValues = { ages: ages.join(",") }
  }

  let birthdaysValues = {}
  if (search.passengers?.birthday && !converToAges) {
    birthdaysValues = { birthdays: birthdays.join(",") }
  }

  const searchReq = {
    origin_region_id: search.origin?.id || "",
    origin_region_type: search.origin?.type || "",
    origin_region_name: search.origin?.name || "",
    origin_region_iata_code: search.origin?.iata_code || "",
    destination_region_id: search.destination?.id || "",
    destination_region_type: crosselling ? search.destination?.type : undefined,
    from: search.departureDate?.format("YYYY-MM-DD") || "",
    to: search.returnDate?.format("YYYY-MM-DD") || "",
    ...agesValues,
    ...birthdaysValues
  }

  const paginationReq = {
    limit: pagination ? pagination.limit : undefined,
    offset: pagination ? pagination.offset : undefined
  }

  const query = qs.stringify(
    {
      ...searchReq,
      ...paginationReq
    },
    { encode: false }
  )

  return query
}
