import React from "react"
import { css } from "glamor"
import InputLabel from "../common/InputLabel"
import { I18N_NS } from "../../utils/constants"
import { withTranslation, WithTranslation } from "react-i18next"
import ErrorLabel from "../common/ErrorLabel"

interface Props extends WithTranslation {
  paymentToken: string
  isCreditCardValid: boolean
  errorText: string
}

class EPSCardNumber extends React.PureComponent<Props, any> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    // let { isCreditCardValid } = this.props
    // function bindEvent(element, eventName, eventHandler) {
    //   if (element.addEventListener) {
    //     element.addEventListener(eventName, eventHandler, false)
    //   } else if (element.attachEvent) {
    //     element.attachEvent("on" + eventName, eventHandler)
    //   }
    // }
    // bindEvent(window, "message", function(e) {
    //   if (e.origin !== "https://checkout.ean.com") return
    //   const data = e.data.eanCheckoutMessage
    //   //console.log(data)
    //   //Posible messages: CVVLoaded, CCLoaded, CCValid, CardType, CCSaved
    //   switch (data.message) {
    //     case "CCValid":
    //       isCreditCardValid = data.value
    //     //console.log(isCreditCardValid)
    //   }
    // })
  }

  render() {
    const { t, paymentToken, errorText } = this.props

    const cssUrl = "https://s3.amazonaws.com/components.basset.ws/accommodations/iframes.css"
    const placeholder = t("EPSCardNumber.title")
    const src =
      "https://checkout.ean.com/frames/cc?token=" + paymentToken + "&cssUrl=" + cssUrl + "&placeholder=" + placeholder

    return (
      <div className={`${containerStyle}`}>
        <InputLabel title={t<string>("EPSCardNumber.title")} />
        <iframe title={t<string>("EPSCardNumber.title")} className={`${textInputContainer}`} src={src} />
        {errorText && errorText.length > 0 && <ErrorLabel errors={[errorText]} />}
      </div>
    )
  }
}

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  flex: "3 1 0%",
  "margin-right": "16px"
})

const textInputContainer = css({
  height: "36px",
  "*": {
    height: "32px"
  }
})

export default withTranslation(I18N_NS)(EPSCardNumber)
