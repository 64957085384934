import { ChangeFareCluster } from "@basset-la/components-flights/dist/model"

import { fetchAsync, getClientConfig } from "./common"

type productType = "HOTELS" | "FLIGHTS"

const cluster = require("./mocks/cluster.json")
const flightReservation = require("./mocks/flightreservation.json")

const getCluster = async (
  id: string,
  country: string,
  agency_id: string,
  agentId: string | null
): Promise<ChangeFareCluster> => {
  if (process.env.REACT_APP_SHOULD_USE_MOCKS === "true") {
    return getMockFlight()
  }
  if (!process.env.REACT_APP_API_URI) throw new Error("Missing key REACT_APP_API_URI")

  const site = country
  const clientID = agency_id

  const url = `${process.env.REACT_APP_API_URI}/flights/clusters/${encodeURI(id)}?site=${site}&channel=WEB&source=`

  const headers = {
    "x-client-id": clientID,
    "x-api-key": process.env.REACT_APP_API_KEY,
    "Content-Type": "application/json"
  }
  if (agentId) {
    headers["x-user-id"] = agentId
  }
  const options: any = {
    headers: headers
  }

  const response = await fetch(url, options)
  if (response.ok || response.status === 400) {
    return (await response.json()) as ChangeFareCluster
  }
  throw new Error(`${response.status}`)
}

const getFlightReservation = async (id: string, lang: string) => {
  if (process.env.REACT_APP_SHOULD_USE_MOCKS === "true") {
    return getFlightReservationMock()
  }
  if (!process.env.REACT_APP_API_URI) throw new Error("Missing key REACT_APP_API_URI")
  const uri = `${process.env.REACT_APP_API_URI}/flights/reservations/${id}`
  return fetchAPI(uri, "FLIGHTS", lang, "WEB", "", null)
}

const getFlightReservationMock = () => {
  if (!process.env.REACT_APP_MOCK_RESPONSE_TIME) throw new Error("Missing key REACT_APP_MOCK_RESPONSE_TIME")
  const responseTime = parseInt(process.env.REACT_APP_MOCK_RESPONSE_TIME, 10)
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(flightReservation)
    }, responseTime)
  })
}

const getMockFlight = (): Promise<ChangeFareCluster> => {
  if (!process.env.REACT_APP_MOCK_RESPONSE_TIME) throw new Error("Missing key REACT_APP_MOCK_RESPONSE_TIME")
  const responseTime = parseInt(process.env.REACT_APP_MOCK_RESPONSE_TIME, 10)
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(cluster)
    }, responseTime)
  })
}

const fetchAPI = async (
  url: string,
  productType: productType,
  lang: string,
  channel: string,
  source: string,
  agentId: string | null,
  opts?: any
) => {
  const clientConfig = await getClientConfig(productType, lang)
  const site = clientConfig.country
  const clientID = clientConfig.agency_id

  const uri = `${url}?site=${site}&channel=${channel}&source=${source}`
  const headers = {
    "x-client-id": clientID,
    "x-api-key": process.env.REACT_APP_API_KEY,
    "Content-Type": "application/json"
  }
  if (agentId) {
    headers["x-user-id"] = agentId
  }

  const options = opts === undefined ? { headers: headers } : { ...opts, headers: headers }

  return fetchAsync(uri, options)
}

export { getCluster, getFlightReservation }
