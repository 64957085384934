import { Config } from "@basset-la/components-commons"
import { Insurance } from "@basset-la/components-insurances/dist/models/insurance"
import { SearchboxParams } from "@basset-la/components-insurances/dist/models"

import { InsurancePagination, InsuranceResultsModel } from "../components/types"
import { fetchAsync } from "./common"
import { mapParamsToUrl } from "../utils/insurancesUtils"

const API_URL = process.env.REACT_APP_TRAVEL_INSURANCES_API_URL

const API_KEY = process.env.REACT_APP_API_KEY

export const getInsuranceDetail = async (
  insuranceId: string,
  site: string,
  channel: string,
  language: string,
  agencyId: string
): Promise<Insurance> => {
  const queryParams = `site=${site}&channel=${channel}&language=${language}`

  const url = `${API_URL}/search/${insuranceId}?${queryParams}`

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": API_KEY || "",
    "x-client-id": agencyId
  })

  return fetchAsync(url, { headers })
}

export const getInsuranceReservation = async (reservationId: string, config: Config): Promise<any> => {
  const queryParams = `site=${config.country}&channel=WEB&source=${config.source}&language=${config.language}`

  const url = `${API_URL}/reservations/${reservationId}?${queryParams}`

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": API_KEY || "",
    "x-client-id": config.agency_id
  })

  return fetchAsync(url, { headers })
}

export const fetchClusters = async (
  params: SearchboxParams,
  pagination: InsurancePagination,
  config: Config,
  crosselling?: boolean
): Promise<InsuranceResultsModel> => {
  const queryParams = mapParamsToUrl(params, true, pagination, crosselling)

  const filtersStr = `${queryParams}&site=${config.country}&channel=WEB&source=${config.source}&language=${config.language}`

  const url = `${API_URL}/search?${filtersStr}`

  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": API_KEY || "",
    "x-client-id": config.agency_id
  })

  return fetchAsync(url, { headers })
}
