// @flow

import React from "react"
import InputLabel from "./InputLabel"
import { css } from "glamor"
import ComboBox from "./comboBox/ComboBox"
import ErrorLabel from "./ErrorLabel"

interface Props {
  options: any[]
  title: string
  placeholder?: string
  onChange: (e: any) => void
  name: string
  value?: string
  errorText?: string
}

const SelectField = ({ options, title, placeholder, onChange, name, value, errorText }: Props) => {
  const onChangeWithTarget = name => v => {
    onChange({ target: { name: name, value: v && v.value } })
  }

  const err = !!(errorText && errorText.length)

  return (
    <div className={`${containerStyle}`}>
      <InputLabel title={title} />
      <ComboBox
        options={options}
        placeholder={placeholder}
        required
        onChange={onChangeWithTarget(name)}
        value={value}
        name={name}
        hasError={err}
      />
      {err && <ErrorLabel errors={[errorText]} />}
    </div>
  )
}

const containerStyle = css({
  display: "flex",
  flexDirection: "column",
  flex: "3 1 0%"
})

export default SelectField
