// @flow

import React from "react"
import { css } from "glamor"
import { ThemeProps, theme } from "@basset-la/themed-components"
import ThanksPassengerDetail from "./ThanksPassengerDetail"
import { withTranslation, WithTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

interface Props extends WithTranslation, ThemeProps {
  passengers: any[]
}

const ThanksReservationDetail = ({ theme, t, passengers }: Props) => {
  const titleStyle = css({
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "1.33",
    color: theme.colors.brand_primary,
    margin: 0,
    marginBottom: "8px"
  })

  return (
    <div className={`${containerStyle}`}>
      <p className={`${titleStyle}`}>{t<string>("ThanksReservationDetail.title")}</p>
      <p className={`${passengersTitleStyle}`}>{t<string>("ThanksReservationDetail.passengers")}</p>
      {passengers.map((p, i) => (
        <ThanksPassengerDetail key={i} passenger={p} />
      ))}
    </div>
  )
}

const containerStyle = css({
  backgroundColor: "#fff",
  padding: "16px 8px",
  "@media(min-width:1200px)": {
    padding: "16px"
  }
})

const passengersTitleStyle = css({
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "1.14",
  color: "#0d207b",
  margin: 0,
  padding: "8px 0",
  borderBottom: "1px solid #f9f9f9",
  marginBottom: "8px"
})

export default theme(withTranslation(I18N_NS)(ThanksReservationDetail))
