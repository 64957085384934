import React, { Suspense } from "react"
import { getAvailableProducts, getClientConfigForProduct } from "../api/common"
import AppLoaderWrapper from "./AppLoaderWrapper/AppLoaderWrapper"
import { ConfigProvider } from "@basset-la/components-commons"
import AppTheme from "./AppTheme"

interface Props {
  children: React.ReactElement
  product: "FLIGHTS" | "HOTELS" | "CARS" | "COMBINED_PRODUCTS" | "TRAVEL_INSURANCES"
}

const Layout: React.FC<Props> = ({ children, product }) => {
  let variant: "ACCOMMODATIONS" | "FLIGHTS" | "CARS" | "INSURANCES"
  switch (product) {
    case "FLIGHTS":
    case "COMBINED_PRODUCTS":
      variant = "FLIGHTS"
      break
    case "CARS":
      variant = "CARS"
      break
    case "TRAVEL_INSURANCES":
      variant = "INSURANCES"
      break
    default:
      variant = "ACCOMMODATIONS"
  }

  return (
    <Suspense fallback={<AppLoaderWrapper variant={variant} />}>
      <ConfigProvider
        getClientConfig={getClientConfigForProduct(product)}
        getAvailableProducts={getAvailableProducts}
        fallback={<AppLoaderWrapper variant={variant} />}
      >
        <AppTheme variant={variant}>{children}</AppTheme>
      </ConfigProvider>
    </Suspense>
  )
}

export default Layout
