export const getAirlines = (agencyId: string, site: string, lang: string) => async (query: string) => {
  if (!query) return []
  const url = process.env.REACT_APP_AUTOCOMPLETE_API_URL
    ? `${process.env.REACT_APP_AUTOCOMPLETE_API_URL}?entities=AIRLINE&q=${query}&language=${lang}&site=${site}&channel=WEB`
    : ""

  const results = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : "",
      "x-client-id": agencyId
    }
  })
  const json = await results.json()

  if (results.ok || results.status === 400) {
    return json
  }

  throw new Error(`${results.status}: ${json.error}`)
}
