import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTranslation } from "react-i18next"

import { getInsuranceDetail, getInsuranceReservation } from "../../../api/insurances"
import { getReservation } from "../../../api/reservations"

import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import NotFound from "@basset-la/commons-frontend/dist/components/checkout/NotFound"
import InsurancePlanCoverage from "@basset-la/components-insurances/dist/components/InsurancePlanCoverage"
import { Insurance } from "@basset-la/components-insurances/dist/models"
import { useTheme } from "@basset-la/themed-components"
import { useConfig } from "@basset-la/components-commons/dist/components/ConfigProvider"
import { useAuthUser } from "@basset-la/components-commons/dist/components/AuthUserProvider"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"

import styles from "./InsurancesThanks.styles"
import Check from "../../../icons/Check"
import ThanksTotal from "../../thanks/ThanksTotal"
import ReservationConfirmationText from "../../thanks/ReservationConfirmationText"
import { I18N_NS } from "../../../utils/constants"
import { useOpenInsuranceDetails } from "@basset-la/components-insurances/dist/hooks"

interface PathParams {
  id: string
}

export type InsurancesThanksInfo = {
  type: string
  insurance: Insurance
  extraCoverages: string[]
  reservation_id: string
  contact: {
    email: string
  }
}

const InsurancesThanks: React.FC<{}> = () => {
  const [product, setProduct] = useState<InsurancesThanksInfo>()

  const [isFetching, setIsFetching] = useState(false)
  const [notFound, setNotFound] = useState(false)

  const {
    loadingDetails,
    onGetClusterDetail,
    onCloseDetailsWrapper: onGetOnCloseDetailsWrapper,
    onOpenDetails,
    openDetails
  } = useOpenInsuranceDetails((id, site, channel, language) =>
    getInsuranceDetail(id, site, channel, language, config.agency_id)
  )

  const { id } = useParams<PathParams>()

  const { config } = useConfig()

  const { userId } = useAuthUser()

  const history = useHistory()

  const { t, i18n } = useTranslation(I18N_NS)

  const locale = getLocale(i18n)

  const theme = useTheme()

  const isMobile = useMediaQuery("(max-width: 1024px)")

  useEffect(() => {
    const init = async () => {
      try {
        setIsFetching(true)

        const res = await getReservation(id, userId, config.country, config.agency_id)

        const product = res.products[0]

        const resProduct = await getInsuranceReservation(product.reservation_id, config)

        const extras = new Set<string>(resProduct.insurance.upgrades?.map(u => u.name) || [])

        setProduct({
          ...resProduct,
          extraCoverages: Array.from(extras)
        })
      } catch (error) {
        console.log(error)

        setNotFound(true)
      }

      setIsFetching(false)
    }

    if (!id) return

    init()
  }, [])

  const handleGetInsuranceDetail = async (): Promise<void> => {
    if (!product) {
      return
    }

    try {
      if (product.insurance.coverages.length) {
        onOpenDetails(product.insurance.id)
        return
      }

      const detail = await onGetClusterDetail(product.insurance.id, config.country, "WEB", config.language)

      setProduct({
        ...product,
        insurance: detail
      })
    } catch (error) {
      console.log(error)
    }
  }

  if (isFetching) {
    return <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="INSURANCES_PAYMENT" />
  }

  if (notFound || !product) {
    return (
      <div className={`${styles.background(theme)}`}>
        <div className={`${styles.notFoundRoot}`}>
          <div className={`${styles.notFoundContainer}`}>
            <NotFound description={t<string>("InsurancesThanks.notFound")} onBackToSearch={() => history.goBack()} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.meesageContainer}>
          <div className={styles.checkIcon}>
            <Check />
          </div>
          <p className={styles.titleText(theme)}>{t<string>("InsurancesThanks.title")}</p>
        </div>

        <div className={styles.content}>
          <div className={styles.infoContainer}>
            <ThanksTotal
              classNames={{
                container: styles.thanksContainer,
                title: styles.thanksTitle
              }}
              currency={product.insurance.rate.currency}
              total={formatPrice(product.insurance.rate.total, locale, product.insurance.rate.currency, {
                hideCurrency: true
              })}
            />
            <ReservationConfirmationText
              reservationID={product.reservation_id}
              email={product.contact.email}
              classNames={{
                container: styles.confirmationContainer,
                reservationText: styles.reservationText
              }}
              reservationText={{
                id: t<string>("InsurancesThanks.reservationNumber"),
                voucher: t<string>("InsurancesThanks.voucher")
              }}
            />
          </div>
          <div className={styles.card(theme)}>
            <InsurancePlanCoverage
              header={t<string>("InsurancesThanks.titleDetail")}
              cluster={product.insurance}
              extraCoverages={product.extraCoverages}
              classNames={{
                headerText: styles.planCoveragesTitles,
                titleText: styles.planCoveragesTitles,
                extraText: styles.planCoveragesTitles
              }}
              openDetails={openDetails[product.insurance.id]}
              onClusterDetail={handleGetInsuranceDetail}
              loading={loadingDetails[product.insurance.id]}
              onCloseDetail={onGetOnCloseDetailsWrapper(product.insurance.id)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsurancesThanks
