import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  containerStyle: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto"
  }),

  buttonStyle: (t: Theme) =>
    css({
      backgroundColor: `${t.colors.brand_primary} !important`,
      color: "#fff !important",
      width: "98vw",
      marginTop: "16px",
      ":disabled,[disabled]": {
        backgroundColor: "#eaeaea !important",
        color: "#bbb !important"
      },
      " span": {
        textTransform: "none",
        fontWeight: "300 !important"
      },
      "@media(min-width:1200px)": {
        maxWidth: "320px"
      }
    }),

  titleStyle: (t: Theme) =>
    css({
      fontSize: "24px",
      lineHeight: "1.33",
      color: t.colors.brand_primary,
      margin: "8px auto 16px auto",
      width: "98vw",
      "@media(min-width:1200px)": {
        flexDirection: "row",
        width: "1320px",
        margin: "0 auto",
        padding: "16px"
      }
    }),

  checkboxCheckedStyle: (t: Theme) =>
    css({
      color: `${t.colors.brand_primary} !important`
    }),

  checkboxStyle: css({
    width: "18px !important",
    height: "18px !important",
    color: "#4a4a4a"
  }),

  hotelDetailContainer: css({
    marginTop: "8px",
    "@media(min-width:1200px)": {
      marginTop: "16px"
    },
    " p": {
      //fix
      margin: 0
    }
  }),

  detailContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    order: "1",
    padding: "8px",
    "@media(min-width: 1200px)": {
      width: "432px",
      order: "2",
      marginLeft: "16px",
      padding: 0
    }
  }),

  mainContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    "@media(min-width:1200px)": {
      flexDirection: "row",
      width: "1320px",
      margin: "0 auto",
      paddingBottom: "32px"
    }
  }),

  notFoundContainerStyle: css({
    minHeight: "608px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto"
  }),

  formContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    order: 2,
    "@media(min-width: 1200px)": {
      padding: 0,
      order: 1,
      width: "100%"
    }
  })
}
