import React from "react"

const Check = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M-4-4h24v24H-4z" />
        <path
          fill="#6DB122"
          fillRule="nonzero"
          d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm-.8 11.6L12.8 6l-1.128-1.128L7.2 9.336 4.728 6.872 3.6 8l3.6 3.6z"
        />
      </g>
    </svg>
  )
}

export default Check
