export type FormConfig = {
  passengers: boolean
  payment: boolean
  billingInfo: boolean
  autocompleteBillingInfo: boolean
  contactInfo: boolean
  passengerDisclaimers: {
    enabled: boolean
    email: string
  }
}

const formConfigDefault = {
  passengers: true,
  payment: false,
  billingInfo: true,
  autocompleteBillingInfo: false,
  contactInfo: true,
  passengerDisclaimers: {
    enabled: false,
    email: ""
  }
}

export const getFormConfig = (checkoutFormConfiguration?): FormConfig => {
  if (!checkoutFormConfiguration) {
    return formConfigDefault
  }

  return {
    passengers: checkoutFormConfiguration.passengers_form ? checkoutFormConfiguration.passengers_form.enabled : false,
    payment: checkoutFormConfiguration.payment_form ? checkoutFormConfiguration.payment_form.enabled : false,
    billingInfo: checkoutFormConfiguration.billing_form ? checkoutFormConfiguration.billing_form.enabled : false,
    autocompleteBillingInfo: checkoutFormConfiguration.billing_form
      ? checkoutFormConfiguration.billing_form.load_data
      : false,
    contactInfo: checkoutFormConfiguration.contact_form ? checkoutFormConfiguration.contact_form.enabled : false,
    passengerDisclaimers: {
      enabled: checkoutFormConfiguration.passenger_disclaimers
        ? checkoutFormConfiguration.passenger_disclaimers.enabled
        : false,
      email: checkoutFormConfiguration.passenger_disclaimers
        ? checkoutFormConfiguration.passenger_disclaimers.email
        : false
    }
  }
}
