import { StepType } from "@basset-la/components-combined/dist/models"
import { CartState } from "./types"

export const saveEnabledStep = (step: StepType) => {
  const str = sessionStorage.getItem("_cartState") || "null"
  const state = (JSON.parse(str) as CartState) || { cartId: "", enabledStep: "flight" }
  sessionStorage.setItem(
    "_cartState",
    JSON.stringify({
      ...state,
      enabledStep: step
    })
  )
}

export const removeCartState = () => {
  sessionStorage.removeItem("_cartState")
}
