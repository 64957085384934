import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  background: css({
    display: "flex"
  }),

  title: (t: Theme) =>
    css({
      fontFamily: "Roboto",
      fontSize: "24px",
      lineHeight: "1.33",
      color: t.colors.brand_primary,
      margin: "16px 0"
    }),

  detailContainer: css({
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column",
    "@media(min-width:1024px)": {
      flexDirection: "row"
    },
    "@media(min-width:1200px)": {
      order: 2,
      width: "432px",
      flexDirection: "column"
    }
  }),

  checkout: css({
    margin: "0 auto",
    padding: "16px 8px",
    flex: "1 1 0%",
    "@media(min-width:1200px)": {
      maxWidth: "1320px"
    },
    /* IE Fix */
    "@media (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      margin: "50px"
    }
  }),

  button: (t: Theme) =>
    css({
      backgroundColor: t.colors.brand_primary + " !important",
      color: "#fff !important",
      width: "100%",
      marginTop: "16px",
      ":disabled,[disabled]": {
        backgroundColor: "#eaeaea !important",
        color: "#bbb !important"
      },
      " span": {
        textTransform: "none",
        fontWeight: 300
      },
      "@media(min-width:1200px)": {
        maxWidth: "320px"
      }
    }),

  checkboxChecked: (t: Theme) =>
    css({
      color: `${t.colors.brand_primary} !important`
    }),

  termAndConditions: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "8px",
      marginBottom: "16px",
      " > p": {
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "1.14",
        margin: 0,
        marginLeft: "8px",
        color: "#4a4a4a",
        " > button": {
          color: t.colors.brand_primary,
          cursor: "pointer",
          fontSize: "inherit",
          background: "none !important",
          border: "none",
          "&:focus": {
            outline: 0
          }
        }
      }
    }),

  formContainer: css({
    "@media(min-width:1200px)": {
      order: 1,
      width: "880px",
      marginRight: "16px"
    }
  }),

  mainContainer: css({
    display: "flex",
    flex: "1 1 0%",
    flexDirection: "column",
    "@media(min-width:1200px)": {
      flexDirection: "row"
    }
  }),

  priceBreakdownContainer: css({
    display: "flex",
    flexDirection: "column",
    "@media(min-width: 1024px)": {
      flex: "1 1 0%",
      marginRight: "8px"
    },
    "@media(min-width: 1200px)": {
      flex: "none",
      marginRight: "0px"
    }
  }),

  productContainer: css({
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
    " > div": {
      width: "inherit"
    },
    " > div > div > span": {
      minWidth: "64px"
    },
    "@media(min-width: 1024px)": {
      flex: "1 1 0%",
      marginTop: "0px"
    },
    "@media(min-width: 1200px)": {
      marginTop: "16px",
      flex: "none"
    }
  }),

  notFoundContainer: css({
    minHeight: "608px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  }),

  checkbox: css({
    width: "18px !important",
    height: "18px !important",
    color: "#4a4a4a"
  }),

  penaltiesAlert: css({
    margin: "16px 0"
  })
}
