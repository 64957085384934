import React, { useEffect } from "react"
import ReactGA from "react-ga"
import { GoogleTagManager } from "@basset-la/commons-frontend"
import Header from "@basset-la/components-commons/dist/components/Header"
import Footer from "@basset-la/components-commons/dist/components/Footer"
import { useAuth0 } from "@auth0/auth0-react"
import { HeaderButton, useAuthUser, useConfig } from "@basset-la/components-commons"

interface Props {
  children: React.ReactNode
}

const Wrapper: React.FC<Props> = ({ children }) => {
  const auth0 = useAuth0()
  const { agency } = useAuthUser()
  const { config, availableProducts } = useConfig()

  useEffect(() => {
    ReactGA.initialize(config.analytics_id)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [config.analytics_id])

  const buildSocialLinkItems = () => {
    if (!config.brand.contact_data || !config.brand.contact_data.social_media) {
      return []
    }
    const social = config.brand.contact_data.social_media
    const items: any[] = []
    if (social.facebook) items.push({ type: "facebook", url: social.facebook })
    if (social.instagram) items.push({ type: "instagram", url: social.instagram })
    if (social.linkedin) items.push({ type: "linkedin", url: social.linkedin })
    if (social.tiktok) items.push({ type: "tiktok", url: social.tiktok })
    if (social.twitter) items.push({ type: "twitter", url: social.twitter })
    if (social.youtube) items.push({ type: "youtube", url: social.youtube })
    return items
  }

  const socialLinkItems = buildSocialLinkItems()

  const handleLogout = () => {
    auth0.logout({ returnTo: `${window.location.origin}/auth/login` })
  }

  const handleAccessBackOffice = () => {
    const url = process.env.REACT_APP_BO_URL || ""
    window.open(url, "_blank")?.focus()
  }

  const drawHeader = () => {
    const activeProducts: HeaderButton[] = []
    const productsSet = new Set(availableProducts)
    if (productsSet.has("FLIGHTS")) activeProducts.push({ type: "flights", path: "/?product=flights" })
    if (productsSet.has("HOTELS")) activeProducts.push({ type: "accommodations", path: "/?product=hotels" })
    if (productsSet.has("COMBINED_PRODUCTS")) activeProducts.push({ type: "trip", path: "/?product=combined_products" })
    if (productsSet.has("CARS")) activeProducts.push({ type: "cars", path: "/?product=cars" })
    if (productsSet.has("TRAVEL_INSURANCES"))
      activeProducts.push({ type: "travel_insurances", path: "/?product=travel_insurances" })

    const phone = config.brand.contact_data?.phone_numbers ? config.brand.contact_data?.phone_numbers[0] : undefined

    return (
      <Header
        products={activeProducts}
        logo={config.brand.logo}
        development={process.env.REACT_APP_SHOW_DEVELOPMENT_ALERT === "true"}
        flow="availability"
        company={config.agency_name}
        phone={phone}
        socialNetworks={socialLinkItems}
        isAuthenticated={!!config.require_authentication}
        showBackOfficeOption={!!agency}
        onLogout={handleLogout}
        onAccessBackOffice={handleAccessBackOffice}
      />
    )
  }

  const drawFooter = () => {
    if (!config.brand.contact_data || !socialLinkItems) return <></>

    return (
      <Footer
        phones={config.brand.contact_data.phone_numbers || []}
        emails={config.brand.contact_data.emails || []}
        socialNetworks={socialLinkItems}
      />
    )
  }

  return (
    <>
      {config.google_tag_manager_id ? <GoogleTagManager gtmId={config.google_tag_manager_id} /> : null}
      {config.header && <header dangerouslySetInnerHTML={{ __html: config.header }} />}
      {!config.header && drawHeader()}
      <main>{children}</main>
      {config.footer && <footer dangerouslySetInnerHTML={{ __html: config.footer }} />}
      {!config.footer && drawFooter()}
    </>
  )
}

export default Wrapper
