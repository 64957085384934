// @flow

import React from "react"
import { css } from "glamor"

interface Props {
  title: string
}

const InputLabel = ({ title }: Props) => {
  return <label className={`${titleStyle}`}>{title}</label>
}

const titleStyle = css({
  fontFamily: "Roboto",
  fontSize: "14px",
  color: "#4a4a4a",
  display: "inline-block",
  marginBottom: "8px",
  fontWeight: "300"
})

export default InputLabel
