import React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { InsurancesThanksInfo } from "../insurances/InsurancesThanks/InsurancesThanks"
import InsurancePlanCoverage from "@basset-la/components-insurances/dist/components/InsurancePlanCoverage"
import styles from "./CrossSellingThanks.styles"
import { useTheme } from "@basset-la/themed-components"
import { getInsuranceDetail } from "../../api/insurances"
import { useOpenInsuranceDetails } from "@basset-la/components-insurances/dist/hooks"
import { Insurance } from "@basset-la/components-insurances/dist/models"

export interface Props {
  products: any[]
}

const CrossSellingThanks: React.FC<Props> = ({ products }) => {
  const { t } = useTranslation(I18N_NS)

  const theme = useTheme()

  const { onCloseDetailsWrapper, onOpenDetails, openDetails } = useOpenInsuranceDetails((id, site, channel, language) =>
    getInsuranceDetail(id, site, channel, language, "")
  )

  const handleOnOpenInsuranceDetails = (insurance: Insurance) => {
    onOpenDetails(insurance.id)
  }

  return (
    <>
      {products.map(p => {
        switch (p.type) {
          case "TRAVEL_INSURANCE":
            const product = p as InsurancesThanksInfo
            return (
              <div className={styles.card(theme)}>
                <InsurancePlanCoverage
                  header={t<string>("InsurancesThanks.titleDetail")}
                  cluster={product.insurance}
                  extraCoverages={product.extraCoverages}
                  classNames={{
                    headerText: styles.planCoveragesHeader(theme),
                    titleText: styles.planCoveragesTitles,
                    extraText: styles.planCoveragesTitles
                  }}
                  openDetails={openDetails[product.insurance.id]}
                  onCloseDetail={onCloseDetailsWrapper(product.insurance.id)}
                  onClusterDetail={handleOnOpenInsuranceDetails}
                />
              </div>
            )
          default:
            return <></>
        }
      })}
    </>
  )
}

export default CrossSellingThanks
