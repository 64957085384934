import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  reservationDetailTitle: (theme: Theme) =>
    css({
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "1.5",
      color: theme.colors.brand_primary
    }),

  subTitle: css({
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.14"
  })
}
