import React from "react"
import { css } from "glamor"
import { ThemeProps, theme } from "@basset-la/themed-components"

interface Props extends ThemeProps {
  title: string
  description: string
}

const AccommodationDescription = ({ title, description, theme }: Props) => {
  return (
    <div className={`${containerStyle(theme)}`}>
      <p className={`${titleStyle(theme)}`}>{title}</p>
      <div className={`${policyTextStyle}`} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  )
}

const titleStyle = theme =>
  css({
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.5",
    color: theme.colors.brand_primary,
    marginBottom: "16px"
  })

const policyTextStyle = css({
  fontSize: "14px",
  fontWeight: "300",
  " >ul": {
    listStyle: "none",
    padding: 0,
    margin: 0
  }
})

const containerStyle = theme =>
  css({
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    backgroundColor: theme.colors.background_primary
  })

export default theme(AccommodationDescription)
