import React from "react"
import Text from "@basset-la/components-commons/dist/components/Text"
import Button from "@basset-la/components-commons/dist/components/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import { useTranslation } from "react-i18next"
import ErrorIcon from "@material-ui/icons/Error"
import { useTheme } from "@basset-la/themed-components"
import styles from "./WarningsDialog.styles"
import { I18N_NS } from "../../utils/constants"

interface Props {
  open: boolean
  hotelName: string
  onAccept: () => void
  onCancel: () => void
  status?: string
}

const WarningsDialog: React.FC<Props> = ({ open, hotelName, onAccept, onCancel, status = "" }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  let description = ""

  switch (status) {
    case "expiration_date_changed":
      description = t<string>("WarningsDialog.expiration_date_changed")
      break
    default:
      description = t<string>("WarningsDialog.refundable_changed")
  }

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm">
      <DialogTitle>
        {t<string>("WarningsDialog.title", { name: hotelName })}
      </DialogTitle>
      <DialogContent>
        <div className={styles.description}>
          <ErrorIcon className={styles.icon(theme)} />
          <Text size={16} variant="regular" color="error">
            {description}
          </Text>
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <div className={styles.actionsContainer}>
          <Button variant="outlined" onClick={onCancel}>
            {t<string>("WarningsDialog.cancel")}
          </Button>
          <Button variant="contained" onClick={onAccept}>
            {t<string>("WarningsDialog.accept")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default WarningsDialog
