// @flow

import React, { PureComponent } from "react"
import { getFlightReservation } from "../api/flights"
import { getReservation } from "../api/reservations"
import ThanksTotal from "./thanks/ThanksTotal"
import { css } from "glamor"
import ReservationConfirmationText from "./thanks/ReservationConfirmationText"
import ThanksReservationDetail from "./thanks/ThanksReservationDetail"
import ThanksFlightDetail from "./thanks/ThanksFlightDetail"
import { formatPrice } from "../utils/flightsUtils"
import ThanksProductReservationDetail from "./thanks/ThanksProductReservationDetail"
import { withTranslation, WithTranslation } from "react-i18next"
import { ThemeProps, theme } from "@basset-la/themed-components"
import { RouteComponentProps } from "react-router"
import { I18N_NS } from "../utils/constants"
import Check from "../icons/Check"
import { AuthUserType, withAuthUser, withConfig } from "@basset-la/components-commons"
import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"
import { ConfigType } from "@basset-la/components-commons/dist/components/ConfigProvider/ConfigProvider"
import CrossSellingThanks from "./crossSelling/CrossSellingThanks"
import { getInsuranceDetail, getInsuranceReservation } from "../api/insurances"

interface MatchProps {
  id: string
}

interface Props extends WithTranslation, ThemeProps, RouteComponentProps<MatchProps>, AuthUserType, ConfigType {}

class FlightsThanks extends PureComponent<Props, any> {
  constructor(props: Props) {
    super(props)
    this.state = {
      reservation: null,
      cluster: null,
      pnr: null,
      site: "",
      insuranceCluster: null
    }
  }

  containerStyle = css({
    //backgroundColor: this.props.theme.brand_colors.background_color,
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    margin: "0 auto",
    "@media(min-width:1200px)": {
      maxWidth: "880px"
    }
  })

  UNSAFE_componentWillMount() {
    this.retrieveReservation(this.props.match.params.id)
  }

  retrieveReservation = async (id: string) => {
    const { i18n } = this.props
    const res = await getReservation(id, this.props.userId, this.props.config.country, this.props.config.agency_id)
    const product = res.products[0]
    const flightRes = await getFlightReservation(product.reservation_id, i18n.language)

    const insuranceProductIndex = res.products.findIndex(p => p.type === "TRAVEL_INSURANCE")
    if (insuranceProductIndex >= 0) {
      const insuranceProduct = res.products[insuranceProductIndex]

      const resInsuranceProduct = await getInsuranceReservation(insuranceProduct.reservation_id, this.props.config)

      if (!resInsuranceProduct.insurance.coverages.length) {
        const insuranceWithDetails = getInsuranceDetail(
          resInsuranceProduct.insurance.id,
          this.props.config.country,
          "WEB",
          this.props.config.language,
          this.props.config.agency_id
        )

        resInsuranceProduct.insurance = insuranceWithDetails
      }

      const extras = new Set<string>(resInsuranceProduct.insurance.upgrades?.map(u => u.name) || [])

      res.products[insuranceProductIndex] = {
        ...resInsuranceProduct,
        extraCoverages: Array.from(extras),
        type: "TRAVEL_INSURANCE"
      }
    }

    this.setState({
      reservation: res,
      cluster: flightRes.cluster,
      pnr: flightRes.pnr,
      site: this.props.config.country
    })
  }

  render() {
    const { reservation, cluster, pnr, site } = this.state
    const { t } = this.props

    if (reservation && cluster) {
      const product = reservation.products[0]
      const crossSellingProducts = reservation.products.slice(1)

      return (
        <div className={`${this.containerStyle}`}>
          <div className={`${titleStyle}`}>
            <div className={`${checkIconStyle}`}>
              <Check />
            </div>
            <p className={`${titleTextStyle}`}>{t<string>("FlightsThanks.title")}</p>
          </div>
          <div className={`${resInfoContainerStyle}`}>
            <ThanksTotal currency={product.fare.currency} total={formatPrice(product.fare.total, site)} />
            <ReservationConfirmationText reservationID={reservation.id} email={reservation.contact?.email || ""} />
            <ThanksProductReservationDetail title={t<string>("ThanksProductReservationDetail.pnr")} code={pnr} />
          </div>
          <div className={`${borderSolid}`}>
            <ThanksReservationDetail passengers={product.passengers} />
            <ThanksFlightDetail cluster={cluster} />
            {crossSellingProducts.length > 0 && <CrossSellingThanks products={crossSellingProducts} />}
          </div>
        </div>
      )
    }
    return <AppLoader variant="FLIGHTS" />
  }
}

const borderSolid = css({
  border: "solid 1px #CCC"
})

const resInfoContainerStyle = css({
  "@media(min-width:1200px)": {
    display: "flex",
    flexDirection: "row",
    marginBottom: "16px"
  }
})

const titleStyle = css({
  display: "flex",
  flexDirection: "row",
  padding: "8px"
})

const titleTextStyle = css({
  fontFamily: "Roboto",
  fontSize: "24px",
  fontWeight: "300",
  lineHeight: "1.33",
  margin: 0,
  color: "#0d207b",
  marginLeft: "8px"
})

const checkIconStyle = css({
  width: "24px",
  height: "24px",
  marginTop: "4px",
  backgroundSize: "contain"
})

export default withConfig(theme(withAuthUser(withTranslation(I18N_NS)(FlightsThanks))))
