import React from "react"
import styles from "./ReservationDetailTitle.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  nights: number
  totalRooms: number
}

const ReservationDetailTitle = ({ nights, totalRooms }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  return (
    <div>
      <div className={styles.reservationDetailTitle(theme)}>{t<string>("AccommodationsThanks.detail")}</div>
      <div className={styles.subTitle}>
        <span>{`${t<string>("AccommodationsThanks.nights", { count: nights })}, ${t<string>(
          "AccommodationsThanks.rooms",
          {
            count: totalRooms
          }
        )}`}</span>
      </div>
    </div>
  )
}

export default ReservationDetailTitle
