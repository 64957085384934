// @flow

import React from "react"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

interface Props extends WithTranslation {
  type: string
  email: string
}

const RequiredDocAlert = ({ t, type, email }: Props) => {
  return (
    <div className={`${requiredDocAlertContainerStyle}`}>
      <span className={`${iconContainerStyle}`}>
        <img className={`${iconStyle}`} src="https://statics.basset.la/alert-icon.png" alt="alert" />
      </span>
      <span className={`${messageContainerStyle}`}>
        <p className={`${titleStyle}`}>{t<string>("RequiredDocAlert.title")}</p>
        <p className={`${subtitleStyle}`}>{t<string>("RequiredDocAlert.types." + type + ".title")}</p>
        <p className={`${messageStyle}`}>
          {t<string>("RequiredDocAlert.types." + type + ".text", { email: email })}
        </p>
      </span>
    </div>
  )
}

const iconStyle = css({
  paddingTop: "8px"
})

const iconContainerStyle = css({
  padding: "8px",
  flexDirection: "column",
  display: "none",
  "@media(min-width:1024px)": {
    display: "inherit"
  }
})

const titleStyle = css({
  color: "#b8850f",
  size: "14px",
  fontWeight: "bold",
  margin: 0
})

const subtitleStyle = css({
  fontWeight: "bold",
  margin: 0
})

const messageStyle = css({
  margin: 0
})

const messageContainerStyle = css({
  flexDirection: "column",
  padding: "8px"
})

const requiredDocAlertContainerStyle = css({
  display: "flex",
  flex: "1 1 0%",
  backgroundColor: "#faf7db",
  border: "solid 2px #d6a106",
  borderRadius: "3px",
  fontSize: "12px",
  fontWeight: "300",
  color: "#4a4a4a",
  marginBottom: "16px"
})

export default withTranslation(I18N_NS)(RequiredDocAlert)
