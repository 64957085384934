// @flow

import React from "react"
import { css } from "glamor"
import { withTranslation, WithTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

interface Props extends WithTranslation {
  passenger: any
}

const ThanksPassengerDetail = ({ t, passenger }: Props) => {
  return (
    <div className={`${containerStyle}`}>
      <p>
        <span className={`${boldStyle}`}>
          <span className={`${capitalize}`}>{passenger.firstname}</span>{" "}
          <span className={`${capitalize}`}>{passenger.lastname}</span>
        </span>
      </p>
      <p>
        {t<string>("ThanksPassengerDetail.documentTypes." + passenger.document.type)}{" "}
        <span className={`${boldStyle}`}>{passenger.document.number}</span>
      </p>
      <p>
        {t<string>("ThanksPassengerDetail.birthdate")} <span className={`${boldStyle}`}>{passenger.birth}</span>
      </p>
    </div>
  )
}

const capitalize = css({
  textTransform: "capitalize"
})

const boldStyle = css({
  fontWeight: "500"
})

const containerStyle = css({
  marginBottom: "8px",
  display: "flex",
  flexDirection: "column",
  "@media(min-width:1200px)": {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  ":last-child": {
    marginBottom: "0px",
    " p:last-child": {
      borderBottom: "none",
      paddingBottom: "0px"
    }
  },
  " p": {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "1.14",
    color: "#4a4a4a",
    margin: 0,
    marginBottom: "8px",
    "@media(min-width:1200px)": {
      flex: "1 1 0%",
      textAlign: "center"
    }
  },
  " p:first-child": {
    "@media(min-width:1200px)": {
      textAlign: "left"
    }
  },
  " p:last-child": {
    marginBottom: "0px",
    paddingBottom: "8px",
    borderBottom: "1px solid #f9f9f9",
    "@media(min-width:1200px)": {
      textAlign: "right"
    }
  }
})

export default withTranslation(I18N_NS)(ThanksPassengerDetail)
